
import './styleSlider.css';
import React, { Component } from 'react';
import DataSlider from '../../services/home';
import { NavLink } from 'react-router-dom';
import PageFacebook from './pageFacebook';
import Home from '../../services/home';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import i18next from 'i18next';
export default class SliderEnVedette extends Component
{

    constructor (props) {
        super(props)
        var event = true;
        if(props.event==false) event = false;
        this.state = {
            postItems : [<ItemPost/>,<ItemPost/>,<ItemPost/>],
        }

        Home.getTopPosts().then((res)=>{
            var postItems=[];
            if(res.data.get){
                var i = 0;
                res.data.data.forEach(post => {
                    if(i<3){
                        postItems.push(<ItemPost post={post}/>)
                    }
                    i++;
                });
                this.setState({postItems:postItems});
            }
        });
        
    }
    render() {
        return ( 
            <>
                <PageFacebook width={350}/>              
                <div className="col-md-12 px-0" style={{backgroundColor: '#ffffff', paddingTop: '10px', marginBottom: '15px'}}>
                    <span className="title-lastest" style={{fontSize: '17px', textTransform: 'uppercase'}}>Derniers artciles</span>
                    {this.state.postItems}
                </div>
            </>
        );
    }
}





class ItemPost extends Component
{
    constructor (props) {
        super(props);
        this.state = {
          
        }
    }
  
    render() {
        if(this.props.post){
            return ( 
                <div className="col-md-12" style={{marginBottom: '10px', backgroundColor: '#fff'}}>
                    <div className style={{paddingRight: 0, paddingLeft: 0}}>
                        <a href={"/"+i18next.language+this.props.post.url}> <img onContextMenu={(e)=> e.preventDefault()} src={this.props.post.img} alt={this.props.post.titer} style={{width: '100%'}} />
                        <h4 style={{position: 'absolute', top: 0, left: '16px', color: '#fff', background: '#ed2829', fontSize: '12px', padding: '1px 5px'}}>{this.props.post.categorie}</h4></a>       
                    </div>
                    <div style={{fontFamily: 'calibri', textAlign: 'left', color: '#151410'}}>
                        <a href={"/"+i18next.language+this.props.post.url}>  <span style={{color: '#000', fontSize: '16px', lineHeight: '1.2', fontWeight: 700}}>{this.props.post.title}</span> </a>
                        <br /> <span style={{float: 'right'}}>{this.props.post.date}</span><br />
                    </div>
                </div>
            );
        }else{
            return ( 
                <div className="col-md-12" style={{marginBottom: '10px', backgroundColor: '#fff'}}>
                    <div className style={{paddingRight: 0, paddingLeft: 0}}>
                        <div className="lowder-image-post" ></div>       
                    </div>
                    <div className="lowder-text-post mt-1">
                    </div>
                </div>
            );
        }
    }
}



