
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import { Redirect } from 'react-router';
import { GoogleKey,backUrl } from  "../../config.json";
import Auth from  "../../services/Auth";
import i18next from 'i18next';
import jwt_decode from "jwt-decode";
import './style.css';
class LoginHeader extends Component
{
   constructor (props) {
        super(props)
        var modal = true;
        if(this.props.modal==false){
            modal = false;
        }
        const code = this.randomNum(8, 20);
        this.state = {
            modal:modal,
            username:"",
            password:"",
            captcha:"",
            captchaCode:code,
            captchaUrlImage:backUrl+"/api/captcha.php?code="+(code.toString(2)),
            showErrorMessage:false,
            errorMessage:"",
            textLogin:<>Se connecter</>,
            redirect:<></>,
            showConfirmerPassword:"password"
        }
    }
    randomNum=(min, max)=> {
        return Math.floor(Math.random() * (max - min)) + min; 
    }
    clickInscription = (e)=>{
        if(this.state.modal){
           this.props.close(); 
        }
    }
    changeUsername=(e)=>{
        this.setState({username:e.target.value});
        this.setState({
            username:e.target.value,
            errorMessage:"",
            showErrorMessage:false
        });
    }
    changePassword=(e)=>{
        this.setState({
            password:e.target.value,
            errorMessage:"",
            showErrorMessage:false
        });
    }
    changeCaptcha=(e)=>{
        this.setState({
            captcha:e.target.value,
            errorMessage:"",
            showErrorMessage:false
        });
    }
    submitLogin=()=>{
        if(this.state.username==""){
            this.setState({
                errorMessage:"Nom d'utilisateur est nécessaire",
                showErrorMessage:true
            });
            return false;
        }
        if(this.state.password==""){
            this.setState({
                errorMessage:"Mot de passe obligatoire",
                showErrorMessage:true
            });
            return false;
        }
        if(this.state.captcha==""){
            this.setState({
                errorMessage:"Captcha est obligatoire",
                showErrorMessage:true
            });
            return false;
        }
        if(this.state.captcha!=this.state.captchaCode){
            this.setState({
                errorMessage:"Le code captcha est incorrect",
                showErrorMessage:true
            });
            return false;
        }        
        
        this.setState({textLogin:<span className='loading-login'></span>});
        Auth.login(this.state.username,this.state.password).then((res)=>{
            if(res.data.code==3 && res.data.reponse=="unconfirm"){
                localStorage.setItem('tokenb2b',"");
                this.setState({redirect:<Redirect to={"/"+i18next.language+"/veuillez-confirmer-votre-compte"}/>});
                this.props.close(); 
            }else if(res.data.code==1){
                localStorage.setItem('tokenb2b',res.data.token);
                var dataUser=jwt_decode(res.data.token);
                if( dataUser && dataUser.redirectToAdd  ){
                    // console.log("----------------redirect To Add----------------");
                    // console.log(res.data.reponse);
                    window.location.href="/"+i18next.language+'/add';
                }else{
                    // console.log("----------------reload----------------");
                    // console.log(res.data);
                    window.location.reload(false);
                }
                console.log("--------------------------------");
            }else{
                if(res.data.reponse=="Compte inexsitant"){
                    this.setState({
                        errorMessage:"Nom d'utilisateur est inexsitant",
                        showErrorMessage:true,
                        textLogin:<>Se connecter</>
                    });
                    return false;
                }else if(res.data.reponse=="incorrect password"){
                    this.setState({
                        errorMessage:"Mot de passe incorrect",
                        showErrorMessage:true,
                        textLogin:<>Se connecter</>
                    });
                    return false;
                }else{
                    this.setState({
                        errorMessage:"Erreur de Connexion",
                        showErrorMessage:true,
                        textLogin:<>Se connecter</>
                    });
                    return false;
                }
            }
        });
    }
    responseGoogle = (response) => {
        if(response && response.profileObj && response.profileObj.email)
        this.setState({username:response.profileObj.email});
    }
    clickShowConfirmerPassword=(e)=>{
        if(this.state.showConfirmerPassword=="password"){
            this.setState({showConfirmerPassword:"text"});
        }else{
            this.setState({showConfirmerPassword:"password"});
        }
    }
    render() {
        return ( 
            <> 
                {this.state.redirect}
                <div className="connxtion_body">
                    <div className="content-ihc mb-0">
                        <div className="b2b-login-form-wrap b2b-login-template-10">
                            {this.state.showErrorMessage?<div id="error">{this.state.errorMessage}</div>:<></>}
                            <form action="" method="post" id="ihc_login_form">
                                <div className="impu-form-line">
                                    <i className="fa fa-user iump_login_fa_icon"></i>
                                    <input type="email" value={this.state.username} id="iump_login_username" onChange={this.changeUsername} placeholder="Utilisateur" required="required" />
                                </div>
                                <div className="impu-form-line">
                                    <i className="fa fa-unlock-alt iump_login_fa_icon"></i>
                                    <input type={this.state.showConfirmerPassword} onChange={this.changePassword}  id="iump_login_password" placeholder="Mot de passe" required="required" />
                                    <div className="pass_eye" onClick={this.clickShowConfirmerPassword}><i className="fa fa-eye" aria-hidden="true" /></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="impu-remember-wrapper">
                                            <input type="checkbox" value="forever" name="rememberme" id="login-form-impu-form-input-remember" className="impu-form-input-remember" />
                                            <label for="login-form-impu-form-input-remember" className="impu-form-label-remember">Se souvenir de moi</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="impu-form-links-pass" style={{marginTop:"0px"}}>
                                            <Link to={"/"+i18next.language+"/rest-pass/"} onClick={this.clickInscription}>Mot de passe oublié ?</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="b2b-clear">
                                    <img onContextMenu={(e)=> e.preventDefault()} src={this.state.captchaUrlImage} />
                                    <input type="text" value={this.state.captcha} autocomplete="off" onChange={this.changeCaptcha} id="result" required="" style={{"width":"60px","height":"40px"}} />
                                </div>
                                <div className="impu-form-line impu-form-submit">
                                    <i className="icone-btn-connecter"></i><button type="button" onClick={this.submitLogin} className="btn-connecter w-100 ml-0">{this.state.textLogin}</button>
                                </div>
                            </form>
                        </div>
                    </div>						
                    <div className="content-ihc">
                        <div className="div_text_ou">
                            <span></span>
                            <span className="text-ou">Ou</span> 
                            <span></span>
                        </div>
                        <div className="container-connxtion-google">
                            <div className="connxtion-google">
                                <div className="impu-form-line impu-form-submit">
                                    <div className="div-btn-connecter">
                                        <i className="icone-btn-connecter icon-google "></i>
                                        <div className="btn-connecter btn-connecter-google ml-0">SE CONNECTER avec google</div>
                                        <GoogleLogin
                                            clientId={GoogleKey}
                                            buttonText="Connexion avec google"
                                            className="GoogleLoginStyle"
                                            onSuccess={this.responseGoogle}
                                            onFailure={this.responseGoogle}
                                            cookiePolicy={'single_host_origin'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> 
                        {/* <div className="container-connxtion-google" >
                            <div className="connxtion-google">
                                <div className="impu-form-line impu-form-submit">
                                    <div className="div-btn-connecter">
                                        <i className="icone-btn-connecter icon-linkedin"></i>
                                        <div className="btn-connecter btn-connecter-linkedin">CONNECTEZ-VOUS avec LinkedIn</div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <h6 className="TESTINSCRIVEZVOUS">Vous n'avez pas de compte ?<br/><Link to={"/"+i18next.language+"/inscription/"} onClick={this.clickInscription}>S'INSCRIRE</Link></h6>
                    </div>	
                </div>
            </>
        );
    }
}
export default LoginHeader;



