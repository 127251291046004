import './style.css';
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import {b2bNameSite,b2bDescriptionSite} from '../../config.json';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import i18next from 'i18next';
import Auth from '../../services/Auth';
import ReactGA from 'react-ga'
export default class Index extends Component
{

    constructor (props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        super(props)
        this.state = {
            email:"",
            erroremail:false,
            errorsubmitForm:0,
            disabled:false,
            isLogin: false
        } 
        Auth.isLogin().then((res)=>{
            if(res.data!=false){
                this.setState({isLogin: true});
            }else{
                this.setState({isLogin: false});
            }
        });
    }
    changeEmail=(e)=>{
        this.setState({
            email:e.target.value
        })
    }
    submitForm=()=>{
        if(this.state.email==""){
            this.setState({
                erroremail:true
            })
            return false;
        }else{
            this.setState({
                disabled:true
            })
            Auth.sundEmailRestPass(this.state.email).then((res)=>{
                if(res.data.sund){
                    this.setState({errorsubmitForm:2})
                }else{
                    this.setState({errorsubmitForm:1})
                }
                this.setState({
                    disabled:false
                })
            });
        } 
    }
    render() {
        if (this.state.isLogin) {
            return ( <Redirect to={"/"+i18next.language}/> );
        } else {
            return ( 
                <>
                <MetaTags>
                    <title>Réinitialisation de mot de passe | {b2bNameSite} | {b2bDescriptionSite}</title>
                </MetaTags>
                <div className="page-content">
                    <section style={{backgroundColor: '#fff', minHeight: '450px', marginBottom: '-50px'}}>
                        <h1 className="title__bordered">Entrez votre email pour réinitialiser votre mot de passe </h1>
                        <div className="container">
                            <div className='row'>
                                <div className='col-md-3'></div>
                                <div className="col-md-6" style={{paddingTop: '20px'}}>
                                    {this.state.errorsubmitForm==1?<div style={{textAlign:"center",color: '#721c24', backgroundColor: '#f8d7da', borderColor: '#f5c6cb', padding: '10px 4px', border: '1px solid transparent', borderRadius: '.25rem'}}>Cette adresse email n'est associée à aucun compte sur « B2B Tunisia© », veuillez introduire un email valide, sinon veuillez nous contacter pour résoudre ce problème</div>:<></>}
                                    {this.state.errorsubmitForm==2?<div style={{textAlign:"center",color: '#155724', backgroundColor: '#d4edda', borderColor: '#c3e6cb', padding: '10px 4px', border: '1px solid transparent', borderRadius: '.25rem'}}>Nous avons envoyé un email à votre adresse mail. Cliquez sur le lien figurant dans cet email pour réinitialiser votre mot de passe.</div>:<></>}
                                    <div style={{margin: '0 auto'}}>
                                    
                                            <div style={{background: '#fff'}}>
                                                <input type="email"  name="email" className="restPassInputt" placeholder="E-mail" onChange={this.changeEmail} />
                                                {this.state.erroremail?<label className="error" style={{display:"block"}}>E-mail Est Obligatoire</label>:<></>}

                                            </div>
                                            <div style={{background: '#fff', paddingTop: '30px'}}>
                                                <button onClick={this.submitForm} disabled={this.state.disabled?"disabled":""} style={{textTransform:"none",fontSize:"18px"}} className="button button-primary button-large btn-b2b" >Réinitialiser mon mot de passe</button>
                                            </div>
                                    </div>
                                </div>
                                <div className='col-md-3'></div>
                            </div>
                        </div>
                    </section>
                </div>
                </>
            );
        }
    }
}


