
import React, { Component } from 'react';
import Actualites from '../../services/actualites';
import Slider from '../Slider/SliderPost';
import BtnPartage from '../Slider/btnPartage';
import Modal, {closeStyle} from 'simple-react-modal';
import ReactPlayer from "react-player"
import { Link,NavLink } from 'react-router-dom';
import './detail.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useParams } from 'react-router-dom';
import { Markup } from 'react-render-markup';
import i18next from 'i18next';
import ReactGA from 'react-ga';
import { withTranslation } from 'react-i18next';
function Detail(props){ 
    let { slug } = useParams();
    return ( 
        <Index slug={slug} t={props.t}/>
    );
}
export default withTranslation()(Detail)
class Index extends Component
{
    constructor (props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        if(document.getElementById("header_b2b"))document.getElementById("header_b2b").style.display="block";
        super(props)
        this.state = {
            slug : props.slug,
            data : null
        }
        Actualites.getDetail(this.state.slug).then((res)=>{
            if(res.data.get){
               this.setState({data : res.data.data});
               console.log(res.data.data);
            }
        });
    }
    
    render() {
        const {t} = this.props;
        if(this.state.data){
            var ItmesPosts = [];
            this.state.data.categoryposts.forEach(post => {
                ItmesPosts.push(<ItmePost t={t} data={post}/>);
            });
            return (
                <div className="page-content page-detail-actualite">
                    <section id="list-places-wrapper" style={{marginTop: 0, background: '#fff'}}>
                        <div className="container">
                            <div className="col-md-12 px-1" style={{backgroundColor: '#fff', paddingTop: '15px'}}>
                                <div className="row">
                                    <div className="col-md-8 px-1" >
                                        <div className="col-md-12" style={{border: '1px solid #b8c7c8', paddingTop: '15px'}}> 
                                            <div className="col-md-12 bottom-header-bar" style={{position: 'relative', width: '100%'}}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h1 className="media-heading title-blog" style={{fontSize: '25px', fontWeight: 'bold', margin: 0, marginBottom: '10px',textAlign:"left"}}>{this.state.data.post_title}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <figure style={{position: 'relative'}}>
                                                <div style={{width: 'fit-content', backgroundColor: '#ff0000', height: '30px', padding: '5px', position: 'absolute', left: '5px', top: '5px'}}>
                                                    <span style={{float: 'left', marginRight: '20px', fontWeight: 600, color: '#fff', textTransform: 'uppercase', textAlign: 'right'}}>{this.state.data.category}</span>
                                                    <span style={{display: 'block', float: 'left', color: '#fff'}}>{t('Publié le')}: {this.state.data.post_date}</span>
                                                </div>
                                                <img onContextMenu={(e)=> e.preventDefault()} className="wpnaw-news-image" src={this.state.data.thumbnail} alt="" style={{width: '100%', height: '400px', objectFit: 'cover'}} />
                                            </figure>
                                            <hr />
                                            <div style={{fontSize: '15px', lineHeight: '24px', fontFamily: 'Verdana, Geneva, sans-serif', padding: '15px', paddingTop: '0px', textAlign: 'justify'}}>
                                                <Markup markup={this.state.data.post_content} />
                                                <Markup markup={this.state.data.iframe_facebook} />
                                                <p className="pb-0 mb-0 w-100 text-center">
                                                    <span style={{fontWeight: 'bold', color: '#5d5f5e'}}>{t("PARTAGER SUR")} : </span>
                                                </p>
                                                <div className='mt-4 w-100'>
                                                    <BtnPartage 
                                                    image={this.state.data.thumbnail} 
                                                    title={this.state.data.post_title} 
                                                    content=""
                                                    url={this.state.data.urlPartage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="section-detail-wrapper pt-2">
                                            <div className="col-md-12" style={{backgroundColor: '#fff', paddingTop: '10px', marginBottom: '15px', border: '1px solid #b8c7c8'}}>
                                                <span className="title-lastest" style={{fontSize: '17px', textTransform: 'uppercase', color: '#aaa', marginBottom: '20px'}}> {t('Dans la même catégorie')}:</span>
                                                <div className="row"> 
                                                    {ItmesPosts}
                                                </div>
                                            </div>          
                                        </div>
                                    </div>
                                    <div className="col-md-4 px-1" >
                                        <Slider data={this.state.data.lastposts}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
    
                </div>
            );
        }else{
            return (
                <div className="page-content">
                    <section id="list-places-wrapper" style={{marginTop: 0, background: '#fff'}}>
                        <div className="container">
                            <div className="col-md-12 px-1" style={{backgroundColor: '#fff', paddingTop: '15px'}}>
                                <div className="row">
                                    <div className="col-md-8 px-1" >
                                        <div className="col-md-12" style={{border: '1px solid #b8c7c8', paddingTop: '15px'}}> 
                                            <div className="col-md-12 bottom-header-bar" style={{position: 'relative', width: '100%'}}>
                                                <div className="row">
                                                    <div className="col-md-12 px-0">
                                                        <h1 className="media-heading title-blog loder-title-blog" style={{fontSize: '25px', fontWeight: 'bold', margin: 0, marginBottom: '10px'}}></h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <figure style={{position: 'relative'}}>
                                                <div style={{width: 'fit-content', backgroundColor: '#ff0000', height: '30px', padding: '5px', position: 'absolute', left: '5px', top: '5px'}}>
                                                    <span style={{float: 'left', marginRight: '20px', fontWeight: 600, color: '#fff', textTransform: 'uppercase', textAlign: 'right'}}>
                                                                            </span>
                                                    <span style={{display: 'block', float: 'left', color: '#fff'}}>Publié le: </span>
                                                </div>
                                                <div className="wpnaw-news-image loder-image-blog " style={{width: '100%', height: '400px', objectFit: 'cover'}}> </div>
                                            </figure>
                                            <hr />
                                            <div style={{fontSize: '15px', lineHeight: '24px', fontFamily: 'Verdana, Geneva, sans-serif', padding: '15px', paddingTop: '0px', textAlign: 'justify'}}>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                                <h1 className="loder-title-lingn-blog" ></h1>
                                            </div>
                                        </div>
                                        <div className="section-detail-wrapper pt-2">
                                            <div className="col-md-12" style={{backgroundColor: '#fff', paddingTop: '10px', marginBottom: '15px', border: '1px solid #b8c7c8'}}>
                                                <span className="title-lastest" style={{fontSize: '17px', textTransform: 'uppercase', color: '#aaa', marginBottom: '20px'}}> {t('Dans la même catégorie')}:</span>
                                                <div className="row"> 
                                                    <ItmePost t={t} />
                                                    <ItmePost t={t}/>
                                                    <ItmePost t={t}/>
                                                </div>
                                            </div>          
                                        </div>
                                    </div>
                                    <div className="col-md-4 px-1" >
                                        <Slider />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        }
        
    }
}

class ItmePost extends Component {
    constructor (props) {
        super(props);
    }
    render(){
        const {t} = this.props;
        if(this.props.data){
            return(
                <div className="col-md-4" style={{marginBottom: '10px', backgroundColor: '#fff'}}>
                    <a className="row" href={"/"+i18next.language+this.props.data.url}>
                    <div className="col-md-12" style={{height: '170px'}}>
                        <img onContextMenu={(e)=> e.preventDefault()} src={this.props.data.thumbnail} alt={this.props.data.post_title} style={{height: '100%', width: '100%', objectFit: 'cover'}} />
                        <h4 style={{position: 'absolute', top: '5px', left: '20px', textTransform: 'uppercase', color: '#fff', background: '#ed2829', fontSize: '12px', padding: '1px 5px'}}>{this.props.data.category}</h4>
                    </div>
                    <div className="col-md-12" style={{fontFamily: 'calibri', textAlign: 'justify'}}>
                        <h5 style={{color: '#000', textTransform: 'uppercase', fontSize: '13px', lineHeight: '20px', minHeight: '60px', marginTop: '10px'}}>{this.props.data.post_title}</h5>
                        <span style={{width: '100%', textAlign: 'right', color: 'red', display: 'block'}}>{t('Voir plus')}...</span>
                    </div>
                    </a>
                </div>
            )
        }else{
            return(
                <div className="col-md-4" style={{marginBottom: '10px', backgroundColor: '#fff'}}>
                </div>
            )
        }
        
    }
}