
import React, { Component } from 'react';
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import TestOnline from '../Pages/testOnline';
import OffresEmploi from '../../services/offresEmploi';
import BlockActualites from '../Slider/BlockActualites';
import AliceCarousel from 'react-alice-carousel';
import Slider from '../Slider/SliderEnVedette';
import Modal, {closeStyle} from 'simple-react-modal';
import Auth from '../../services/Auth'
import Login from '../header/loginheader';
import { Link,NavLink } from 'react-router-dom';
import './detail.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useParams } from 'react-router-dom';
import { Markup } from 'react-render-markup';
import BtnPartage from '../Slider/btnPartage';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga'
function Detail(props){ 
    let { slug } = useParams();
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    const { t } = props;
    return ( 
        <Index slug={slug} t={t} offset={offset}/>
    );
}
export default withTranslation()(Detail);

class Index extends Component
{
    constructor (props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        super(props)
        this.state = {
            slug : props.slug,
            blockmenu : <BlockPresentation t={this.props.t} data={null} />,
            data: null,
            offresEmploiSimi: null,
            lastOffreEmploi: null,
            userLogin:false,
            dataUser:null,
            modaleLogin:false,
            htmlModaleLogin:<></>,
            userpostule:false,
            sue_menu_entreprise_b2b:{}
        }
        Auth.isLogin().then((res)=>{
            if(res.data!=false){
                this.setState({
                    userLogin:true,
                    dataUser:res.data
                });
            }else{
                this.setState({
                   userLogin:false,
                   dataUser:null
                });
             }
        });
        OffresEmploi.getDetail(this.state.slug).then((res)=>{
            if(res.data.get){
                console.log(res.data.data);
                this.setState({
                    data: res.data.data,
                    blockmenu : <BlockPresentation t={this.props.t} data={res.data.data} />,
                    offresEmploiSimi : res.data.offresEmploiSimi,
                    lastOffreEmploi : res.data.lastOffreEmploi 
                });
            }
        });
        
    }
    setuserpostule = (stat)=>{
        this.setState({
            userpostule : stat
        });
        
    }
    scrollMenu = ()=>{
        var win = window,doc = document,docElem = doc.documentElement,body = doc.getElementsByTagName('body')[0],x = win.innerWidth || docElem.clientWidth || body.clientWidth,y = win.innerHeight|| docElem.clientHeight|| body.clientHeight;
      
        document.getElementById("header_b2b").style.display="none";
        this.setState({sue_menu_entreprise_b2b:{
            position: "fixed",
            zIndex: "999999",
            top: "0px",
            left: "0px",
            width: "100%",
            backgroundColor: "#fff",
        }})
        if(x<700){
            window.scrollTo(0,550);
            setTimeout(() => {
                window.scrollTo(0,550);
            }, 20);
        }else{
            window.scrollTo(0,100);
            setTimeout(() => {
                window.scrollTo(0,100);
            }, 20);
        }
    }
    selectItem = (e)=>{
        var items = document.getElementsByClassName("active-item");
        var i;
        for (i = 0; i < items.length; i++) {
            items[i].classList.remove("active-item");
        }
        e.target.classList.add('active-item');
    }
    presentation = (e)=>{
        this.selectItem(e);
        this.scrollMenu(e);
        this.setState({
            blockmenu : <BlockPresentation t={this.props.t} data={this.state.data} />
        });
    }  
    infosCles = (e)=>{
        this.selectItem(e);
        this.scrollMenu(e);
        this.setState({
            blockmenu : <BlockInfosCles t={this.props.t} data={this.state.data} />
        });
    }
    entreprise = (e)=>{
        this.selectItem(e);
        this.scrollMenu(e);
        this.setState({
            blockmenu : <BlockEntreprise t={this.props.t} data={this.state.data} />
        });
    }
    postuler = (e)=>{
        if(this.state.userLogin){
            this.selectItem(e);
            this.scrollMenu(e);
            this.setState({
                blockmenu : <BlockPostuler t={this.props.t} data={this.state.data} userLogin={this.state.userLogin} dataUser={this.state.dataUser} userpostule={this.state.userpostule} setuserpostule={this.setuserpostule} />
            });
        }else{
            this.setState({
                modaleLogin : true,
                htmlModaleLogin : <Login close={this.close.bind(this)}/>
            });
        }
        
    }
    close=()=>{
        this.setState({
            modaleLogin : false,
            htmlModaleLogin : <></>
        })
    }
    render() {
        const {t}=this.props
        let sue_menu_entreprise_b2b={};
        let stylecontainer={};
        if(this.props.offset<10){
            if(document.getElementById("header_b2b"))document.getElementById("header_b2b").style.display="block";
            sue_menu_entreprise_b2b={};
            stylecontainer={};
        }else{
            if(document.getElementById("header_b2b"))document.getElementById("header_b2b").style.display="none";
            stylecontainer={marginTop:"145px"};
            sue_menu_entreprise_b2b={
                position: "fixed",
                zIndex: "999999",
                top: "0px",
                left: "0px",
                width: "100%",
                backgroundColor: "#fff",
            };
        }
       

        var btnPostuler = <div className="btn-postuler" onClick={this.postuler}>
                <span className="menu-item"href="#activites">{t("Postuler")}</span>
            </div>
        return (<>
            <div className="modal-login-hadeer">
                <Modal
                    closeOnOuterClick={true}
                    show={this.state.modaleLogin}
                    onClose={this.close.bind(this)}
                    >
                    <div classNameName="div-loginn">
                        <div className="connxtion_header">
                            <h2 id="to_text_pupup--" className="size-titre-b2b grie-b2b">
                                {t("VOUS ÊTES DÉJÀ INSCRIT")} ? {t("CONNECTEZ-VOUS")} :
                            </h2>
                            <div className="divbtnclose">
                                <span className="close closemodalheader" onClick={this.close.bind(this)}>×</span>
                            </div>
                        </div>
                    {this.state.htmlModaleLogin}
                    </div>
                </Modal>
            </div>
            <div className="page-content page-offre">
                
                <div className="container" style={stylecontainer}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sue-menu" style={sue_menu_entreprise_b2b}>
                                <nav id="menu_desktop" className="scroll-nav scroll-init menu-desk">
                                    <ul>
                                        <li>
                                            <a onClick={this.presentation} href="#presentation" className="menu-item active-item grie-b2b">{t("DESCRIPTION DE L’OFFRE")} </a>
                                        </li>
                                        <li>
                                            <a onClick={this.infosCles} href="#infosCles" className="menu-item catalogue_button grie-b2b">{t("INFOS CLÉS")}</a>
                                        </li>
                                        <li>
                                            <a onClick={this.entreprise} href="#entreprise" className="menu-item grie-b2b">{t("ENTREPRISE")} </a>
                                        </li>
                                        <li>
                                            <a onClick={this.postuler} href="#postuler" className="menu-item grie-b2b">{t("POSTULER")} </a>
                                        </li>
                                    </ul>
                                </nav>
                                <nav id="menu_mobile" className="scroll-nav scroll-init ">
                                    <ul className="row mx-0">
                                        <li className="col-xs-<?php echo $index_col; ?> px-1">
                                            <a onClick={this.presentation} href="#presentation" className="menu-item px-1 active-item grie-b2b">{t("DESCRIPTION")}</a>
                                        </li>
                                        <li className="col-xs-<?php echo $index_col; ?>  px-1">
                                            <a onClick={this.infosCles} href="#infosCles" className="menu-item px-1 catalogue_button">{t("INFOS CLÉS")}</a>
                                        </li>
                                        <li className="col-xs-<?php echo $index_col; ?>  px-1">
                                            <a onClick={this.entreprise} href="#entreprise" className="menu-item px-1 grie-b2b">{t("ENTREPRISE")}</a>
                                        </li>
                                        <li className="col-xs-<?php echo $index_col; ?>  px-1">
                                            <a onClick={this.postuler} href="#postuler" className="menu-item px-1 grie-b2b">{t("POSTULER")}</a>
                                        </li>
                                        
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-4 px-1 pb-2">
                                    <div className="borde-block blockImageTitle-block" style={{minHeight:"430px",height: "auto"}}>
                                        <BlockImageTitle  t={t} data={this.state.data}  dataUser={this.state.dataUser} btnpostuler={btnPostuler} userpostule={this.state.userpostule} setuserpostule={this.setuserpostule}/>
                                    </div>
                                </div>
                                <div className="col-md-8 px-1 pb-2">
                                    <div className="borde-block blockmenu-block" style={{minHeight:"430px",height: "auto"}}>
                                        {this.state.blockmenu}
                                    </div>
                                </div>
                                
                                <div className="col-md-12 px-1 pb-2" id="produits">
                                    <div className="borde-block">
                                        <BlockOffresEmploiSimilaires t={t} data={this.state.offresEmploiSimi}/>
                                    </div>
                                </div>
                                <div className="col-md-12 px-1 pb-2" id="partenaires">
                                    <div className="borde-block">
                                        <BlockActualites />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-md-3 px-1 ">
                            <div className="borde-block p-3">
                                <DerniersOffresDempoi t={t} data={this.state.lastOffreEmploi}/>
                                <Slider event={false}/>         
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* <div id="bottom_buttons" style={{backgroundColor: '#cc4040', width: '100%', height: '52px', textAlign: 'center', position: 'fixed', bottom: 0, zIndex: 999999999999}}>
                    <a className="telephone_b2b" href={"tel:+218"}>
                        <div style={{backgroundColor: '#AFAAA4', width: '49.5%', height: '52px', display: 'inline-block', color: 'white', paddingTop: '5px', fontSize: '22px', marginLeft: '-5px'}}>
                        <i className="fa fa-phone" /> {t("Appeler")}
                        </div>
                    </a>
                    <a onClick={this.contact} href="#contact" id="contacterlink2" role="tab" data-toggle="tab">
                        <div style={{backgroundColor: '#cc4040', width: '48%', height: '52px', display: 'inline-block', color: 'white', paddingTop: '5px', fontSize: '22px'}}>
                        <i className="fa fa-envelope-o" /> {t("Contacter")}
                        </div>
                    </a>
                </div> */}
            </div>
        </>);
    }
}

class DerniersOffresDempoi extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            
        }
    }
    render() {
        const {t}=this.props
        if(this.props.data){
            var lastOffres = [];
            this.props.data.forEach(data => {
                lastOffres.push(
                    <a href={"/"+i18next.language+data.permalink}>
                        <div className="top-offres">
                            <span className="domaine-offres">{data.domaine}</span> 
                            <span className="title-offres">{data.title}</span>
                            <span className="budget-offres"><b> {t("Gouvernorat")} :</b>{data.gouvernorat}</span>  
                        </div>
                    </a>
                );
            });
            return (
                <div className="Banniere-pup">
                    <div className="top-entreprise-header text-left" >
                        <h3>{t("LES DERNIERES OFFRES D'EMPLOI")} :</h3>
                    </div>
                    <div className="top-offres-body">
                        {lastOffres}
                    </div>
                </div>
            );
        }else{
            return (
                <div className="Banniere-pup">
                    <div className="top-entreprise-header text-left">
                        <h3>{t("LES DERNIERES OFFRES D'EMPLOI")} :</h3>
                    </div>
                    <div className="top-offres-body">
                        <a href="#">
                            <div className="top-offres">
                                <span className="domaine-offres"></span> 
                                <span className="title-offres"></span>
                                <span className="budget-offres"><b> {t("Gouvernorat")} :</b></span>  
                            </div>
                        </a>
                        <a href="#">
                            <div className="top-offres">
                                <span className="domaine-offres"></span> 
                                <span className="title-offres"></span>
                                <span className="budget-offres"><b> {t("Gouvernorat")} :</b></span>  
                            </div>
                        </a>
                        <a href="#">
                            <div className="top-offres">
                                <span className="domaine-offres"></span> 
                                <span className="title-offres"></span>
                                <span className="budget-offres"><b> {t("Gouvernorat")} :</b></span>  
                            </div>
                        </a>
                        <a href="#">
                            <div className="top-offres">
                                <span className="domaine-offres"></span> 
                                <span className="title-offres"></span>
                                <span className="budget-offres"><b> {t("Gouvernorat")} :</b></span>  
                            </div>
                        </a>
                        <a href="#">
                            <div className="top-offres">
                                <span className="domaine-offres"></span> 
                                <span className="title-offres"></span>
                                <span className="budget-offres"><b> {t("Gouvernorat")} :</b></span>  
                            </div>
                        </a>
                    </div>
                </div>
            );
        }
    }
}


class BlockImageTitle extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            userpostule:props.userpostule
        }
        var timesRun = 0;
        var interval = setInterval(() => {
            if(this.props.data && this.props.dataUser){
                this.props.data.users_postuler.forEach(userid => {
                    if(userid==this.props.dataUser.code_user){
                        this.setState({userpostule:true});
                        this.props.setuserpostule(true);
                    }
                });
            }
            timesRun += 1;
            if(timesRun === 10){
                clearInterval(interval);
            }
        }, 1000);
    }
    render() {
        const {t}=this.props
        if(this.props.data){
            var domaines=[];
            var i = 1;
            this.props.data.domaines.forEach(domaine => {
                
                domaines.push(<span className='mr-3'><Link to={"/"+i18next.language+"/les-offres-demploi/?categorie="+domaine.id}>{domaine.nom}</Link></span>)
                if(i!=this.props.data.domaines.length){
                    domaines.push(<span> | </span>)
                }
                i++
            });
            return (
                <div className="list-single-main-item">
                    <h3 style={{textAlign: 'center', color: 'red'}}>{this.props.data.company_title}</h3>
                    <figure style={{textAlign: 'center'}}>
                        <img onContextMenu={(e)=> e.preventDefault()} style={{width: '100%', height: '150px', objectFit: 'contain'}} src={this.props.data.company_logo} />
                    </figure>
                    <div style={{width: '100%', textAlign: 'center', display: 'block', margin: '10px'}}>
                        <span style={{width: '100%', fontWeight: 'bold'}}>{this.props.data.post_title}</span>
                    </div>
                    {this.state.userpostule?<>
                    <p style={{textAlign:"center"}}>{t("Vous avez déjà postulé pour cette offre d'emploi")} </p>
                    </>:this.props.btnpostuler}
                    {domaines.length>0?
                    <p className="pb-0 mb-0">
                        <span style={{fontWeight: 'bold', color: '#5d5f5e'}}>{t("Métier(s) emploi")} : </span>
                        {domaines}
                    </p> 
                    :<></>}
                    <div style={{width: '100%', textAlign: 'center',display: "flex",justifyContent: "center",alignItems: "center"}}>
                        <span style={{width: '90px', height: '70px', display: 'flex', alignItems: 'center', fontWeight: 'bold'}}>{t("PARTAGER SUR")} : </span>
                        <div style={{width: 'calc(100% - 90px)',zoom: '80%'}}>
                            <BtnPartage 
                                image={this.props.data.company_logo} 
                                title={this.props.data.post_title}
                                content=""
                                url={this.props.data.urlPartage} />
                        </div>
                    </div>
                </div>
            );
        }else{
            return (
                <div className="list-single-main-item">
                    <h3 className="loader-entreprise-title"></h3>
                    <div className="loader-entreprise-image"></div>
                    
                    <p className="pb-0 mb-0">
                        <span style={{fontWeight: 'bold', color: '#5d5f5e'}}>{t("Domaine")} : </span>
                        <span><p className="loader-entreprise-text"></p></span>
                    </p> 
                    <div >
                        <span style={{fontWeight: 'bold'}}>{t("Partager")} : </span>
                        <p className="loader-entreprise-text"></p>
                    </div>
                </div>
            );
        }
    }
}
class BlockPresentation extends Component
{
    constructor (props) {
        super(props)
        this.state = {
           
        }
    }
    render() {
        const {t}=this.props
        if(this.props.data){
            return (
                <div id="_presentation" className="presentation-body-item"  style={{height: "auto"}}>
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("DESCRIPTION DE L’OFFRE")} :</h3>
                    </div>
                    <div id="descr" className="col-md-12 block-descr px-1">
                        <div style={{"text-align":"justify"}}>
                            <p><Markup markup={this.props.data.post_content}/></p>
                        </div>
                    </div>
                </div>
            );
        }else{
            return (
                <div id="_presentation" className="presentation-body-item">
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("DESCRIPTION DE L’OFFRE")} :</h3>
                    </div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                </div>
            );
        }
       
    }
}
class BlockInfosCles extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            
        }
    }
    render() {
        const {t}=this.props
        if(this.props.data){
            return (
                <div id="_chiffres" className="presentation-body-item"  style={{height: "auto"}}>
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("INFOS CLÉS")} :</h3>
                    </div>
                    <div className="listing-features fl-wrap">
                        <div className="col-md-12" style={{fontFamily: 'calibri', fontSize: '13px', textAlign: 'center', backgroundColor: '#fff'}}>
                            <div className="col-md-12 row" style={{fontFamily: 'calibri', fontSize: '13px', textAlign: 'center', backgroundColor: '#fff'}}>
                                <div className="col-md-4">
                                    <b>{t("Expire le")}</b> <br />{this.props.data.date_fin}                           
                                </div>
                                <div className="col-md-4">
                                    <b> {t("Type de contrat")} </b><br />
                                    {this.props.data.type_emploi}        
                                </div>
                                <div className="col-md-4">
                                    <b>{t("Niveau d'expérience")}</b> <br />{this.props.data.experience}     
                                </div>
                            </div>
                            <div className="col-md-12">
                                <hr />
                            </div>
                            <div className="col-md-12 row" style={{fontFamily: 'calibri', fontSize: '13px', textAlign: 'center', backgroundColor: '#fff'}}>
                                <div className="col-md-4"><b>{t("Niveau d'étude")}</b>
                                    <br />
                                    {this.props.data.niveau_etude}                               
                                </div>
                                <div className="col-md-4"> <b>{t("Rémunération proposée")}</b>
                                    <br />
                                    {this.props.data.salairesProposee}                             
                                </div>
                                <div className="col-md-4">
                                    <b>{t("Gouvernorat")}</b>
                                    <br />
                                    {this.props.data.gouvernorat}                              
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
            );
        }else{
            return (
                <div id="_chiffres" className="presentation-body-item">
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("INFOS CLÉS")} :</h3>
                    </div>
                    <div className="listing-features fl-wrap">
                        <div className="col-md-12" style={{fontFamily: 'calibri', fontSize: '13px', textAlign: 'center', backgroundColor: '#fff'}}>
                            <div className="col-md-12 row" style={{fontFamily: 'calibri', fontSize: '13px', textAlign: 'center', backgroundColor: '#fff'}}>
                                <div className="col-md-4">
                                    <b>{t("Expire le")}</b> <br />
                                    <div  className="block-descr-loder"></div>
                                </div>
                                <div className="col-md-4">
                                    <b> {t("Type de contrat")} </b><br />
                                    <div  className="block-descr-loder"></div>   
                                </div>
                                <div className="col-md-4">
                                    <b>{t("Niveau d'expérience")}</b> 
                                    <br />
                                    <div  className="block-descr-loder"></div> 
                                </div>
                            </div>
                            <div className="col-md-12">
                                <hr />
                            </div>
                            <div className="col-md-12 row" style={{fontFamily: 'calibri', fontSize: '13px', textAlign: 'center', backgroundColor: '#fff'}}>
                                <div className="col-md-4"><b>{t("Niveau d'étude")}</b>
                                    <br />
                                    <div  className="block-descr-loder"></div>                             
                                </div>
                                <div className="col-md-4"> <b>{t("Rémunération proposée")}</b>
                                    <br />
                                    <div  className="block-descr-loder"></div>                         
                                </div>
                                <div className="col-md-4">
                                    <b>{t("Gouvernorat")}</b>
                                    <br />
                                    <div  className="block-descr-loder"></div>                             
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            );
        }
        
    }
}
class BlockEntreprise extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            
        }
    }
    render() {
        const {t}=this.props
        if(this.props.data){
            return (
                <div id="_activites" className="presentation-body-item"  style={{height: "auto"}}>
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("L'ENTREPRISE")} :</h3>
                    </div>
                    <div id="descr" className="col-md-12 block-descr px-1">
                        <div style={{"text-align":"justify"}}>
                            <p><Markup markup={this.props.data.company_description}/></p>
                        </div>
                    </div>     
                </div>
            );
        }else{
            return (
                <div id="_activites" className="presentation-body-item">
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("L'ENTREPRISE")} :</h3>
                    </div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>    
                </div>
            );
        }
        
    }
}

class BlockPostuler extends Component
{
    constructor (props) {
        super(props)
        this.state = {
     
        }
       
    }
    render() {
        const {t}=this.props
        if(this.props.data){
            return (
                <div id="_activites" className="presentation-body-item"  style={{height: "auto"}}>
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("Postuler")} :</h3>
                    </div>
                    <div id="descr" className="col-md-12 block-descr px-1">
                        {this.props.data.date_expire?<>
                            <center className="w-100"><h2 style={{color:"red"}}>{t("L'offre expire")}</h2></center>
                        </>:<>
                            {this.props.userLogin?<>
                                
                                <div style={{"text-align":"justify"}}>
                                    <p><Markup markup={this.props.data.company_postuler}/></p>
                                </div>
                                {this.props.dataUser && this.props.data && this.props.dataUser.code_user!=this.props.data.company_user?<>  
                                    {this.props.dataUser.valid_b2b=="ok"?
                                        <FormContact t={t} userpostule={this.props.userpostule} setuserpostule={this.props.setuserpostule} user={this.props.dataUser} data={this.props.data} title={this.props.data.post_title} offreid={this.props.data.ID} entrepriseid={this.props.data.company_ID} entrepriseuser={this.props.data.company_user} />
                                    :<>{t("Vous ne pouvez pas effectuer cette action avant la validation de votre compte.")}</>}
                                </>:<></>}
                                </>:<>
                            </>}
                        </>}
                    </div>     
                </div>
            );
        }else{
            return (
                <div id="_activites" className="presentation-body-item">
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("Postuler")} :</h3>
                    </div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>    
                </div>
            );
        }
        
    }
}
class BlockOffresEmploiSimilaires extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            items : [<ItemOffresSimilaire />,<ItemOffresSimilaire />,<ItemOffresSimilaire />,<ItemOffresSimilaire />,<ItemOffresSimilaire />,<ItemOffresSimilaire />]
        }
    }
    render() {
        const {t}=this.props
        if(this.props.data ){
            var offres =[];
            this.props.data.forEach(offre => {
                if(offre.company_logo!=""){
                    offres.push(<ItemOffresSimilaire offre={offre}/>)
                }
            });

            return (
                <div className="list-single-main-item"> 
                    <div className="list-single-main-item-title fl-wrap">
                        <h3 className="size-titre-b2b grie-b2b">{t("OFFRES D'EMPLOI SIMILAIRES")} :</h3>
                    </div>
                    <div className=" product-list no-padding" width="100%">
                        <AliceCarousel 
                            mouseTracking 
                            autoPlay={true}
                            items={offres}
                            infinite
                            renderDotsItem={false}
                            autoPlayInterval = {2000}
                            responsive={{1200: {items: 4},1000: {items: 3},800: {items: 2},300: {items: 2}}} />
                    </div>
                </div>
            );
        }else{
            return (
                <div className="list-single-main-item"> 
                    <div className="list-single-main-item-title fl-wrap">
                        <h3 className="size-titre-b2b grie-b2b">{t("OFFRES D'EMPLOI SIMILAIRES")} :</h3>
                    </div>
                    <div className=" product-list no-padding" width="100%">
                        <AliceCarousel 
                            mouseTracking 
                            autoPlay={true}
                            items={this.state.items}
                            infinite
                            renderDotsItem={false}
                            autoPlayInterval = {2000}
                            responsive={{1200: {items: 4},1000: {items: 3},800: {items: 2},300: {items: 2}}} />
                    </div>
                </div>
            );
        }
        
    }
}
class ItemOffresSimilaire extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            
        }
    }
    render() {
        const {t}=this.props
        if(this.props.offre ){ //
            return (
                <a href={"/"+i18next.language+this.props.offre.permalink}>
                    <div className="partenaires-item item">
                        <figure style={{minHeight:"200px"}}>
                            <img onContextMenu={(e)=> e.preventDefault()} width="200" height="200" src={this.props.offre.company_logo} className="attachment-200x200 size-200x200 " alt={<Markup markup={this.props.offre.title} />}  />
                        </figure>
                        <h6><Markup markup={this.props.offre.title} /> </h6>
                    </div>
                </a>
            );
        }else{
            return (
                <div className="partenaires-item item">
                    <figure>
                        <div width="200" height="200" className="loder-image"/>
                    </figure>
                    <h6><span className="loder-title"></span></h6>
                </div>
            );
        }
       
    }
}


function FormContact(props) {
    
    const [loderFrome, setLoderFrome] = useState("d-none");
    const [sund, setSund] = useState(false);
    const [questionsTest, setQuestionsTest] = useState(false);
    const [commencerTest, setCommencerTest] = useState(false);
    const [title, setTitle] = useState(props.title);
    const [postule, setPostule] = useState( props.userpostule );
    const [offreid, setOffreid] = useState(props.offreid);
    const [entrepriseid, setEntrepriseid] = useState(props.entrepriseid);
    const [entrepriseuser, setEntrepriseuser] = useState(props.entrepriseuser);
    const { register, handleSubmit, reset , formState: { errors } } = useForm();
    const {t} = props
    const onSubmit = (data) => 
    {
        setLoderFrome("loder-from");
        OffresEmploi.registerMessage(entrepriseid,entrepriseuser,offreid,data).then((res)=>{
            if(res.data.register){
               reset(); 
               setSund(true);
               setLoderFrome("d-none");
               props.setuserpostule(true);
               setPostule(true);
            }
        });
    }
  
    useEffect(() => {
        if(props.user && props.data ){
            props.data.users_postuler.forEach(userid => {
                if(userid==props.user.code_user){
                    setPostule(true);
                    props.setuserpostule(true);
                }
            });
            if( parseInt(props.data.number_of_questions)>0 ){
                setQuestionsTest(true);
            }
        }

    }, []);
    const CommencerTestOnline = (testId) => {
        
        setQuestionsTest(false);
        setCommencerTest(true);
    }

    if(questionsTest){
        return ( <>
            {postule?<>
            <p>{t("Vous avez déjà postulé pour cette offre d'emploi")} </p>
            </>:<>
                {t("Pour postuler a cette offre il faut passer le Test suivant")}
                <div onClick={()=>CommencerTestOnline(props.data.ID)} className="btn btn-b2b">{t("Commencer")}</div>
            </>}
        </>);
    }else if(commencerTest){
        return (<>
            {postule?<>
            <p>{t("Vous avez déjà postulé pour cette offre d'emploi")} </p>
            </>:<TestOnline postid={props.data.ID}/>}
        </>);
    }else{
        return (<>
            {postule?<>
                {sund?
                <div className="sund-form-sucsses">
                    <span>{t("Votre Message a été enregistrée avec succès")}</span>
                </div>:<>
                <p>{t("Vous avez déjà postulé pour cette offre d'emploi")} </p>
                </>}
            </>:<>
                {sund?
                <div className="sund-form-sucsses">
                    <span>{t("Votre Message a été enregistrée avec succès")}</span>
                </div>:<></>}
                <div className={loderFrome}>
                    <span className='loading-login'></span>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="f1 m-0 w-100" id="f1">
                    <div className="contentform p-0">
                        <input type="hidden" {...register("objet")} value={"Candidature pour «"+title+"»"} />
                        <div className="form-group">
                            <p className="grie-b2b" style={{fontSize: '16px !important'}}>{t("QUELLES SONT VOS MOTIVATIONS POUR CE POSTE")} ? ({t("en quelques mots")}):</p>
                            <textarea  {...register("message", { required: true })} rows={14} placeholder={t("Pourquoi êtes-vous le candidat idéal")+"? "+t("Qu’est-ce qui vous démarque des autres candidats")+"? "+t("Quels sont vos qualités uniques")+"? "} />
                            {errors.message?<span className="error d-block">{t("Ce champ est obligatoire")}</span>:<></>}
                        </div>
                        <button type="submit" className="bouton-contact btn-b2b m-0" name="ok">{t("Postuler")}</button>
                    </div>
                </form>
            </>}   
        </>);
    }
   
}
 