import React, { Component } from 'react';
import Home from '../../services/home';
import { Markup } from 'react-render-markup';
import './style.css';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
class Searchpro extends Component
{
 
    constructor (props) {
        super(props)
        var placeholder =null;
        var widthInput = "40%";
        var serchHomeProduitEntreprise=false;
        var typeSearch="all";
        if(props.type=="produit"){
            typeSearch="produit";
            widthInput = "100%";
            placeholder = props.placeholder;
            serchHomeProduitEntreprise=true;
        }
        if(props.type=="entreprise"){
            typeSearch="entreprise";
            widthInput = "100%";
            placeholder = props.placeholder;
            serchHomeProduitEntreprise=true;
        }
        if(props.width=="100"){
            widthInput = "100%";
        }
        this.state = {
            search : "",
            typeSearch:typeSearch,
            serchHomeProduitEntreprise:serchHomeProduitEntreprise,
            placeholder : placeholder,
            widthInput:widthInput,
            proloading : {display: 'none'},
            proclose : {display: 'none'},
            resaltSearch : {display: 'none'},
            resaltSearchNull : {display: 'none'},
            itemsResaltSearchProduit : null,
            itemsResaltSearchEntreprise : null,
            itemsResaltSearchCategory : null,
            nbItemsResalt: 0,
            nbproduitsponsoriser: 0,  
            nbpaisesponsoriser: 0,
        }
    }
    closeSearch = (e)=>{
        this.setState({
            search : "",
            proloading : {display: 'none'},
            proclose : {display: 'none'},
            resaltSearch : {display: 'none'},
            resaltSearchNull : {display: 'none'},
            itemsResaltSearchProduit : null,
            itemsResaltSearchEntreprise : null,
            itemsResaltSearchCategory : null,
            nbItemsResalt: 0,
        });
    }
    searchChange = (e)=>{
        this.setState({
            search : e.target.value,
            proloading : {display: 'block'}
        });
        if(e.target.value==""){
            this.setState({
                proloading : {display: 'none'},
                resaltSearch : {display: 'none'},
                resaltSearchNull : {display: 'none'},
            });
        }else{
            Home.getSearch(e.target.value,this.state.typeSearch).then((res)=>{
                if(res.data.get){
                    var itemsResaltSearchProduit =[];
                    var itemsResaltSearchEntreprise =[];
                    var itemsResaltSearchCategory =[];
                    var nbproduitsponsoriser=0;
                    var nbpaisesponsoriser=0;
                    this.setState({
                        itemsResaltSearchProduit:itemsResaltSearchProduit,
                        itemsResaltSearchEntreprise:itemsResaltSearchEntreprise,
                        itemsResaltSearchCategory:itemsResaltSearchCategory,
                    });
                    res.data.resalt.forEach(element => {
                      
                        if(!element.sponsoriser){ 
                            if(element.type=="place"){
                                itemsResaltSearchEntreprise.push({
                                    image : element.image,
                                    url : element.url,
                                    title : element.title
                                });
                            }else if(element.type=="produit"){
                                itemsResaltSearchProduit.push({
                                    image : element.image,
                                    url : element.url,
                                    title : element.title
                                });
                            }else if (element.type=="place_category"){
                                itemsResaltSearchCategory.push({
                                    image : element.image,
                                    url : element.url,
                                    title : element.title
                                });
                            }
                        }else{
                            if(element.type=="place"){
                                nbpaisesponsoriser++;
                            }else if(element.type=="produit"){
                                nbproduitsponsoriser++
                            }
                             
                        }
                    });
                     
                    this.setState({
                        nbproduitsponsoriser: nbproduitsponsoriser,
                        nbpaisesponsoriser :nbpaisesponsoriser,
                    })
                    if(res.data.resalt.length>0){
                        this.setState({
                            itemsResaltSearchProduit:itemsResaltSearchProduit,
                            itemsResaltSearchEntreprise:itemsResaltSearchEntreprise,
                            itemsResaltSearchCategory:itemsResaltSearchCategory,
                            proloading : {display: 'none'},
                            proclose : {display: 'block'},
                            resaltSearch : {display: 'block'},
                            resaltSearchNull : {display: 'none'},
                            nbItemsResalt : res.data.resalt.length
                        });
                    }else{
                        this.setState({
                            itemsResaltSearchProduit:[],
                            itemsResaltSearchEntreprise:[],
                            itemsResaltSearchCategory:[],
                            proloading : {display: 'none'},
                            proclose : {display: 'block'},
                            resaltSearch : {display: 'none'},
                            resaltSearchNull : {display: 'flex'},
                            nbItemsResalt : 0
                        });
                    }
                }
            });
        }
        
    }
    
    render() {
        const { t } = this.props;
        return (<>
            <div className="dlab-topbar-left" style={{width:this.state.widthInput,margin:"0 0 0 auto"}}>
                <div id="rech-mob_ajax">
                    <div id="ajaxsearchpro1_1" className='asp_w asp_m asp_m_1 asp_m_1_1 wpdreams_asp_sc wpdreams_asp_sc-1 ajaxsearchpro asp_main_container  asp_non_compact'>
                        <div className="probox">
                            {this.state.serchHomeProduitEntreprise?<></>:
                            <div className='promagnifier'>
                                <div className='innericon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                                        <path d="M460.355 421.59l-106.51-106.512c20.04-27.553 31.884-61.437 31.884-98.037C385.73 124.935 310.792 50 218.685 50c-92.106 0-167.04 74.934-167.04 167.04 0 92.107 74.935 167.042 167.04 167.042 34.912 0 67.352-10.773 94.184-29.158L419.945 462l40.41-40.41zM100.63 217.04c0-65.095 52.96-118.055 118.056-118.055 65.098 0 118.057 52.96 118.057 118.056 0 65.097-52.96 118.057-118.057 118.057-65.096 0-118.055-52.96-118.055-118.056z"/>
                                    </svg>
                                </div>
                                <div className="asp_clear"></div>
                            </div>
                            }
                            <div className='proinput'>
                                <form action='#' autocomplete="off" aria-label="Search form 1">
                                    <input type='search' className='orig' onChange={this.searchChange} placeholder={this.state.placeholder?this.state.placeholder:t('Trouvez rapidement un produit ou une entreprise...')} value={this.state.search} autocomplete="off"/>
                                </form>
                            </div>
                            
                            <div className="proloading" style={this.state.proloading}>
                                <div className="asp_loader">
                                <div className="asp_loader-inner asp_ball-clip-rotate-simple">
                                    <div />
                                    <div />
                                </div>
                                </div>
                            </div>
                            <div className="proclose" onClick={this.closeSearch} style={this.state.proclose}>
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
                                <polygon id="x-mark-icon" points="438.393,374.595 319.757,255.977 438.378,137.348 374.595,73.607 255.995,192.225 137.375,73.622 73.607,137.352 192.246,255.983 73.622,374.625 137.352,438.393 256.002,319.734 374.652,438.378 " />
                                </svg>
                            </div>
                            
                        </div>
                    </div>
                    <div className="asp_nores" style={this.state.resaltSearchNull}>
                        <span className="asp_nores_header">{t('Aucun résultat de recherche pour le mot clé')} "{this.state.search}"!</span>
                    </div>
                    <div className='masque-resalt-search' style={this.state.resaltSearch} onClick={this.closeSearch}></div>
                    <div className="resalt-search" style={this.state.resaltSearch}>
                        <div className="all-resalt-search"> 
                            <ResaltEntreprises data={this.state.itemsResaltSearchEntreprise} nbspon={this.state.nbpaisesponsoriser}/>
                            <ResaltProduits data={this.state.itemsResaltSearchProduit} nbspon={this.state.nbproduitsponsoriser}/>
                            <ResaltCategorys data={this.state.itemsResaltSearchCategory} />
                        </div>
                        <Link to={"/"+i18next.language+"/recherche?s="+this.state.search} onClick={this.closeSearch} className="voir-all-resalt">{t('VOIR TOUS')} ({this.state.nbItemsResalt-(this.state.nbpaisesponsoriser+this.state.nbproduitsponsoriser)})</Link>
                    </div>

                </div>
            </div>
        </>);
    }
}
export default withTranslation()(Searchpro);

class ResaltEntreprises extends Component
{
 
    constructor (props) {
        super(props)
    }
    render() {
        if(this.props.data && this.props.data.length>0){
            var items = [];
            this.props.data.forEach(item => {
                items.push(<Item url={item.url} img={item.image} title={item.title} type="entreprises"/>)
            });
            return (<>
                    <div className="asp_group_header"> Entreprises ({this.props.data.length-this.props.nbspon})</div>
                    {items}
                </>);
        }else{
            return (<></>);
        }
       
    }
}
class ResaltProduits extends Component
{
 
    constructor (props) {
        super(props)
    }
    render() {
        if(this.props.data && this.props.data.length>0){
            var items = [];
            this.props.data.forEach(item => {
                items.push(<Item url={item.url} img={item.image} title={item.title} type="produits"/>)
            });
            return (<>
                <div className="asp_group_header"> Produits ({this.props.data.length-this.props.nbspon})</div>
                {items}
                </>);
        }else{
            return (<></>);
        }
    }
}
class ResaltCategorys extends Component
{
 
    constructor (props) {
        super(props)
    }
    render() {
        if(this.props.data && this.props.data.length>0){
            var items = [];
            this.props.data.forEach(item => {
                items.push(<Item url={item.url} title={item.title} type="categories"/>)
            });
            return (<>
                <div className="asp_group_header"> Catégories ({this.props.data.length})</div>
                {items}
                </>);
        }else{
            return (<></>);
        }
    }
}
class Item extends Component
{
 
    constructor (props) {
        super(props)
        this.state = {
            url : this.props.url,
            img : this.props.img,
            title : this.props.title,
            type : this.props.type,           
        }
    }
    render() {
        
        return (
        <>
        <div className="results-item">
            <div className="asp_content">
                {this.state.type!=="categories"?
                <a className="asp_res_image_url" href={"/"+i18next.language+this.state.url}>
                    <div className="asp_image" style={{backgroundImage: 'url("'+this.state.img+'")'}}>
                        <div className="void" />
                    </div>
                </a>
                :<></>}
                <h3>
                    <a className="asp_res_url" href={this.state.type!=="categories"?"/"+i18next.language+this.state.url:"/"+i18next.language+"/entreprises?categorie="+this.state.url}><Markup markup={this.state.title} /><span className="overlap" /></a>
                </h3>
                <div className="etc"> </div>
            </div>
            <div className="clear" />
        </div>
        <div className="asp_spacer"/>
        </>);
    }
}

