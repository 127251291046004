import React, { Component , useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DataHome from '../../services/home';
import myb2b from '../../services/myb2b';
import { Markup } from 'react-render-markup';
import i18next from 'i18next';
const Ajoutezavotrefilactualite = () => {
    const [items,setItems] = useState([]);
    useEffect(() => {
        DataHome.getEntreprisesEnVedette().then((res)=>{
            if(res.data.get){
                var newitems=[];
                res.data.data.forEach(entreprise => {
                    newitems.push(<Item id={entreprise.id} url={entreprise.url} img={entreprise.img} title={entreprise.title}/>);
                });
                setItems(newitems);
            }
        });
    }, []);
   
    return (
        <div className="widget stick-widget">
            <h4 className="widget-title">AJOUTEZ A VOTRE FIL D’ACTUALITÉ :</h4>
            <ul className="followers" style={{overflow: 'auto',maxHeight: "315px"}}>
            {items}								
            </ul>
        </div>
    );
}

export default Ajoutezavotrefilactualite;

class Item extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            url : props.url,
            img : props.img,
            title : props.title,
            show : true
        }
    }
    clickSuivreCompany=()=>{

        myb2b.suivreCompany(this.props.id).then((res)=>{
            if(res.data.status=="add"){
                 this.setState({
                    show : false
                })
            }
           
        })
       
    }
    render() {
        if(this.state.show){
            return ( 
                <li className="d-flex align-items-center mb-1">
                    <img onContextMenu={(e)=> e.preventDefault()} src={this.state.img} style={{width: '40px', height: '40px', borderRadius: '50%', border: '1px solid',marginRight: "5px"}} />
                    <div style={{width:"calc(100% - 45px)"}}>
                        <Link to={"/"+i18next.language+this.state.url} className="overflow-text-hidden w-100 d-block" title={this.state.title}> <Markup markup={this.state.title} /></Link>
                        <div className='d-flex justify-content-end'><span class="icon-follow" onClick={this.clickSuivreCompany}  style={{cursor:"pointer"}}>Suivre</span></div>
                    </div>
                    
                </li>
                );
        }else{
            return ( <></>);
        }
    }
}