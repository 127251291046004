import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {b2bNameSite,b2bDescriptionSite,backUrl} from '../../config.json';
import i18next from 'i18next';
import Auth from '../../services/Auth';
import home from '../../services/home';
import jwt_decode from "jwt-decode";
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga'
class Abonnementb2b extends Component {
    constructor(props) {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname);
        if(document.getElementById("header_b2b"))document.getElementById("header_b2b").style.display="block";
        super(props);
        var userLogin=false;
        var dataUser=null;
        if(localStorage.getItem('tokenb2b') && localStorage.getItem('tokenb2b')!="undefined" && localStorage.getItem('tokenb2b')!=""){
            userLogin=true;
            dataUser=jwt_decode(localStorage.getItem('tokenb2b'));
        }
        this.state={
            userLogin:userLogin, 
            dataUser:dataUser,
            prix_pro:0,
            prix_business:0,
            prix_devis:"",
            show_abonnement_mobile_1:false,
            show_abonnement_mobile_2:false,
            show_abonnement_mobile_3:false,
            abonnement : [  
                {
                    text:"Vitrine Web  ENTREPRISE (Logo, Présentation, Activités,Produits, Services, Vidéos, Coordonnées, Contact, etc..).",
                    pack1check:false,pack1croix:false,textpack1:"MAX 04 Produits",
                    pack2check:false,pack2croix:false,textpack2:"Jusqu’à 20 Produits",
                    pack3check:false,pack3croix:false,textpack3:"Jusqu’à 100 Produits"
                },{
                    text:"Vitrine Web PRODUIT et/ou SERVICE (Photos, Description,Fiche Technique, Vidéos, Devis, etc..).",
                    pack1check:true,pack1croix:false,textpack1:"",
                    pack2check:true,pack2croix:false,textpack2:"",
                    pack3check:true,pack3croix:false,textpack3:""
                },{
                    text:"Affichage prioritaire sur « "+b2bNameSite+" ».",
                    pack1check:true,pack1croix:false,textpack1:"",
                    pack2check:false,pack2croix:false,textpack2:"Milieu de liste",
                    pack3check:false,pack3croix:false,textpack3:"Haut de liste"
                },{
                    text:"Vidéo de présentation ENTREPRISE & PRODUITS Conception, Retouche et mise en ligne assurées par nos experts)",
                    pack1check:false,pack1croix:true,textpack1:"",
                    pack2check:true,pack2croix:false,textpack2:"",
                    pack3check:true,pack3croix:false,textpack3:""
                },{
                    text:"Galerie Photos Produits et/ou Services.",
                    pack1check:true,pack1croix:false,textpack1:"",
                    pack2check:true,pack2croix:false,textpack2:"",
                    pack3check:true,pack3croix:false,textpack3:""
                },{
                    text:"Catalogue interactif / E-Catalogue Entreprise",
                    pack1check:false,pack1croix:true,textpack1:"",
                    pack2check:true,pack2croix:false,textpack2:"08 pages",
                    pack3check:true,pack3croix:false,textpack3:"jusqu’à 20 pages"
                },{
                    text:"Fiche technique produit (format PDF)",
                    pack1check:false,pack1croix:true,textpack1:"",
                    pack2check:true,pack2croix:false,textpack2:"",
                    pack3check:true,pack3croix:false,textpack3:""
                },{
                    text:"Publi-Reportage: articles au choix + partage sur les différents réseaux sociaux (liens sponsorisés ).",
                    pack1check:false,pack1croix:true,textpack1:"",
                    pack2check:true,pack2croix:false,textpack2:"Jusqu’à 10 articles/AN",
                    pack3check:true,pack3croix:false,textpack3:"illimité"
                },{
                    text:"Réseau Social « MyB2B » : fil d’actualités B2B",
                    pack1check:true,pack1croix:false,textpack1:"",
                    pack2check:true,pack2croix:false,textpack2:"",
                    pack3check:true,pack3croix:false,textpack3:""
                },{
                    text:"Réception des demandes de Devis clients (Devis EXPRESS)",
                    pack1check:false,pack1croix:true,textpack1:"",
                    pack2check:true,pack2croix:false,textpack2:"10 Devis /Mois ",
                    pack3check:true,pack3croix:false,textpack3:"illimité"
                },{
                    text:"EMPLOI & MISSION : publication et accès illimité aux offres d’emplois,aux offres de Freelance, et à la liste des experts qualifiés.",
                    pack1check:false,pack1croix:false,textpack1:"illimité",
                    pack2check:false,pack2croix:false,textpack2:"illimité",
                    pack3check:false,pack3croix:false,textpack3:"illimité"
                },{
                    text:"SOUS-TRAITANCE : publication et accès illimité aux offres et aux demandes de sous-traitance.",
                    pack1check:false,pack1croix:true,textpack1:"",
                    pack2check:false,pack2croix:false,textpack2:"illimité",
                    pack3check:false,pack3croix:false,textpack3:"illimité"
                },{
                    text:"VENTE AUX ENCHÈRES : publication et accès illimité aux offres et aux demandes de vente aux enchères ",
                    pack1check:false,pack1croix:true,textpack1:"",
                    pack2check:false,pack2croix:false,textpack2:"illimité",
                    pack3check:false,pack3croix:false,textpack3:"illimité"
                },{
                    text:"« B2B Ads Entreprise »: Priorité d'affichage de votre entreprise (premières positions), dans les résultats de recherches sur "+b2bNameSite+" (selon mots clés choisis)",
                    pack1check:false,pack1croix:true,textpack1:"",
                    pack2check:false,pack2croix:false,textpack2:"02 mots clés",
                    pack3check:false,pack3croix:false,textpack3:"04 mots clés"
                },{
                    text:"« B2B Ads Produit »: Priorité d'affichage de vos produits choisis (premières positions), dans les résultats de recherches sur "+b2bNameSite+" (selon mots clés choisis)",
                    pack1check:false,pack1croix:true,textpack1:"",
                    pack2check:false,pack2croix:false,textpack2:"04 mots clés",
                    pack3check:false,pack3croix:false,textpack3:"08 mots clés"
                },{
                    text:"Renforcement du référencement sur les principaux moteurs de recherche (Google, Bing et Yahoo).",
                    pack1check:true,pack1croix:false,textpack1:"",
                    pack2check:true,pack2croix:false,textpack2:"",
                    pack3check:true,pack3croix:false,textpack3:""
                },{
                    text:"Abonnement GRATUIT sur « B2B-AFRICA » inclus",
                    pack1check:false,pack1croix:true,textpack1:"",
                    pack2check:true,pack2croix:false,textpack2:"",
                    pack3check:true,pack3croix:false,textpack3:""
                },{
                    text:"Mise à jour du contenu",
                    pack1check:false,pack1croix:false,textpack1:"illimité",
                    pack2check:false,pack2croix:false,textpack2:"illimité",
                    pack3check:false,pack3croix:false,textpack3:"illimité"
                },{
                    text:"Tableau de bord détaillé & statistiques en temps réel de l’ensemble de votre activité sur "+b2bNameSite+" ",
                    pack1check:false,pack1croix:true,textpack1:"",
                    pack2check:true,pack2croix:false,textpack2:"",
                    pack3check:true,pack3croix:false,textpack3:""
                },{
                    text:"Génération de contacts commerciaux à l'international",
                    pack1check:false,pack1croix:true,textpack1:"",
                    pack2check:false,pack2croix:true,textpack2:"",
                    pack3check:true,pack3croix:false,textpack3:""
                },{
                    text:"Participation à des réunions en ligne (Visioconférence),Meeting par Région, par Secteur d'activités et par Thème.",
                    pack1check:false,pack1croix:true,textpack1:"",
                    pack2check:false,pack2croix:true,textpack2:"",
                    pack3check:true,pack3croix:false,textpack3:""
                }
            ]
        }
        Auth.isLogin().then((res)=>{
            if(res.data!=false){
               this.setState({
                  userLogin:true,
                  dataUser:res.data
               });
            }else{
               this.setState({
                  userLogin:false,
                  dataUser:null
               });
            }
        });
        home.getPrixPack().then((res)=>{
            if(res.data.get){
                this.setState({
                    prix_pro:(res.data.data.prix_pack_pro>res.data.data.prix_r_pack_pro)?res.data.data.prix_pack_pro:res.data.data.prix_r_pack_pro,
                    prix_business:(res.data.data.prix_pack_bessnes>res.data.data.prix_r_pack_bessnes)?res.data.data.prix_pack_bessnes:res.data.data.prix_r_pack_bessnes,
                    prix_devis:res.data.data.devis,
                });
                    
            }
        })
    }
    
    render() { 
        const {t} = this.props;
        // if(this.state.userLogin){
        //     console.log("----------------------------");
        //     console.log(this.state.dataUser);
        //     console.log("----------------------------");
        // }
        var textabonnement=[];
        var abonnement1=[];
        var abonnement2=[];
        var abonnement3=[];
        var abonnement_mobile_1=[];
        var abonnement_mobile_2=[];
        var abonnement_mobile_3=[];
        var bg = 1;
        this.state.abonnement.forEach(element => {
            textabonnement.push(<li className={'bg-'+bg}><p>{element.text}</p></li>);
            if(element.pack1check || element.textpack1!=""){
                abonnement_mobile_1.push(<li className={'bg-1'}><p>{element.text} {element.textpack1!=""?<b>({element.textpack1})</b>:<></>}</p> </li>);
            }
            if(element.pack2check || element.textpack2!=""){
                abonnement_mobile_2.push(<li className={'bg-1'}><p>{element.text} {element.textpack2!=""?<b>({element.textpack2})</b>:<></>}</p> </li>);
            }
            if(element.pack3check || element.textpack3!=""){
                abonnement_mobile_3.push(<li className={'bg-1'}><p>{element.text} {element.textpack3!=""?<b>({element.textpack3})</b>:<></>}</p> </li>);
            }
            abonnement1.push(<li className={'bg-1'}><p>{element.pack1check?<img onContextMenu={(e)=> e.preventDefault()} src="/assets/img/check.png" />:<></>}{element.pack1croix?<img onContextMenu={(e)=> e.preventDefault()} src="/assets/img/croix.png" />:<></>}{element.textpack1!=""?<span>{element.textpack1}</span>:<></>} </p></li>);
            abonnement2.push(<li className={'bg-1'}><p>{element.pack2check?<img onContextMenu={(e)=> e.preventDefault()} src="/assets/img/check.png" />:<></>}{element.pack2croix?<img onContextMenu={(e)=> e.preventDefault()} src="/assets/img/croix.png" />:<></>}{element.textpack2!=""?<span>{element.textpack2}</span>:<></>} </p></li>);
            abonnement3.push(<li className={'bg-1'}><p>{element.pack3check?<img onContextMenu={(e)=> e.preventDefault()} src="/assets/img/check.png" />:<></>}{element.pack3croix?<img onContextMenu={(e)=> e.preventDefault()} src="/assets/img/croix.png" />:<></>}{element.textpack3!=""?<span>{element.textpack3}</span>:<></>} </p></li>);
            if(bg == 1){bg = 0}else{bg = 1}
        });
        return (
            <div className="container page-abonnement-b2b mt-5">
                <div className="row abonnement-desktop">
                    <div className='col-md-6 p-0'>
                        <div className='header-abonnement'>
                            <h2>PACKS / ABONNEMENTS <br/>B2B</h2>
                        </div>
                        <div className='body-abonnement abonnement-0'>
                            <ul>
                                {textabonnement}
                                <li className={'bg-'+bg}><h2>PRIX ABONNEMENT</h2></li>
                                
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-2 p-0'>
                        <div className='header-abonnement bg-abonnement-1'>
                            <p>Pack <span>CONNEXION</span></p>
                        </div>
                        <div className='body-abonnement abonnement-1'>
                            <ul> 
                                {abonnement1}
                                <li className={'bg-1'}><p><span className='size-prix-pack'>GRATUIT</span></p></li>
                                <li className={'bg-1'}><BtnActiver t= {t} pack={""} userLogin={this.state.userLogin} dataUser={this.state.dataUser} /></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-2 p-0'>
                        <div className='header-abonnement bg-abonnement-2'>
                            <p>Pack <span>PRO</span></p> 
                        </div>
                        <div className='body-abonnement abonnement-2'>
                            <ul> 
                                {abonnement2}
                                <li className={'bg-1'}><p><span className='size-prix-pack'>{this.state.prix_pro} {this.state.prix_devis} HT/AN</span></p></li>
                                <li className={'bg-1'}><BtnActiver t= {t} pack={"premium"} userLogin={this.state.userLogin} dataUser={this.state.dataUser} /></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-2 p-0'>
                        <div className='header-abonnement bg-abonnement-3'>
                            <p>Pack <span>BUSINESS</span></p>
                        </div>
                        <div className='body-abonnement abonnement-3'>
                            <ul> 
                                {abonnement3}
                                <li className={'bg-1'}><p><span className='size-prix-pack'>{this.state.prix_business} {this.state.prix_devis} HT/AN</span></p></li>
                                <li className={'bg-1'}><BtnActiver t= {t} pack={"platinium"} userLogin={this.state.userLogin} dataUser={this.state.dataUser} /></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row abonnement-mobile p-2">
                    <div className='col-md-12 p-0'>
                        <div className='header-abonnement'>
                            <h2>PACKS / ABONNEMENTS <br/>B2B</h2>
                            
                        </div>
                    </div>
                    <div className='col-md-12 p-0'>
                        <div className='header-abonnement bg-abonnement-1' onClick={()=>{if(this.state.show_abonnement_mobile_1){this.setState({show_abonnement_mobile_1:false})}else{this.setState({show_abonnement_mobile_1:true})}}}>
                            <p>Pack <span>CONNEXION</span></p>
                            <i class="fa fa-chevron-left ml-3" aria-hidden="true" style={{    transform: "rotate(270deg)"}}></i>
                        </div>
                        <div className='body-abonnement abonnement-1'>
                            <ul style={this.state.show_abonnement_mobile_1?{display:"block"}:{display:"none"}}> 
                                {abonnement_mobile_1}
                            </ul>
                            <ul> 
                                <li className={'bg-1'}><p><span className='size-prix-pack'>GRATUIT</span></p></li>
                                <li className={'bg-1'}><BtnActiver t= {t} pack={""} userLogin={this.state.userLogin} dataUser={this.state.dataUser} /></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-12 p-0'>
                        <div className='header-abonnement bg-abonnement-2' onClick={()=>{if(this.state.show_abonnement_mobile_2){this.setState({show_abonnement_mobile_2:false})}else{this.setState({show_abonnement_mobile_2:true})}}}>
                            <p>Pack <span>PRO</span></p>
                            <i class="fa fa-chevron-left ml-3" aria-hidden="true" style={{    transform: "rotate(270deg)"}}></i>
                        </div>
                        <div className='body-abonnement abonnement-2'>
                            <ul style={this.state.show_abonnement_mobile_2?{display:"block"}:{display:"none"}}> 
                                {abonnement_mobile_2}
                            </ul>
                            <ul> 
                                <li className={'bg-1'}><p><span className='size-prix-pack'>{this.state.prix_pro} {this.state.prix_devis} HT/AN</span></p></li>
                                <li className={'bg-1'}><BtnActiver t= {t} pack={"premium"} userLogin={this.state.userLogin} dataUser={this.state.dataUser} /></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-12 p-0'>
                        <div className='header-abonnement bg-abonnement-3' onClick={()=>{if(this.state.show_abonnement_mobile_3){this.setState({show_abonnement_mobile_3:false})}else{this.setState({show_abonnement_mobile_3:true})}}}>
                            <p>Pack <span>BUSINESS</span></p>
                            <i class="fa fa-chevron-left ml-3" aria-hidden="true" style={{    transform: "rotate(270deg)"}}></i>
                        </div>
                        <div className='body-abonnement abonnement-3'>
                            <ul style={this.state.show_abonnement_mobile_3?{display:"block"}:{display:"none"}}> 
                                {abonnement_mobile_3}
                            </ul>
                            <ul> 
                                <li className={'bg-1'}><p><span className='size-prix-pack'>{this.state.prix_business} {this.state.prix_devis} HT/AN</span></p></li>
                                <li className={'bg-1'}><BtnActiver t= {t} pack={"platinium"} userLogin={this.state.userLogin} dataUser={this.state.dataUser} /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default withTranslation()(Abonnementb2b);


class BtnActiver extends Component {
    constructor(props) {
        super(props);
        this.state={
            textBtn:"ACTIVER",
            link:"/"+i18next.language+"/compte-entreprise"+(this.props.pack!=""?"?pack="+this.props.pack:""),
            activer:true
        }
        setInterval(() => {
            if(this.props.userLogin){
                this.setState({
                    link:"/b2b/admin/user/fr/paiement?typePack=pack_"+this.props.pack+"&token="+localStorage.getItem("tokenb2b")
                });
                if(this.props.dataUser.pack==this.props.pack){
                    this.setState({
                        textBtn: this.props.t("ACTIVÉ"),
                        activer:false
                    });
                }else if(this.props.pack=="" || this.props.dataUser.type!="societe"){
                    this.setState({
                        textBtn:this.props.t("ACTIVÉ"),
                        activer:false
                    });
                    if(this.props.dataUser.type!="societe"){
                        this.setState({
                            textBtn:this.props.t("ACTIVER"),
                            activer:false
                        });
                    }
                }
            }
        }, 1000);
    } 

    render() {

        if(this.state.activer){
            if(this.props.userLogin){
                return (
                    <a href={backUrl+this.state.link} target="_blank" className='btn-acheter-pack-b2b'><span>{this.state.textBtn}</span></a>
                );
            }else{
                return (
                    <Link to={this.state.link} className='btn-acheter-pack-b2b'><span>{this.state.textBtn}</span></Link>
                );
            }
        }else{
            return (
                <div className='btn-acheter-pack-b2b disabled'><span>{this.state.textBtn}</span></div>
            );
        }
        
    }
}


