
import React, { Component } from 'react';
import Searchpro from './serchpro';
import Modal, {closeStyle} from 'simple-react-modal';
import Login from './loginheader';
import Auth from '../../services/Auth';
import LogoB2B from './logob2b';
import { Markup } from 'react-render-markup';
import MetaTags from 'react-meta-tags';
import jwt_decode from "jwt-decode";
import { Link,NavLink } from 'react-router-dom';
import i18next from 'i18next';
import './style.css';
import { Redirect } from 'react-router'
import { withTranslation } from 'react-i18next';

class Header extends Component
{

   constructor (props) {
      super(props)
      var userLogin=false;
      var dataUser=null;
      if(localStorage.getItem('tokenb2b') && localStorage.getItem('tokenb2b')!="undefined" && localStorage.getItem('tokenb2b')!=""){
         userLogin=true;
         dataUser=jwt_decode(localStorage.getItem('tokenb2b'));
      }
      this.state = {
         htmlModaleLogin:<></>,
         showModaleLogin:false,
         classfixheader : "",
         dataUser:dataUser,
         userLogin:userLogin,
         showMonEspace:false,
         showmenumobile:"",
         showconnexionumobile:"",
         redirectLang:<></>
      }
      if(localStorage.getItem("lang-b2b") && localStorage.getItem("lang-b2b")!=""){
         i18next.changeLanguage(localStorage.getItem("lang-b2b"));
      }else{
         i18next.changeLanguage("fr");
      }
      if(localStorage.getItem('tokenb2b')){
         setTimeout(() => {
            Auth.isLogin().then((res)=>{
               if(res.data!=false){
                  this.setState({
                     userLogin:true,
                     dataUser:res.data
                  });
               }else{
                  this.setState({
                     userLogin:false,
                     dataUser:null
                  });
               }
            });
         }, 1000);
       
         setTimeout(() => {
            Auth.isLogin().then((res)=>{
               if(res.data!=false){
                  this.setState({
                     userLogin:true,
                     dataUser:res.data
                  });
               }else{
                  this.setState({
                     userLogin:false,
                     dataUser:null
                  });
               }
            });
         }, 8000);
         setInterval(() => {
            Auth.isLogin().then((res)=>{
               if(res.data!=false){
                  this.setState({
                     userLogin:true,
                     dataUser:res.data
                  });
               }else{
                  this.setState({
                     userLogin:false,
                     dataUser:null
                  });
               }
            });
        }, 20000);
      }
      
      window.onscroll = ()=>{
         if (window.pageYOffset > 10) {
            this.setState({classfixheader:"is-fixed"});
         } else {
            this.setState({classfixheader:""});
         }
      };
      setTimeout(() => {
         if(!i18next.language || i18next.language=="fr-FR"){
            i18next.changeLanguage("fr");
            const  url = window.location.href.replace('/fr-FR', '/fr').replace(window.location.origin, '').replace("/undefined","/fr");
            this.setState({
               redirectLang:<Redirect to={url}/>
            });
         }
      }, 1000);
   } 

   close=()=>{
      this.setState({showModaleLogin: false})
   }
   clickBtnLogin=()=>{ 
      this.setState({showModaleLogin:true});
      this.setState({htmlModaleLogin:<Login close={this.close}/>});
   }
   clickBtnMonEspace=()=>{
      if(this.state.showMonEspace){
         this.setState({
            showMonEspace:false
         });
      }else{
         this.setState({
            showMonEspace:true
         });
      }
   }
   showMenuMobile=()=>{
      var win = window,doc = document,docElem = doc.documentElement,body = doc.getElementsByTagName('body')[0],x = win.innerWidth || docElem.clientWidth || body.clientWidth,y = win.innerHeight|| docElem.clientHeight|| body.clientHeight;
      if(this.state.showmenumobile==""){
         this.setState({showmenumobile:"show"});
         if(document.getElementById("slidemyb2bleft"))document.getElementById("slidemyb2bleft").style.left="-1000px";
         if(document.getElementById("slidemyb2bright"))document.getElementById("slidemyb2bright").style.right="-1000px";
         if(document.getElementById("block-fillter-mobile")){
            document.getElementById("block-fillter-mobile").style.left="-1000px";
            document.getElementById("block-fillter-mobile").classList.remove('p0');
         }
         
         if(x<700){
            document.body.style.overflow="hidden";
         }else{
            document.body.style.overflow="auto";
         }
      }else{
         this.setState({showmenumobile:""});
         document.body.style.overflow="auto";
      }
   }
   handleClickFR=()=>{
      i18next.changeLanguage("fr");
      localStorage.setItem("lang-b2b","fr");
      const  url = window.location.href.replace('/en', '/fr').replace(window.location.origin, '');
      this.setState({
         redirectLang:<Redirect to={url}/>
      });
   }
   handleClickEN=()=>{
      i18next.changeLanguage("en");
      localStorage.setItem("lang-b2b","en");
      const  url = window.location.href.replace('/fr', '/en').replace(window.location.origin, '');
      this.setState({
         redirectLang:<Redirect to={url}/>
      });
   }
   showConnectionMobile = () =>{
      if(this.state.showconnexionumobile==""){
         this.setState({showconnexionumobile:"show"});
      }else{
         this.setState({showconnexionumobile:""});
      }
   }
   
   render() {
      const { t } = this.props;
      var messages_url ="#";
      var notifications_url="#";
      var long = i18next.language=="fr"?"fr":"en";
      
      if(this.state.dataUser){
         if(this.state.dataUser.type=="expert"){
            // messages_url = window.location.origin+"/b2b/admin/expert/inbox?token="+localStorage.getItem("tokenb2b");
            // notifications_url=window.location.origin+"/b2b/admin/expert/notifications?token="+localStorage.getItem("tokenb2b");
            messages_url = "inbox?token="+localStorage.getItem("tokenb2b");
            notifications_url="notifications?token="+localStorage.getItem("tokenb2b");
         }else{
            // messages_url =window.location.origin+"/b2b/admin/user/"+long+"/?show=msg&token="+localStorage.getItem("tokenb2b");
            // notifications_url=window.location.origin+"/b2b/admin/user/"+long+"/?show=notif&token="+localStorage.getItem("tokenb2b");
            messages_url ="inbox?token="+localStorage.getItem("tokenb2b");
            notifications_url="notifications?token="+localStorage.getItem("tokenb2b");
         }
      }
        return ( 
            <> 
               
               {this.state.redirectLang}
               <header className="site-header header mo-left header-style-1 " id="header_b2b">
                  <div id="myP" className={"sticky-header header-curve main-bar-wraper navbar-expand-lg "+this.state.classfixheader}>
                     <div className="main-bar bg-primary clearfix ">
                        <div className="top-bar" id="top-bar-desktop">
                           <div className="container">
                              <div className="row d-flex justify-content-between">
                                 <Searchpro t={t}/>
                                 {this.state.userLogin?<>
                                 <div className="block-msg-notif">
                                    <a className="block envelope"  href="#" onClick={(e)=>this.setState({showListeMessages:true})} onMouseLeave={(e)=>this.setState({showListeMessages:false})}>
                                       <span className="number">{this.state.dataUser.notifications.messages}</span>
                                       <i className="icone-message" aria-hidden="true"></i>
                                       <ListeNotifications voirAll={messages_url} icone="icone-message" textHeader="Messages" t={t} show={this.state.showListeMessages} data={this.state.dataUser.notifications.textmessages} bas={window.location.origin+"/b2b/admin/user/"+long+"/"}/>
                                    </a>
                                    <a className="block bell" href="#" onClick={(e)=>this.setState({showListeNotifications:true})} onMouseLeave={(e)=>this.setState({showListeNotifications:false})}>
                                       <span className="number">{this.state.dataUser.notifications.notifications}</span>
                                       <i className={this.state.dataUser.notifications.notifications>0?"icone-notification active":"icone-notification"} aria-hidden="true"></i>
                                       <ListeNotifications voirAll={notifications_url} icone="icone-notification" textHeader="Notifications" t={t} show={this.state.showListeNotifications} data={this.state.dataUser.notifications.textnotifications} bas={window.location.origin+"/b2b/admin/user/"+long+"/"}/>
                                    </a>
                                 </div>
                                 <div className="dlab-topbar-right" onMouseLeave={(e)=>this.setState({showMonEspace:false})}>
                                    <div className="btn btn-login" onClick={(e)=>this.setState({showMonEspace:true})}>
                                       <a className="btn-login pupup-login-heder"  href="javascript:void(0)"  >
                                          <span>{t('Mon espace')}</span>
                                          <i className="fa fa-caret-down" aria-hidden="true"></i>
                                       </a>
                                       <img onContextMenu={(e)=> e.preventDefault()} src={this.state.dataUser.img==""?"/assets/img/profile.png":this.state.dataUser.img} style={{width:"35px", marginRight: "-1px;" , borderRadius:"100%",height:"35px",objectFit:"cover"}}/>
                                    </div>
                                    <MonEspace show={this.state.showMonEspace} data={this.state.dataUser} t={t} close={this.clickBtnMonEspace}/>
                                 </div>
                                 </>
                                 :<>
                                 <div className="block-msg-notif">
                                    <a className="block envelope" onClick={this.clickBtnLogin} href="javascript:void(0)">
                                       <span className="number">0</span>
                                       <i className="icone-message" aria-hidden="true"></i>
                                    </a>
                                    <a className="block bell" onClick={this.clickBtnLogin} href="javascript:void(0)">
                                       <span className="number">0</span>
                                       <i className="icone-notification" aria-hidden="true"></i>
                                    </a>
                                 </div>
                                 <div className="dlab-topbar-right">
                                    <div className="btn btn-add-entreprise">
                                       <Link className="btn-add-entreprise" to={"/"+i18next.language+"/compte-entreprise"} >
                                       <img onContextMenu={(e)=> e.preventDefault()} src={i18next.language=="en"?"/assets/img/en_votre-entreprise.webp":"/assets/img/votre-entreprise.webp"}/>
                                       </Link>
                                    </div>
                                    <div className="btn btn-login" onClick={this.clickBtnLogin}>
                                       <a className="btn-login pupup-login-heder"  href="javascript:void(0)"  >
                                       <span>{t('SE CONNECTER')}</span>
                                       </a>
                                       <img onContextMenu={(e)=> e.preventDefault()} src="/assets/img/profile.png" style={{width:"35px", marginRight: "-1px;", borderRadius:"100%"}}/>
                                    </div>
                                 </div>
                                 </>}
                                 
                                 <form method="post" className="b2b_lang_form">
                                    <ul className="social-bx list-inline pull-right">
                                       {i18next.language=="en"?
                                       <li><input type="button" className="b2b_lang_form-fr" onClick={this.handleClickFR} /></li>:
                                       <li><input type="button" className="b2b_lang_form-en" onClick={this.handleClickEN} /></li>
                                       }
                                    </ul>
                                 </form>
                              </div>
                           </div>
                        </div>
                        <div className="container clearfix ">
                           <button style={{float:"left",zIndex:"99999"}} className="navbar-toggler collapsed navicon justify-content-end" type="button" onClick={this.showMenuMobile}>
                           {this.state.showmenumobile?<>
                              <span style={{transform:"rotate(45deg)",marginTop:"3px"}}></span>
                              <span style={{transform: "rotate(-45deg)",marginTop:"-4px"}}></span>
                              
                           </>:<>
                              <span></span>
                              <span></span>
                              <span></span>
                           </>}
                           
                           </button>
                           {this.state.showmenumobile?<><div className='masque-close' onClick={this.showMenuMobile}></div></>:<></>}
                           
                           <LogoB2B /> 
                           {/* <!-- nav toggle button --> */}
                           <div className={"dropdown  dropleft connect-mobile "+this.state.showconnexionumobile} style={{backgroundColor:"#fff"}}>
                              <i className="fa fa-lg" style={{backgroundImage:'url('+((this.state.dataUser && this.state.dataUser.error)!=''?'/assets/img/profile.png':this.state.dataUser.img)+')',width:"30px",height: "30px" , borderRadius: "50%",backgroundSize: "cover",backgroundPosition: "center"}} onClick={this.showConnectionMobile}  id="dropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></i>
                              <div className={"dropdown-menu "+this.state.showconnexionumobile} aria-labelledby="dropdownMenuLink">
                              {this.state.userLogin?<>
                                 {this.state.dataUser.valid_b2b=="ok"?<>
                                 <a className="dropdown-item"  target="_blank" href={this.state.dataUser.gerer+"?token="+localStorage.getItem("tokenb2b")}>{t('Gérer mon compte B2B')}</a>
                                 <a className="dropdown-item" href={this.state.dataUser.error==""?"/"+i18next.language+this.state.dataUser.consulter:"/"+i18next.language+this.state.dataUser.error}>
                                    {this.state.dataUser.error==""?<>{this.state.dataUser.type=="societe"?<>{t('Consulter ma société')}</>:<>{t('Consulter mon profil')}</>}</>:<>{t('Ajouter ma Société')}</>}
                                 </a>
                                 </>:<>
                                 <a className="dropdown-item blockedbtnb2b" style={{borderRadius: "0"}} href="javascript:void(0)">{t('Gérer mon compte B2B')}</a>
                                 <a className="dropdown-item blockedbtnb2b" style={{borderRadius: "0"}} href="javascript:void(0)">
                                    {this.state.dataUser.error==""?<>{this.state.dataUser.type=="societe"?<>{t('Consulter ma société')}</>:<>{t('Consulter mon profil')}</>}</>:<>{t('Ajouter ma Société')}</>}
                                 </a>
                                 </>}
                                 <Link className="dropdown-item" to={"/"+i18next.language+"/deconnexion"}>
                                    {t('Se déconnecter')}
                                 </Link>
                              </>:<>
                                 <Link className="dropdown-item" onClick={this.showConnectionMobile} to={"/"+i18next.language+"/signin"}>{t('SE CONNECTER')}</Link> 
                                 <Link className="dropdown-item" onClick={this.showConnectionMobile} to={"/"+i18next.language+"/inscription"}>{t('INSCRIVEZ-VOUS')}!</Link>
                              </>}
                              </div>
                           </div>
                           {/* <!-- main nav --> */}
                           <div className={"header-nav navbar-collapse collapse justify-content-end "+this.state.showmenumobile} id="navbarNavDropdown" >
                              <ul className="nav navbar-nav nav-style">
                                 <li className="" onClick={this.showMenuMobile}> <NavLink exact={true} to={"/"+i18next.language+"/entreprises"} activeClassName="active"><span className="icon-entreprises"></span>{t('ENTREPRISES')}</NavLink ></li>
                                 <li className="" onClick={this.showMenuMobile}> <NavLink exact={true} to={"/"+i18next.language+"/produits-et-services"} activeClassName="active"><span className="icon-produits"></span>{t('PRODUITSETSERVICES')}</NavLink ></li>
                                 {/* <li className="" onClick={this.showMenuMobile}> <NavLink exact={true} to={"/"+i18next.language+"/evenements"} activeClassName="active"><span className="icon-produits"></span>{t('ÉVÉNEMENTS B2B')}</NavLink ></li> */}
                                 <li className="">
                                    <a href="javascript:void(0)"><span className="icon-carrieres"></span>{t('CARRIERES')}<i className="fa fa-chevron-down"></i></a>
                                    <ul className="sub-menu">
                                       <li onClick={this.showMenuMobile}><NavLink exact={true} to={"/"+i18next.language+"/les-offres-demploi"} activeClassName="active">{t('EMPLOI')}</NavLink ></li>
                                       <li onClick={this.showMenuMobile}><NavLink exact={true} to={"/"+i18next.language+"/les-offres-missions"} activeClassName="active">{t('MISSIONS')}</NavLink ></li>
                                       <li onClick={this.showMenuMobile}><NavLink exact={true} to={"/"+i18next.language+"/experts"} activeClassName="active">{t('EXPERTS')}</NavLink ></li>
                                    </ul>
                                 </li>
                                 <li className="">
                                    <a href="javascript:void(0)"><span className="icon-carrieres"></span>{t('SERVICES AUX ENTREPRISES')}<i className="fa fa-chevron-down"></i></a>
                                    <ul className="sub-menu">
                                       <li onClick={this.showMenuMobile}><NavLink exact={true} to={"/"+i18next.language+"/evenements"} activeClassName="active">{t('ÉVÉNEMENTS B2B')}</NavLink ></li>
                                       <li onClick={this.showMenuMobile}><NavLink exact={true} to={"/"+i18next.language+"/formations"} activeClassName="active">{t('FORMATIONS AUX ENTREPRISES')}</NavLink ></li>
                                       <li onClick={this.showMenuMobile}><NavLink exact={true} to={"/"+i18next.language+"/sous-traitance"} activeClassName="active">{t('SOUS-TRAITANCE')}</NavLink ></li>
                                       <li onClick={this.showMenuMobile}><NavLink exact={true} to={"/"+i18next.language+"/webinaires"} activeClassName="active">{t('WEBINAIRES B2B')}</NavLink ></li>
                                       {/* <li onClick={this.showMenuMobile}><NavLink exact={true} to={"/"+i18next.language+"/ventes-aux-encheres"} activeClassName="active">{t('VENTES AUX ENCHERES')}</NavLink ></li> */}
                                       
                                    </ul>
                                 </li>
                                 <li className="" onClick={this.showMenuMobile}> <NavLink exact={true} to={"/"+i18next.language+"/actualites"} activeClassName="active"><span className="icon-actu"></span>{t('ACTUB2B')}</NavLink ></li>
                                 {(this.state.userLogin)?<>
                                    {(this.state.dataUser.type=="societe")?<>
                                    <li className="" onClick={this.showMenuMobile}> <NavLink exact={true} to={"/"+i18next.language+"/myb2b"} activeClassName="active"><span className="icon-myb2b"></span>{t('MYB2B')}</NavLink ></li>
                                    </>:<></>}
                                 </>:<>
                                 <li className="btn-myb2b" onClick={this.showMenuMobile}> <NavLink exact={true} to={"/"+i18next.language+"/myb2b"} activeClassName="active"><span className="icon-myb2b"></span>{t('MYB2B')}</NavLink ></li>
                                 </>}
                                

                                 {i18next.language=="en"?
                                 <li className="mobile_b2b_lang_form" onClick={this.showMenuMobile}><a href="#" onClick={this.handleClickFR}> <label for="b2b_lang_form" className="m-0"><span className="icon-french"></span>FRANÇAIS</label></a></li>:
                                 <li className="mobile_b2b_lang_form" onClick={this.showMenuMobile}><a href="#" onClick={this.handleClickEN}> <label for="b2b_lang_form" className="m-0"><span className="icon-anglais"></span>ANGLAIS</label></a></li>
                                 }
                              </ul>
                           </div>
                        </div>
                     </div>
                     {/* <!-- top bar --> */}
                     <div className="top-bar" id="top-bar-mobile">
                        <div className="container">
                           <div className="row d-flex justify-content-between">
                              <div className="dlab-topbar-left" style={{width:"100%",margin:"5px;"}}>
                                 <Searchpro t={t} width="100"/>
                              </div>
                              <div className="dlab-topbar-right" style={{margin:"auto"}}>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </header>
               {this.state.dataUser && this.state.dataUser.email=="unconfirm"?
               <p className='text-center w-100 mt-0 h5'><span><i className="fa fa-exclamation-circle" style={{color: "red"}} />Votre adresse email n'a pas encore été confirmée (Veuillez vérifier votre boîte mail, pour activer votre compte) !</span></p>               :<></>}
               <div className="modal-login-hadeer">
                  <Modal
                     closeOnOuterClick={true}
                     show={this.state.showModaleLogin}
                     onClose={this.close.bind(this)}
                     >
                     <div classNameName="div-loginn">
                        <div className="connxtion_header">
                           <h2 id="to_text_pupup--" className="size-titre-b2b grie-b2b">{t('ÊTES-VOUS DÉJÀ INSCRIT')} ? {t('IDENTIFIER')}:</h2>
                           <div className="divbtnclose">
                              <span className="close closemodalheader" onClick={this.close.bind(this)}>×</span>
                           </div>
                        </div>
                        {this.state.htmlModaleLogin}
                     </div>
                  </Modal>
               </div>
            </>
        );
   }
}
export default withTranslation()(Header);


class MonEspace extends Component {
   constructor (props) {
      super(props)
      this.state = {
        
      }
   } 
   render() {
      const { t } = this.props;
      if(this.props.show){
         return (<>
            {/* <div style={{
               position: "fixed",
               width: "100%",
               height: "100vh",
               top: "0px",
               left: "0px",
               zIndex: 999998
            }} onClick={this.props.close}></div> */}
            <div className="pup-up-gerer-compte" style={{display:"block"}}>
               <figure>
                  <img onContextMenu={(e)=> e.preventDefault()} src={this.props.data.img==""? "/assets/img/profile.png" : this.props.data.img} />
               </figure>
               <div className="titre-societe">
                  <sapn>{this.props.data.title}</sapn>
               </div>
             
                  {this.props.data.error==""?<>
                  {this.props.data.valid_b2b=="ok"?<>
                  <div className="btn-gerer-compte" style={{cursor:"pointer"}} onClick={()=> window.open(this.props.data.gerer+"?token="+localStorage.getItem("tokenb2b"),"_blank")}><a >{t('Gérer mon compte B2B')}</a></div>
                  </>:<>
                  <div className="btn-gerer-compte"><a href="javascript:void(0)" className='blockedbtnb2b'>{t('Gérer mon compte B2B')}</a></div>
                  </>}
                  </>:<>
                  <div className="btn-gerer-compte"><a href={this.props.data.error}>{this.props.data.text_error}</a></div>
                  </>}
               
               <div className="btn-consulter-ma-societe">
                  {this.props.data.valid_b2b=="ok"?<>
                  <a href={this.props.data.error==""?"/"+i18next.language+this.props.data.consulter:"/"+i18next.language+this.props.data.error}>
                     {this.props.data.error==""?<>{this.props.data.type=="societe"?<>{t('Consulter ma société')}</>:<>{t('Consulter mon profil')}</>}</>:<>{t('Ajouter ma Société')}</>}
                  </a>
                  </>:<>
                  <a href="javascript:void(0)" className='blockedbtnb2b profil'>
                     {this.props.data.error==""?<>{this.props.data.type=="societe"?<>{t('Consulter ma société')}</>:<>{t('Consulter mon profil')}</>}</>:<></>}
                  </a>
                  </>}
               </div>
               <hr />
               <div className="btn-logaut">
                  <Link to={"/"+i18next.language+"/deconnexion"}>
                     <i className="fa fa-sign-out" aria-hidden="true" />
                     {t('Se déconnecter')}
                  </Link>
               </div>
            </div>
         </>);
      }else{
         return (<></>);
      }
     
   }
}

class ListeNotifications extends Component {
   constructor (props) {
      super(props)
      this.state = {
         redirect:<></>
      }
   } 
   openLink=(link)=>{
      if(link=="myb2b"){
         this.setState({redirect:<Redirect to={"/"+i18next.language+"/myb2b"}/>})
      }else{
         window.open(this.props.bas+link+"&token="+localStorage.getItem("tokenb2b"),"_blank");
      }
   }
   render() {
      const { t } = this.props;
      if(this.props.show){
         var items=[];

         this.props.data.forEach(item => {
            items.push(
            <div className='item-notification' onClick={()=>{this.openLink(item.link)}}>
               <div className='objet'><p><Markup markup={item.objet} /></p></div>
               <div className='msg'><p><Markup markup={item.msg} /></p></div>
               <div className='date'><p><Markup markup={item.notification_date} /></p></div>
            </div>)
         });
         return (<>{this.state.redirect}

          <div className='continer-notifications'>
            <div className='header-notifications'>
               <i className={this.props.icone} aria-hidden="true"></i>
               {this.props.textHeader}
            </div>
            <div className='liste-notifications'>
               {items}
            </div>
            <div className='all-notifications' onClick={()=> window.open(this.props.bas+this.props.voirAll,"_blank")}>Voir Tous</div>
          </div>
         </>);
      }else{
         return (<></>);
      }
     
   }
}