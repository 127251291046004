import http from "./httpService";
import { apiUrl } from  "../config.json";

const getExperts = (paged=1,mot_cle="",domaine=0,type_compte=[],type_emploi=[],experiences=[],niveau_etudes=[],regions=[]) => {
    var apiEndoint =  apiUrl+"/experts/getExperts.php?paged="+paged+"&mot_cle="+mot_cle+"&domaine="+domaine;
    if(type_compte.length>0){
        type_compte.forEach(element => {
            apiEndoint = apiEndoint+"&type_compte%5B%5D="+element;
        });
    }
    
    if(type_emploi.length>0){
        type_emploi.forEach(element => {
            apiEndoint = apiEndoint+"&type_emploi%5B%5D="+element;
        });
    }
    if(experiences.length>0){
        experiences.forEach(element => {
            apiEndoint = apiEndoint+"&experience%5B%5D="+element;
        });
    }
    if(niveau_etudes.length>0){
        niveau_etudes.forEach(element => {
            apiEndoint = apiEndoint+"&niveau_etude%5B%5D="+element;
        });
    }
    if(regions.length>0){
        regions.forEach(element => {
            apiEndoint = apiEndoint+"&gouvernorat%5B%5D="+element;
        });
    }
    return http.get(apiEndoint);
}
const getDetail = (slug) => {
    const apiEndoint =  apiUrl+"/experts/getDetail.php?slug="+slug;
    return http.get(apiEndoint);
}

const getDetailByID = (id) => {
    const apiEndoint =  apiUrl+"/experts/getDetailByID.php";
    const formData = new FormData();
    formData.append("ID", id);
    return http.post(apiEndoint,formData);
}

const getCategories = () => {
    const apiEndoint =  apiUrl+"/experts/getCategories.php";
    return http.get(apiEndoint);
}

const getInfoInscription = () => {
    const apiEndoint =  apiUrl+"/experts/getInfoInscription.php";
    return http.get(apiEndoint);
}


const getProposerPM = () => {
    const apiEndoint =  apiUrl+"/experts/getInfoInscription.php?type=getProposerPM";
    return http.get(apiEndoint);
}
const registerProposerEmploi = (expertid,data,competences) => {
    const apiEndoint =  apiUrl+"/security/registerProposerEmploi.php";
    const formData = new FormData();
    formData.append("competences", competences);
    formData.append("expertid", expertid);
    formData.append("token", localStorage.getItem("tokenb2b"));
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return http.post(apiEndoint,formData);
}
const registerProposerProjet = (expertid,data,competences) => {
    const apiEndoint =  apiUrl+"/security/registerProposerProjet.php";
    const formData = new FormData();
    formData.append("competences", competences);
    formData.append("expertid", expertid);
    formData.append("token", localStorage.getItem("tokenb2b"));
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return http.post(apiEndoint,formData);
}


const registerMessage = (expertid,data) => {
    const apiEndoint =  apiUrl+"/security/registerMessageExpert.php";
    const formData = new FormData();
    formData.append("expertid", expertid);
    formData.append("token", localStorage.getItem("tokenb2b"));
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return http.post(apiEndoint,formData);
}

export default 
{
    getExperts,
    getDetail,
    getCategories,
    getInfoInscription,
    getProposerPM,
    registerProposerProjet,
    registerProposerEmploi,
    registerMessage,
    getDetailByID
}