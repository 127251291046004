import React, { Component} from 'react';
import ReactStars from "react-rating-stars-component";
import Products from "../../../services/products";
export default class BlockLaisserAvis extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            avisStars:1,
            avisText:"",
            errorAvisText:false,
            save:false,
            loderFrome:"d-none",
            listeavis:[]
        }
        setTimeout(() => {
            Products.getAvis(this.props.data.ID).then((res)=>{
                if(res.data.get){
                    console.log(res);
                    this.setState({
                        listeavis:[]
                    })
                    var listeavis = this.state.listeavis;
                    res.data.avis.forEach(avis => {
                        listeavis.push({user:avis.user,userimg:avis.userimg,note:avis.note,text:avis.text,date:avis.date});
                    });
                    this.setState({
                        listeavis:listeavis
                    })
                }
            });
        }, 10);
    }
    ratingChanged = (newRating) => {
        this.setState({
            avisStars:newRating
        });
    };
    avisTextChanged = (e) => {
        this.setState({
            avisText:e.target.value
        });
    };
    saveAvis = ()=>{
        if (this.state.avisText=="") {
            this.setState({
                errorAvisText:true
            });
            return false;
        }else{
            this.setState({
                loderFrome:"loder-from"
            });
            Products.saveAvis(this.props.data.ID,this.state.avisStars,this.state.avisText).then((res)=>{
                if(res.data.save){
                    console.log(res);
                    this.setState({
                        avisStars:1,
                        avisText:"",
                        errorAvisText:false,
                        save:true,
                        loderFrome:"d-none"
                    });
                    Products.getAvis(this.props.data.ID).then((res)=>{
                        if(res.data.get){
                            
                            this.setState({
                                listeavis:[]
                            })
                            var listeavis = this.state.listeavis;
                            res.data.avis.forEach(avis => {
                                listeavis.push({user:avis.user,userimg:avis.userimg,note:avis.note,text:avis.text,date:avis.date});
                            });
                            this.setState({
                                listeavis:listeavis
                            })
                        }
                    });
                }
            })
        }
    }
    
    render() {
        if(this.props.data && this.props.userLogin){
            var avis=[];
            this.state.listeavis.forEach(element => {
                avis.push( 
                <li className="comment">
                    <div className="comment_container"> 
                        <img onContextMenu={(e)=> e.preventDefault()} className="avatar avatar-60 photo" src={element.userimg} alt={element.user}  />
                        <div className="comment-text">
                            <div className='star-rating'>
                                 <ReactStars
                                    count={5}
                                    size={24}
                                    value={element.note}
                                    activeColor="#ffd700"
                                />
                            </div>
                            <p className="meta"> 
                                <strong className="author">{element.user} </strong> 
                            </p>
                            <div className="description">
                                <p>{element.text}</p>
                            </div>
                            <p className="meta date"> 
                                <span><i className="fa fa-clock-o" />{element.date} </span> 
                            </p>
                        </div>
                        
                    </div>
                </li>);
            });
            return (
                <div id="_avis-produit" className="">
                    <div className="fl-wrap" id="sec4">
                        <div className="list-single-main-item-title fl-wrap">
                            <h3 className="size-titre-b2b grie-b2b" style={{textTransform: 'uppercase'}}>Laisser un Avis :</h3>
                        </div>
                        
                        <div className={"row "}>
                            {this.props.data.company_user != this.props.dataUser.code_user?
                            <div className="col-md-6">	
                                {this.state.save?<div className="sund-form-sucsses">
                                    <span>Votre Avi a été enregistrée avec succès</span>
                                </div>:<>
                                <div className={this.state.loderFrome}>
                                    <span className='loading-login'></span>
                                </div>		
                                <div className="comment-form-rating w-100">
                                    <label className="m-r10 w-100 mb-0">Votre note</label>
                                        <ReactStars
                                            count={5}
                                            onChange={this.ratingChanged}
                                            size={24}
                                            value={this.state.avisStars}
                                            activeColor="#ffd700"
                                        />
                                    <div className="comment-form-comment w-100 p-0">
                                        <label className="w-100 mb-0">Votre avis</label>
                                        <textarea  rows={8} cols={45} onChange={this.avisTextChanged} >{this.state.avisText}</textarea>
                                        {this.state.errorAvisText?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                    </div>
                                    {this.props.dataUser.valid_b2b=="ok"?
                                    <div className="form-submit">
                                        <button type="button" onClick={this.saveAvis} className="site-button btn-b2b w-100" >Ajouter</button>
                                    </div>
                                    :
                                    <div className="form-submit">
                                       Vous ne pouvez pas effectuer cette action avant la validation de votre compte.
                                    </div>}
                                </div>
                                </>}
                            </div>
                            :<></>}
                            <div className="col-md-6 listes-avis-clients">
                                <div id="comments">
                                    <ol className="commentlist">			
                                       {avis}
                                    </ol>
                                </div>		
                            </div>
                        </div>
                    </div>
                </div>
            );
        }else{
            return (
                <div id="_avis-produit" className="">
                    <h3 className="size-titre-b2b grie-b2b" style={{textTransform: 'uppercase'}}>Demander un Avis :</h3>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                </div>
            );
        }
    }
}