
import './style.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Experts from '../../services/experts';
import offresMission from '../../services/offresMission';
import { Markup } from 'react-render-markup';
import Select from 'react-select'
import {b2bNameSite,b2bDescriptionSite} from '../../config.json';
import MetaTags from 'react-meta-tags';
import Pagination from "react-js-pagination";
import Slider from '../Slider/SliderFacebook';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga'
class Index extends Component
{
    constructor (props) {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname);
        const { innerWidth: width, innerHeight: height } = window;
        super(props)
        this.state = {
            items : [<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>],
            categorieoptions : [{ value: '', label: '' }],
            search_mot_cle : "", 
            search_localisation : [], 
            search_duree_mission : [], 
            search_budgets : [],
            search_domaines_freelance : [] , 
            activePage : 1 ,
            totalItemsCount : 0,
            itemsPerPage :14,
            localisation: [],
            duree_mission: [],
            budgets: [],
            domaines_freelance : [] ,
            progressPage : 0,
            showProgressPage:true,
            showfiltre:width>700?"0":"-100",
        }
        setInterval(() => {
            if(this.state.progressPage<80){
                this.setState({progressPage:this.state.progressPage++});
            }
        }, 100);
        offresMission.getOffresMissions().then((res)=>{
            this.updatedate(res);
            this.setState({progressPage : 100});
            setTimeout(() => {
                this.setState({showProgressPage:false}); 
            }, 1000);
        });
    }
    changeSearch = (e)=>{
        this.setState({
            search_mot_cle : e.target.value
        });
    }
    submitSearch= ()=>{
        offresMission.getOffresMissions(
            this.state.activePage,
            this.state.search_mot_cle,
            this.state.search_domaines_freelance,
            this.state.search_budgets,
            this.state.search_duree_mission,
            this.state.search_localisation,
            ).then((res)=>{
            this.updatedate(res);
        });
    }
    handlePageChange(pageNumber) {
        
        this.setState({activePage: pageNumber});
        this.initItems();
        offresMission.getOffresMissions(
            pageNumber,
            this.state.search_mot_cle,
            this.state.search_domaines_freelance,
            this.state.search_budgets,
            this.state.search_duree_mission,
            this.state.search_localisation
            ).then((res)=>{
            this.updatedate(res);
        });
    }
    initItems(){
        window.scrollTo(0, 0);
        this.setState({items : [
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>
        ]});
    }
    updatedate(res) {
        if(res.data.get){
            var items = [];
            res.data.data.forEach(offres => {
                items.push(<Item 
                    t={this.props.t}
                    key={offres.id} 
                    id={offres.id}
                    url={offres.url}
                    img={offres.img}
                    title={offres.title}
                    content={offres.content}
                    date={offres.date}
                    />);
            }); 
            this.setState({items : []});
            this.setState({items : items});
            this.setState({
                items : items,
                localisation: res.data.localisation,
                duree_mission: res.data.duree_mission,
                budgets: res.data.budgets,
                domaines_freelance : res.data.domaines_freelance ,
                totalItemsCount : res.data.nbposts
            });
        }
    }
    changelocalisation(event){
        var value = Array.from(this.state.search_localisation);
        if (event.target.checked == true){
            value.push(event.target.value);
            this.setState({search_localisation: value }); 
        }else{
            var index = value.indexOf(event.target.value)
            if (index !== -1) {
                value.splice(index, 1);
            }
            this.setState({search_localisation: value});
        }
        
        
        offresMission.getOffresMissions(
            this.state.activePage,
            this.state.search_mot_cle,
            this.state.search_domaines_freelance,
            this.state.search_budgets,
            this.state.search_duree_mission,
            value
            ).then((res)=>{
            this.updatedate(res);
        });
    }
    changeDureeMission(event){
        var value = Array.from(this.state.search_duree_mission);
        if (event.target.checked == true){
            value.push(event.target.value);
            this.setState({search_duree_mission: value }); 
        }else{
            var index = value.indexOf(event.target.value)
            if (index !== -1) {
                value.splice(index, 1);
            }
            this.setState({search_duree_mission: value});
        }
    
        offresMission.getOffresMissions(
            this.state.activePage,
            this.state.search_mot_cle,
            this.state.search_domaines_freelance,
            this.state.search_budgets,
            value,
            this.state.search_localisation
            ).then((res)=>{
            this.updatedate(res);
        });
    }
    changeBudgets(event){
        var value = Array.from(this.state.search_budgets);
        if (event.target.checked == true){
            value.push(event.target.value);
            this.setState({search_budgets: value }); 
        }else{
            var index = value.indexOf(event.target.value)
            if (index !== -1) {
                value.splice(index, 1);
            }
            this.setState({search_budgets: value});
        }
        offresMission.getOffresMissions(
            this.state.activePage,
            this.state.search_mot_cle,
            this.state.search_domaines_freelance,
            value,
            this.state.search_duree_mission,
            this.state.search_localisation
            ).then((res)=>{
            this.updatedate(res);
        });
    }
    changeDomaines(event){
        var value = Array.from(this.state.search_domaines_freelance);
        if (event.target.checked == true){
            value.push(event.target.value);
            this.setState({search_domaines_freelance: value }); 
        }else{
            var index = value.indexOf(event.target.value)
            if (index !== -1) {
                value.splice(index, 1);
            }
            this.setState({search_domaines_freelance: value});
        }
        offresMission.getOffresMissions(
            this.state.activePage,
            this.state.search_mot_cle,
            value,
            this.state.search_budgets,
            this.state.search_duree_mission,
            this.state.search_localisation,
            value).then((res)=>{
            this.updatedate(res);
        });
    }
    showfiltre= ()=>{
        if(this.state.showfiltre=="-100"){
            this.setState({
                showfiltre:"0"
            })
        }else{
            this.setState({
                showfiltre:"-100"
            })
        }
       
    }
    
    render() {
      
        const { t } = this.props 
        var localisation=[];
        if(this.state.localisation){
            this.state.localisation.forEach(element => {
                localisation.push(
                <div>
                    <input type="checkbox" className="click-submit" id={"checkbox_localisation_"+element.slug+"_"+element.id} value={element.id} onChange={this.changelocalisation.bind(this)}/>
                    <label for={"checkbox_localisation_"+element.slug+"_"+element.id} className="l" >{element.nom}</label> 
                    <br />
                </div>)
            });
        }
        var duree_mission=[];
        if(this.state.duree_mission){
            this.state.duree_mission.forEach(element => {
                duree_mission.push(
                <div>
                    <input type="checkbox" className="click-submit" id={"checkbox_duree_mission_"+element.slug+"_"+element.id} value={element.id} onChange={this.changeDureeMission.bind(this)}/>
                    <label for={"checkbox_duree_mission_"+element.slug+"_"+element.id} className="l" >{element.nom}</label> 
                    <br />
                </div>)
            });
        }

       
        var budgets=[];
        if(this.state.budgets){
            this.state.budgets.forEach(element => {
                budgets.push(
                <div>
                    <input type="checkbox" className="click-submit" id={"checkbox_budgets_"+element.slug+"_"+element.id} value={element.id} onChange={this.changeBudgets.bind(this)}/>
                    <label for={"checkbox_budgets_"+element.slug+"_"+element.id} className="l" >{element.nom}</label> 
                    <br />
                </div>)
            });
        }
        var domaines_freelance=[];
        if(this.state.domaines_freelance){
            this.state.domaines_freelance.forEach(element => {
                domaines_freelance.push(
                <div>
                    <input type="checkbox" className="click-submit" id={"checkbox_domaines_freelance_"+element.slug+"_"+element.id} value={element.id} onChange={this.changeDomaines.bind(this)}/>
                    <label for={"checkbox_domaines_freelance_"+element.slug+"_"+element.id} className="l" >{element.nom}</label> 
                    <br />
                </div>)
            });
        }
        return ( <>
            {this.state.showProgressPage?<div class="progress-div" style={{width: "100%"}}><div style={{width: this.state.progressPage+"%"}} class="progress"></div></div>:<></>}
            <MetaTags>
                <title>{t("Offres missions en Tunisie et à l’international")} | {b2bNameSite} | {b2bDescriptionSite}</title>
            </MetaTags>
            <div className="page-content">
                <section id="list-places-wrapper" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 widget de_widget_categories prod-list" id="search-places">
                                <form role="search" className="place_search_form" action="" method="GET">
                                    <div className="row block-search-all ml-0">
                                        <div className="row col-md-11 col-10 de-search-form border-block" id="search-places" style={{"margin-left": "0px"}}>
                                            <div className="col-md-12 block-search-keyword">
                                                <div className="de-scontrol">
                                                    <input type="text" name="mot_cle" onChange={this.changeSearch} className="keyword-search" placeholder="Entrez un mot-clé ..." value={this.state.search_mot_cle} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 col-2 block-search-btn" style={{"padding-right":"0"}}>
                                            <button className="btn btn-primary search-btn" type="button" onClick={this.submitSearch} style={{"background-color":"#ED2829","border":"none","height":"65px","width": "100%",marginBottom: "1px"}}>
                                                <i className="fa fa-search fa-lg"></i>
                                            </button>
                                            <button className="btn btn-primary show-mobile" type="button" onClick={this.showfiltre} style={{"background-color":"#ED2829","border":"none","height":"65px","width": "100%",borderRadius: "0px 0px 25px 25px"}}>
                                                <i className="fa fa-filter fa-lg"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className={"col-md-2 border-block block-fillter-expert block-fillter-mobile p"+this.state.showfiltre} id="block-fillter-mobile" style={{left:this.state.showfiltre+"%"}}>
                                <div className='masque-close-fillter' onClick={this.showfiltre}><span>x</span></div>
                                <div className="row p-2">
                                    {domaines_freelance?
                                    <div className="col-md-12">
                                        <h4 className="h4 ">{t("Catégories")}: </h4>
                                        {domaines_freelance}  
                                    </div>
                                    :<></>}
                                    {budgets?
                                    <div className="col-md-12">
                                        <h4 className="h4 ">{t("Budget")}: </h4>
                                        {budgets}  
                                    </div>
                                    :<></>}
                                    {duree_mission?
                                    <div className="col-md-12">
                                        <h4 className="h4 ">{t("Durée de la mission")}: </h4>
                                        {duree_mission}  
                                    </div>
                                    :<></>}
                                    {localisation?
                                    <div className="col-md-12">
                                        <h4 className="h4 ">{t("Localisation")} : </h4>
                                        {localisation}  
                                    </div>
                                    :<></>}
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                {/* <div className="col-md-12 widget de_widget_categories prod-list" style={{"margin-bottom": "10px","text-transform":  "uppercase","text-align": "center"}}><h3></h3></div> */}
                                <div className="col-md-12">
                                    {this.state.items}
                                    <div className="paginations-wrapper">
                                        <div className="paginations">
                                            {this.state.totalItemsCount>this.state.itemsPerPage?
                                            <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.itemsPerPage}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                            />
                                            :<></>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 ">
                                <div className="border-block" style={{padding:"15px"}}><Slider /></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>);
    }
}


export default withTranslation()(Index);


class Item extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            id : props.id,
            url:props.url,
            img:props.img,
            title:props.title,
            content:props.content,
            date:props.date,
        }
    }
  
    render() {
        const { t } = this.props 
        return ( 
            <div className="row">
                <div className="col-md-12 " style={{backgroundColor: '#fff', marginBottom: '10px', paddingTop: '10px', border: '1px solid #b8c7c8'}}>
                    <div className="row">
                        <div className="col-md-3" style={{paddingLeft: 0, textAlign: 'center'}}>
                            <div className="cont-img-offre"> 
                            <Link to={"/"+i18next.language+this.state.url}>  <img onContextMenu={(e)=> e.preventDefault()} src={this.state.img} style={{width: '100%', height: 'auto'}} /></Link>
                            </div>
                        </div>
                        <div className="col-md-9 pl-1">
                            <Link to={"/"+i18next.language+this.state.url} style={{color: '#ED2829', borderBottom: '1px solid #ED2829', fontFamily: 'calibri', fontWeight: 700, textTransform: 'uppercase'}}><Markup markup={this.state.title} /></Link>
                            <br />
                            <div style={{textAlign: 'justify'}}><Markup markup={this.state.content} /><br /></div>
                            
                           
                        </div>
                        <div className="col-md-12 pl-1 pb-1 pt-1 d-flex justify-content-between">
                            <div className="block-marker">
                                <i className="fa fa-calendar" style={{fontSize: '15px', paddingRight: '5px'}} />{this.state.date}
                            </div>
                            <div className="" style={{textAlign: 'justify'}}>
                            <Link to={"/"+i18next.language+this.state.url} style={{border: '1px solid #ED2829', padding: '0 10px', color: '#ED2829', fontFamily: 'calibri', marginBottom: '5px', float: 'right'}}> {t("Voir")} +</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
class ItemLoader extends Component
{
    render() {

        return ( 
            <div className="row">
                <div className="col-md-12 " style={{backgroundColor: '#fff', marginBottom: '10px', paddingTop: '10px', border: '1px solid #b8c7c8'}}>
                    <div className="row">
                    <div className="col-md-3" style={{paddingLeft: 0, textAlign: 'center'}}>
                        <div className="cont-img-offre"> 
                        <a href="#">  
                            <div className="loder-image-offre" ></div>
                        </a>
                        <div className="block-marker block-marker-loder">
                            <i className="fa fa-calendar" style={{fontSize: '15px', paddingRight: '5px'}} /><span className="loder-span ml-0"></span></div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <a href="#" style={{color: '#ED2829', borderBottom: '1px solid #ED2829', fontFamily: 'calibri', fontWeight: 700, textTransform: 'uppercase'}}><span className="loder-span  ml-0"></span></a>
                        <br />
                        <div style={{textAlign: 'justify'}}>
                            <span className="loder-p"></span>
                            <span className="loder-p"></span>
                        </div>
                        <div className="col-md-12" style={{textAlign: 'justify'}}>
                        <a href="" style={{border: '1px solid #ED2829', padding: '0 10px', color: '#ED2829', fontFamily: 'calibri', marginBottom: '5px', float: 'right'}}> Voir +</a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}
