
import React, { Component} from 'react';
import Products from '../../../services/products';
import Slider from '../../Slider/SliderEnVedette';
import Modal, {closeStyle} from 'simple-react-modal';
import BtnPartage from '../../Slider/btnPartage';
import Gallery from './gallery';
import FormDevis from './FormDevis';
import BlockLaisserAvis from './laisserAvis';
import {prefixe,b2bNameSite} from '../../../config.json';
import Login from '../../header/loginheader';
import ModalDevi from './ModalDevi';
import Auth from '../../../services/Auth';
import jwt_decode from "jwt-decode";
import AliceCarousel from 'react-alice-carousel';
import ReactPlayer from "react-player"
import { Markup } from 'react-render-markup';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import PageVisite from '../../Slider/pageVisite';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../../Entreprises/detail.css';
import './detail.css';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga'
class Index extends Component
{
    constructor (props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
      
        if(document.getElementById("header_b2b")){
            document.getElementById("header_b2b").style.opacity="1";
            document.getElementById("header_b2b").style.display="block";
        }
        super(props)
        var userLogin=false;
        var dataUser=null;
        const tokenb2b = localStorage.getItem('tokenb2b');
        if(tokenb2b && tokenb2b!=""){
            userLogin=true;
            dataUser = jwt_decode(tokenb2b);
        }
        this.state = {
            userLogin:userLogin,
            dataUser:dataUser,
            slug : props.slug,
            blockmenu : <BlockDescription t={this.props.t} data={null} />,
            data: null,
            Presentation : <BlockPresentation t={this.props.t} data={null} />,
            infoCompany : <LoderBlockInfoCompany t={this.props.t} />,
            produitsAussi : <LoderBlockProduitsAussi t={this.props.t} />,
            gallery : <></>,
            modaleLoginDevi : false,
            modaleLoginAvis : false,
            htmlModaleLogin : <></>,
            name : "",
            modaleyoutube:false,
            htmlModaleYoutube: <></>,
            menuRef : React.createRef() ,
            youtube_key :"oGc-SuQNBiY",
        }
        Auth.isLogin().then((res)=>{
            if(res.data!=false){
                this.setState({ 
                    userLogin:true,
                    dataUser:res.data
                });
            }else{
                this.setState({ 
                    userLogin:false,
                    dataUser:null
                });
            }
        });
        const urlParams = new URLSearchParams(window.location.search);
        const devisProduit = urlParams.get('devis-produit');
        if(devisProduit){
            setTimeout(() => {
                const e = document.getElementById("devis-produithref");
                this.selectItem(e);
                if(this.state.userLogin){
                    this.setState({
                        blockmenu : <BlockDemandeDevis t={this.props.t} data={this.state.data} userLogin={this.state.userLogin} dataUser={this.state.dataUser}/>,
                        modaleLoginDevi : false,
                        modaleLoginAvis : false,
                        htmlModaleLogin : <></>
                    });  
                }else{
                    this.setState({
                        blockmenu : <></>,
                        modaleLoginDevi : true,
                        modaleLoginAvis : false,
                        htmlModaleLogin : <Login close={this.close.bind(this)}/>
                    });  
                }
                
            }, 1000);
        }
        Products.getDetail(this.state.slug).then((res)=>{
            if(res.data.get){
                console.log(res.data);
                this.setState({
                    blockmenu : <></>,
                    Presentation : <></>,
                    gallery : <></>,
                    infoCompany : <></>,
                });
                this.setState({
                    data: res.data.data,
                    blockmenu : <BlockDescription t={this.props.t} data={res.data.data} />,
                    Presentation : <BlockPresentation t={this.props.t} data={res.data.data} />,
                    gallery : <Gallery data={res.data.data} />,
                    infoCompany : <BlockInfoCompany t={this.props.t} data={res.data.data} />,
                    produitsAussi : <BlockProduitsAussi t={this.props.t} data={res.data.data}/>,
                    name : res.data.data.post_title,
                });
                setTimeout(() => {
                    this.setState({
                        gallery : <Gallery data={res.data.data} />,
                        infoCompany : <BlockInfoCompany t={this.props.t} data={res.data.data} />,
                    });
                }, 1000);
                setTimeout(() => {
                    this.setState({
                        produitsAussi : <BlockProduitsAussi t={this.props.t} data={res.data.data}/>,
                    });
                }, 3000);
            }
        });
       

    }
   
    selectItem = (e)=>{
        var items = document.getElementsByClassName("active");
        var i;
        for (i = 0; i < items.length; i++) {
            items[i].classList.remove("active");
        }
        e.classList.add('active');
        this.scrollMenu(e);
    }
    scrollMenu = ()=>{
        var win = window,doc = document,docElem = doc.documentElement,body = doc.getElementsByTagName('body')[0],x = win.innerWidth || docElem.clientWidth || body.clientWidth,y = win.innerHeight|| docElem.clientHeight|| body.clientHeight;
        window.scrollTo(0,730);
        // document.getElementById("header_b2b").style.display="none";
        // this.setState({sue_menu_entreprise_b2b:{
        //     position: "fixed",
        //     zIndex: "9999",
        //     top: "0px",
        //     left: "0px",
        //     width: "100%",
        //     backgroundColor: "#fff",
        //     display: "flex",
        //     justifyContent: "center",
        // }})
        // if(x<700){
        //     window.scrollTo(0,1000);
        // }
    }
    description = (e)=>{
        this.selectItem(e.currentTarget);
        this.setState({
            blockmenu : <BlockDescription t={this.props.t} data={this.state.data}/>,
            modaleLoginDevi : false,
            modaleLoginAvis : false,
        });
    }
    laisserAvis = (e)=>{
        this.selectItem(e.currentTarget);
        if(this.state.userLogin){
            this.setState({
                blockmenu : <BlockLaisserAvis t={this.props.t} userLogin={this.state.userLogin} dataUser={this.state.dataUser} data={this.state.data} />,
                modaleLoginDevi : false,
                modaleLoginAvis : false,
                htmlModaleLogin : <></>
            });
        }else{
            this.setState({
                blockmenu : <></>,
                modaleLoginDevi : false,
                modaleLoginAvis : true,
                htmlModaleLogin : <Login close={this.close.bind(this)}/>
            });
        }
        
    }
    demandeDevis = (e)=>{
        this.selectItem(e.currentTarget);
        if(this.state.userLogin){
            if(this.state.dataUser.valid_b2b=="ok"){
                this.setState({
                    blockmenu : <BlockDemandeDevis t={this.props.t} data={this.state.data} userLogin={this.state.userLogin} dataUser={this.state.dataUser}/>,
                    modaleLoginDevi : false,
                    modaleLoginAvis : false,
                    htmlModaleLogin : <></>
                });
            }else{
                this.setState({
                    blockmenu : <ModalDevi t={this.props.t} minimale={this.state.data.quantite_minimale} parent={this.state.data.post_parent} IdProd={this.state.data.ID} />,
                    modaleLoginDevi : false,
                    modaleLoginAvis : false,
                    htmlModaleLogin : <></>
                });
            }
            
        }else{
            this.setState({
                blockmenu : <></>,
                modaleLoginDevi : true,
                modaleLoginAvis : false,
                htmlModaleLogin : <Login  close={this.close.bind(this)}/>
            });
        }
       

    }
    close=()=>{
        this.setState({
            modaleLoginDevi : false,
            modaleLoginAvis : false,
            htmlModaleLogin : <></>
        })
    }
    playerYoutube =(e) =>{
        this.setState({
            modaleyoutube:true,
            htmlModaleYoutube:<ReactPlayer
            url={'https://www.youtube.com/embed/'+this.state.youtube_key+'?enablejsapi=1&widgetid=1'}
            className='react-player'
            playing
            width='100%'
            height='100%'
        />
        })
    }
    closeYoutube=()=>{
        this.setState({
            modaleyoutube:false,
            htmlModaleYoutube: <></>
        })
    }
    changePossedecompte = e => {
        const { name, value } = e.target;
        if(value == "oui"){
            this.setState({
                modaleLoginDevi : true,
                modaleLoginAvis : false,
                htmlModaleLogin : <Login close={this.close.bind(this)}/>
            });
        }else{
            this.setState({
                modaleLoginDevi : true,
                modaleLoginAvis : false,
                htmlModaleLogin : <ModalDevi minimale={this.state.data.quantite_minimale} parent={this.state.data.post_parent} IdProd={this.state.data.ID} />
            });
        }
    };
    render() {
        const { t } = this.props;
        let sue_menu_entreprise_b2b={};
        console.log(this.props.offset);
        if(this.props.offset<730){
            if(document.getElementById("header_b2b"))document.getElementById("header_b2b").style.opacity="1";
            sue_menu_entreprise_b2b={};
        }else{
            if(document.getElementById("header_b2b"))document.getElementById("header_b2b").style.opacity="0";
            sue_menu_entreprise_b2b={
                position: "fixed",
                zIndex: "9999",
                top: "0px",
                left: "0px",
                width: "100%",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
            };
        }
        console.log(this.state.data);
        return (
            <div >
                {this.state.data?<PageVisite postId={this.state.data.ID}/>:<></>}
                <div className="modal-login-hadeer modal-devi-login">
                  <Modal
                     closeOnOuterClick={true}
                     show={this.state.modaleLoginDevi}
                     onClose={this.close.bind(this)}
                     >
                     <div classNameName="div-loginn">
                        <div className="connxtion_header">
                            <h2 id="to_text_pupup--" className="size-titre-b2b grie-b2b">{t("JE POSSEDE UN COMPTE SUR")} « {b2bNameSite} » ?
                                <input id="possedecompteoui" value="oui" checked="checked" name="Possedecompte" type="radio" onChange={this.changePossedecompte} /><label for="possedecompteoui" style={{marginLeft:"5px"}} >{t("OUI")}</label>
                                <input id="possedecomptenon" value="non" name="Possedecompte" type="radio" onChange={this.changePossedecompte} /><label for="possedecomptenon" style={{marginLeft:"5px"}} >{t("NON")}</label>
                            </h2>
                            <div className="divbtnclose">
                              <span className="close closemodalheader" onClick={this.close.bind(this)}>×</span>
                            </div>
                        </div>
                        {this.state.htmlModaleLogin}
                     </div>
                  </Modal>
               </div>
               <div className="modal-login-hadeer">
                  <Modal
                     closeOnOuterClick={true}
                     show={this.state.modaleLoginAvis}
                     onClose={this.close.bind(this)}
                     >
                     <div classNameName="div-loginn">
                        <div className="connxtion_header">
                           <h2 id="to_text_pupup--" className="size-titre-b2b grie-b2b">
                                {t("VEUILLEZ VOUS CONNECTER POUR LAISSER UN AVIS SUR")} <span className="place_post_title">{this.state.name}</span> :
                           </h2>
                           <div className="divbtnclose">
                              <span className="close closemodalheader" onClick={this.close.bind(this)}>×</span>
                           </div>
                        </div>
                        {this.state.htmlModaleLogin}
                     </div>
                  </Modal>
               </div>
                <div className="modal-login-hadeer modale-youtube">
                    <Modal
                        closeOnOuterClick={true}
                        show={this.state.modaleyoutube}
                        onClose={this.closeYoutube.bind(this)}
                        >
                        {this.state.htmlModaleYoutube}
                    </Modal>
                </div>
                <div className="youtube_video" onClick={this.playerYoutube}>
                    <div>
                        <img onContextMenu={(e)=> e.preventDefault()} src="/upload/image/min-youtube-prodact.jpg" style={{width: '100%', height: '350px', objectFit: 'cover'}} />
                    </div>
                    <div className="min-youtube_video">
                        <i className="fa fa-youtube-play" aria-hidden="true" style={{fontSize: '100px', color: 'red'}} />
                    </div>
                </div>
                <div className="container">
                    <div className="row mt-3">
                        <div className="col-md-9">
                            <div className="row borde-block" style={{height:"auto"}}>
                                <div className="col-md-6 px-1 pb-2 gallery-images">

                                    {this.state.gallery}
                                </div>
                                <div className="col-md-6 px-1 pb-2">
                                   {this.state.Presentation}
                                </div>
                                <div className="col-md-12 px-1 pb-2">
                                <div className="col-md-12 px-2 mt-3" style={{order: 3}}>
                                    <div className=" w-100 mt-2">
                                    <div className="list-author-widget-contacts list-item-widget-contacts">
                                        <div className="dlab-tabs product-description border-tp bg-tabs tabs-site-button">
                                        <div style={sue_menu_entreprise_b2b}>
                                            <ul className="nav nav-tabs bottom-header-bar" id="nav_produit" ref={this.state.menuRef} style={{top: 'unset', left: 'unset', position: 'unset', zIndex: 999999}}>
                                                <li className="nav-item" >
                                                    <a onClick={this.description} data-toggle="tab" className="nav-link active" id="description-produithref" href="javascript:void(0)">
                                                        <i className="fa fa-globe" /> <span>{t("Description")}</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item" >
                                                    <a onClick={this.demandeDevis} id="devis-produithref" data-toggle="tab" className="nav-link" href="javascript:void(0)">
                                                        <i className="fa fa-photo" /> <span className="sapn-devis"><span>{t("Demander un Devis")}</span></span>
                                                    </a>
                                                </li>
                                                <li className="nav-item" >
                                                    <a onClick={this.laisserAvis} id="avis-produithref" data-toggle="tab" className="nav-link" href="javascript:void(0)">
                                                        <i className="fa fa-cog" /> <span className="sapn-avis"><span>{t("Avis")}</span></span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tab-content">
                                            {this.state.blockmenu}
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-12 px-1 pb-2">
                                    {this.state.produitsAussi}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 px-1 ">
                            <div className="borde-block p-3">
                                {this.state.infoCompany}
                               <Slider event={false}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="bottom_buttons" style={{backgroundColor: '#cc4040', width: '100%', height: '52px', textAlign: 'center', position: 'fixed', bottom: 0, zIndex: 99999999}}>
                    <a className="telephone_b2b" href={"tel:"+prefixe+(this.state.data?this.state.data.company_phone!=""?this.state.data.company_phone:this.state.data.company_fixe!=""?this.state.data.company_fixe:this.state.data.company_fixe2:"")}>
                        <div style={{backgroundColor: '#AFAAA4', width: '49.5%', height: '52px', display: 'inline-block', color: 'white', paddingTop: '5px', fontSize: '22px', marginLeft: '-5px'}}>
                        <i className="fa fa-phone" /> {t("Appeler")}
                        </div>
                    </a>
                    <a onClick={this.contact} href="#contact" id="contacterlink2" role="tab" data-toggle="tab">
                        <div style={{backgroundColor: '#cc4040', width: '48%', height: '52px', display: 'inline-block', color: 'white', paddingTop: '5px', fontSize: '22px'}}>
                        <i className="fa fa-envelope-o" /> {t("Contacter")}
                        </div>
                    </a>
                </div>
            </div>
        );
    }
}
export default withTranslation()(Index);
class BlockPresentation extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            dirigeants : false
        }
    }
    render() {
        const { t } = this.props;
        if(this.props.data){
            return (
                <div className="mobil-order-info-product px-5">
                    <h2 className="post-title">
                        <a href="#" className="size-titre-b2b" style={{color: '#e11a18', "font-weight": '700!important'}}>{this.props.data.post_title}</a>
                    </h2>
                   
                    <div className="block-prix">
                        <table className="relative-table mt-3">
                            <tbody>
                                <tr>
                                    <td className="table-titel">
                                        {t("Prix")} :
                                    </td>
                                    <td className="table-info">
                                        {(this.props.data.prix_fixe_ou_variable=="Variable")? <>{this.props.data.prix_de.replace('DT', '').replace('TND', '')} DT {t("à")} : {this.props.data.prix_a.replace('DT', '').replace('TND', '')} DT</>:
                                        (this.props.data.prix_fixe_ou_variable=="Fix")? <>{this.props.data.prix.replace('DT', '').replace('TND', '')} DT</>: <>{t("Sur Devis")}</> }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-titel">
                                        {t("Unité de vente")} :
                                    </td>
                                    <td className="table-info">
                                        {this.props.data.unite_de_vente!=""?this.props.data.unite_de_vente:<>-</>}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-titel">
                                        {t("Quantité Minimale")} :
                                    </td>
                                    <td className="table-info">
                                        {this.props.data.quantite_minimale!=""?this.props.data.quantite_minimale:<>1</>}
                                    </td>
                                </tr>
                                {this.props.data.fichier!=""?
                                <tr>
                                    <td className="table-titel">
                                        {t("Fiche Technique")} :
                                    </td>
                                    <td className="table-info">
                                       <a style={{"color":"#e11a18"}} href={this.props.data.fichier} target="_blank">{t("Télécharger")}</a>
                                    </td>
                                </tr>
                                :<></>}
                            </tbody>
                        </table>
                    </div>
                    <div className="buttons_share">
                        <label style={{marginBottom: '-15px', marginTop: '20px'}}>{t("PARTAGER SUR")}:</label>
                        <BtnPartage
                            style={{width:"200px",marginTop:"10px"}}
                            image={this.props.data.thumbnail}
                            title={this.props.data.post_title}
                            content={this.props.data.post_content}
                            url={this.props.data.urlPartage} />
                    </div>
                </div>
            );
        }else{
            return (
                <div className="mobil-order-info-product px-5">
                    <h2 className="post-title">
                        <a href="#" className="size-titre-b2b" style={{color: '#e11a18', "font-weight": '700!important'}}><div  className="block-descr-loder"></div></a>
                    </h2>
                    <div className="buttons_share">
                        <label style={{marginBottom: '-15px', marginTop: '20px'}}>{t("Quantité Minimale")}:</label>
                        <div className="essb_links essb_counter_modern_right essb_displayed_shortcode essb_share essb_template_circles-retina essb_template_blocks-retina essb_675748097 print-no" id="essb_displayed_shortcode_675748097" data-essb-postid data-essb-position="shortcode" data-essb-button-style="icon" data-essb-template="circles-retina essb_template_blocks-retina" data-essb-counter-pos="right"  data-essb-instance={675748097}>
                        <div  className="block-descr-loder"></div>
                        </div>
                    </div>
                    <div className="block-prix">
                        <table className="relative-table mt-3">
                        <tbody>
                            <tr>
                            <td className="table-titel">
                                {t("Prix")} :
                            </td>
                            <td className="table-info">
                            <div  className="block-descr-loder"></div>
                            </td>
                            </tr>
                            <tr>
                            <td className="table-titel">
                                {t("Unité de vente")} :
                            </td>
                            <td className="table-info">
                            <div  className="block-descr-loder"></div>
                            </td>
                            </tr>
                            <tr>
                            <td className="table-titel">
                                {t("Quantité Minimale")} :
                            </td>
                            <td className="table-info">
                            <div  className="block-descr-loder"></div>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>

                </div>
            );
        }

    }
}

class BlockDescription extends Component
{
    constructor (props) {
        super(props)
        this.state = {

        }
    }
    render() {
        const { t } = this.props;
        if(this.props.data){
            return (
                <div id="_description-produit" className="">
                    <h3 className="size-titre-b2b grie-b2b" style={{textTransform: 'uppercase'}}>{t("Description")} :</h3>
                    <div className="m-b10">
                        <Markup markup={this.props.data.post_content} />
                    </div>
                </div>
            );
        }else{
            return (
                <div id="_description-produit" className="">
                    <h3 className="size-titre-b2b grie-b2b" style={{textTransform: 'uppercase'}}>{t("Description")} :</h3>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                </div>
            );
        }

    }
}
class BlockDemandeDevis extends Component
{
    constructor (props) {
        super(props)
        this.state = {
             
        }
    }
    render() {
        const { t } = this.props;
        if(this.props.userLogin && this.props.data){
            return (
                <div id="_devis-produit" className="">
                    <div className="fl-wrap" id="sec4">
                        <div className="list-single-main-item-title fl-wrap">
                            <h3 className="size-titre-b2b grie-b2b" style={{textTransform: 'uppercase'}}>{t("Demander un Devis")} :</h3>
                        </div>
                        {this.props.data.company_user != this.props.dataUser.code_user?
                        <FormDevis t={this.props.t} dataUser={this.props.dataUser}  title={this.props.dataUser.title} IdProd={this.props.data.ID} parent={this.props.data.post_parent} minimale={this.props.data.quantite_minimale} />
                        :<></>}
                        {/* <form className="add-comment custom-form" method="post">
                            <fieldset>
                                <div className=" row">
                                    <div className="col-md-12 form-group">
                                        <span className="form-control" style={{paddingTop: '8px'}}>{}</span>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <input placeholder="Nom"  required type="text" name="nom" className="form-control" />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <input type="text" placeholder="Poste" required name="poste" className="form-control" />
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <input type="number" placeholder="Quantite" min={this.props.data.quantite_minimale} required name="quantite" className="form-control" />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label className="mt-2">Date de livraison : </label>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <input type="date" placeholder min="2022-01-24" required name="nbrjours" className="form-control" style={{lineHeight: 1}} />
                                    </div>
                                    <input type="hidden" placeholder defaultValue="Demande devis" required name="sujett" />
                                </div>
                                <textarea cols={40} rows={3} placeholder="Message" name="message" style={{height: '94px'}} className="form-control" defaultValue={""} />
                            </fieldset>
                            <div className=" form-group">
                                <button className="site-button button-skew-s1 m-l20 btn-b2b" type="submit" name="okk" style={{marginTop: '10px', float: 'right', borderRadius: '50px', overflow: 'auto'}}><span>Envoyer</span></button>
                            </div>
                        </form> */}
                    </div>
                </div>
            );
        }else{
            return (
                <div id="_devis-produit" className="">
                    <h3 className="size-titre-b2b grie-b2b" style={{textTransform: 'uppercase'}}>{t("Demander un Devis")} :</h3>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                    <div  className="block-descr-loder"></div>
                </div>
            );
        }

    }
}

class BlockProduitsAussi extends Component
{
    constructor (props) {
        super(props)
        var items=[]
        this.props.data.produits_aussi.forEach(produit => {
            items.push(<ItemProduitAussi t={this.props.t} data={produit}/>)
        });
        this.state = {
            items : items
        }
    }
    render() {
        const { t } = this.props;
        if(true){

            return (
                <div className="list-single-main-item">
                    <div className="list-single-main-item-title fl-wrap">
                        <h3 className="size-titre-b2b grie-b2b text-uppercase">{t("AUTRES PRODUITS DANS LE MÊME SECTEUR D’ACTIVITÉ")} :</h3>
                    </div>
                    <div className=" product-list no-padding" width="100%">
                        <AliceCarousel
                            mouseTracking
                            autoPlay={true}
                            items={this.state.items}
                            infinite
                            renderDotsItem={false}
                            autoPlayInterval = {2000}
                            responsive={{1200: {items: 4},1000: {items: 3},800: {items: 2},300: {items: 2}}} />
                    </div>
                </div>
            );
        }else{
            return (
                <div className="list-single-main-item"></div>
            );
        }

    }
}
class ItemProduitAussi extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            data : props.data
        }
    }
    render() {
        const { t } = this.props;
        return (
            <div className="partenaires-item item">
                <a href={"/"+i18next.language+this.state.data.permalink} style={{width:"100%",height:"100%",display:"block"}}>
                    <figure>
                        <img onContextMenu={(e)=> e.preventDefault()} width="200" height="200" src={this.state.data.image} className="attachment-200x200 size-200x200 " alt={this.state.data.post_title}  />
                    </figure>
                    <h6>{this.state.data.post_title}</h6>
                </a>
            </div>
        );
    }
}

class LoderBlockProduitsAussi extends Component
{
    constructor (props) {
        super(props)
        const item = <div className="partenaires-item item">
                        <div className="p-2"><div className="loder-produi-aussi-image"></div></div>
                        <div className="p-2"><h6 className="loder-produi-aussi-name"></h6></div>
                    </div>;
        this.state = {
            items : [item,item,item,item,item,item]
        }
    }
    render() {
        const { t } = this.props;
        return (
            <div className="list-single-main-item">
                <div className="list-single-main-item-title fl-wrap">
                    <h3 className="size-titre-b2b grie-b2b text-uppercase">{t("AUTRES PRODUITS DANS LE MÊME SECTEUR D’ACTIVITÉ")} :</h3>
                </div>
                <div className=" product-list no-padding" width="100%">
                    <AliceCarousel
                        mouseTracking
                        autoPlay={true}
                        items={this.state.items}
                        infinite
                        renderDotsItem={false}
                        autoPlayInterval = {2000}
                        responsive={{1200: {items: 4},1000: {items: 3},800: {items: 2},300: {items: 2}}} />
                </div>
            </div>
        );
    }
}

class BlockInfoCompany extends Component
{
    constructor (props) {
        super(props)
        var items=[];
        this.props.data.company_produis.forEach(produi => {
            items.push(
                <div className="item">
                    <a href={"/"+i18next.language+produi.permalink}>
                    <figure>
                        <img onContextMenu={(e)=> e.preventDefault()} style={{"height":"60px"}} src={produi.image} alt={produi.post_title}/>
                    </figure>
                    <h6>{produi.post_title}</h6>
                    </a>
                </div>);
        });
        this.state = {
            items : items,
            showModaleInfo : false,
            showDivHorairesTravail : false
        }

    }

    ClickInfoEntreprise = (e)=>{
        this.setState({
            showModaleInfo : true
        })

    }
    close=()=>{
        this.setState({
            showModaleInfo : false
        })
    }
    showHorairesTravail = (e)=>{
        if(this.state.showDivHorairesTravail){
            this.setState({
                showDivHorairesTravail : false
            });
        }else{
            this.setState({
                showDivHorairesTravail : true
            });
        }
    }
    render() {
        const { t } = this.props;
        var company_serve_time=<></>;
        if(this.props.data.company_serve_time == "toujoursouvert"){
            company_serve_time=<div className="toujoursouvert">{t("Toujours Ouvert")}</div>;
        }else{
            var serve_times=[];
            var jour = 1;
            this.props.data.company_serve_time.forEach(serve_time => {
                if(jour==1){
                    serve_times.push(<li><span className="opening-hours-day">{t("Lundi")} </span><span className="opening-hours-time">{serve_time=="true"?"Fermé":serve_time}</span></li>);
                }else if(jour==2){
                    serve_times.push(<li><span className="opening-hours-day">{t("Mardi")} </span><span className="opening-hours-time">{serve_time=="true"?"Fermé":serve_time}</span></li>);
                }else if(jour==3){
                    serve_times.push(<li><span className="opening-hours-day">{t("Mercredi")} </span><span className="opening-hours-time">{serve_time=="true"?"Fermé":serve_time}</span></li>);
                }else if(jour==4){
                    serve_times.push(<li><span className="opening-hours-day">{t("Jeudi")} </span><span className="opening-hours-time">{serve_time=="true"?"Fermé":serve_time}</span></li>);
                }else if(jour==5){
                    serve_times.push(<li><span className="opening-hours-day">{t("Vendredi")} </span><span className="opening-hours-time">{serve_time=="true"?"Fermé":serve_time}</span></li>);
                }else if(jour==6){
                    serve_times.push(<li><span className="opening-hours-day">{t("Samedi")} </span><span className="opening-hours-time">{serve_time=="true"?"Fermé":serve_time}</span></li>);
                }else if(jour==7){
                    serve_times.push(<li><span className="opening-hours-day">{t("Dimanche")} </span><span className="opening-hours-time">{serve_time=="true"?"Fermé":serve_time}</span></li>);
                }
                jour++;
            });
            company_serve_time = <div className="box-widget-content"><ul>{serve_times}</ul></div>;
        }
        return (
            <>
                <div className="modal-login-hadeer modale-info-company">
                    <Modal
                        closeOnOuterClick={true}
                        show={this.state.showModaleInfo}
                        onClose={this.close.bind(this)}
                        >
                        <div classNameName="div-loginn">
                            <div className="connxtion_header">
                                <h2 id="to_text_pupup--" className="size-titre-b2b grie-b2b">{t("Infos Entreprise")} <a className="place_post_title" href={"/"+i18next.language+this.props.data.company_permalink}>{this.props.data.company_title}</a>: </h2>
                                <div className="divbtnclose">
                                    <span className="close closemodalheader" onClick={this.close.bind(this)}>×</span>
                                </div>
                            </div>
                            <div className="connxtion_body">
                                <figure className="figure">
                                    <Link to={"/"+i18next.language+this.props.data.company_permalink}>
                                        <span>
                                            <img onContextMenu={(e)=> e.preventDefault()} src={this.props.data.company_thumbnail} style={{width: '150px'}} />
                                        </span>
                                    </Link>
                                </figure>
                                <div>
                                    <p className="text-titel-pupup-info-omp mb-1 place_post_title">
                                        <span className="grie-b2b"><i className="fa fa-map-marker" /> {t("Adresse")} :</span>
                                        {this.props.data.company_adresse}
                                    </p>
                                    {this.props.data.company_fixe!=""?
                                    <p className="text-titel-pupup-info-omp mb-1 place_post_title">
                                        <span className="grie-b2b"><i className="fa fa-phone" /> {t("Fixe")} :</span>
                                        <a href={"tel:"+this.props.data.company_fixe}><span></span>{prefixe}<span className="formatFixe">{this.props.data.company_fixe}</span></a>
                                    </p>
                                    :<></>}
                                    {this.props.data.company_fixe2!=""?
                                    <p className="text-titel-pupup-info-omp mb-1 place_post_title">
                                        <span className="grie-b2b"><i className="fa fa-phone" /> {t("Fixe")}2 :</span>
                                        <a href={"tel:"+this.props.data.company_fixe2}><span>{prefixe}</span> <span className="formatFixe">{this.props.data.company_fixe2}</span></a>
                                    </p>
                                    :<></>}
                                    {this.props.data.company_phone!=""?
                                    <p className="text-titel-pupup-info-omp mb-1 place_post_title">
                                        <span className="grie-b2b"><i className="fa fa-phone" /> {t("Téléphone")} :</span>
                                        <a href={"tel:"+this.props.data.company_phone}><span>{prefixe}</span> <span className="formatFixe">{this.props.data.company_phone}</span></a>
                                    </p>
                                    :<></>}
                                    {this.props.data.company_fax!=""?
                                    <p className="text-titel-pupup-info-omp mb-1 place_post_title">
                                        <span className="grie-b2b"><i className="fa fa-phone" /> {t("Fax")} :</span>
                                        <a href={"tel:"+this.props.data.company_fax}><span>{prefixe}</span> <span className="formatFixe">{this.props.data.company_fax}</span></a>
                                    </p>
                                    :<></>}
                                    <p className="text-titel-pupup-info-omp mb-1 place_post_title">
                                        <span className="grie-b2b"><i className="fa fa-globe" /> {t("Site Web")} :</span>
                                        <a target="_blank" href={this.props.data.company_sitweb}>{t("Cliquez ICI")}</a>
                                    </p>
                                    {(this.props.data.company_linkedin!="" || this.props.data.company_fb!="")?<>
                                    <p className="text-titel-pupup-info-omp mb-1 place_post_title">
                                        <span className="grie-b2b"> {t("Suivre")} </span> <b>{this.props.data.company_title}</b> <span className="grie-b2b"> {t("sur les réseaux sociaux")} :</span>
                                    </p>
                                    <div className="company-sociaux">
                                        {this.props.data.company_linkedin!=""?<a target="_blank" href={this.props.data.company_linkedin}><i className="fa fa-linkedin" /></a>:<></>}
                                        {this.props.data.company_fb!=""?<a target="_blank" href={this.props.data.company_fb}><i className="fa fa-facebook" /></a>:<></>}
                                    </div>
                                    </>:<></>}
                                    <p className="text-titel-pupup-info-omp mb-1 place_post_title cursor-pointer" onClick={this.showHorairesTravail}>
                                        <span className="grie-b2b">{t("Horaires de travail de")}</span> <b>{this.props.data.company_title}</b><span> : </span>
                                    </p>
                                    <div id="horaires_de_travail" style={this.state.showDivHorairesTravail?{display:"block"}:{display:"none"}}>
                                        <div className="box-widget-item fl-wrap" >
                                            <div className="box-widget opening-hours">
                                                {company_serve_time}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
                <div id="block_info_company">
                    <a href={"/"+i18next.language+this.props.data.company_permalink}>
                        <figure style={{textAlign: 'center'}}>
                            <img onContextMenu={(e)=> e.preventDefault()} src={this.props.data.company_thumbnail} style={{height: '150px', width: 'auto', objectFit: 'cover'}} />
                        </figure>
                        <h2 style={{textAlign: 'center'}} className="size-titre-b2b">{this.props.data.company_title} </h2>
                    </a>
                    <div style={{textAlign: 'center'}}>
                        <div onClick={this.ClickInfoEntreprise} className="btn btn-info-company" id="btn_info_company">
                            {t("Infos Entreprise")}
                        </div>
                    </div>
                    <div className="produit-aussi mt-4">
                        <label className="m-b20">{t("LES AUTRES PRODUITS DE")} <span className="place_post_title">{this.props.data.company_title}</span> :</label>
                        <AliceCarousel
                            mouseTracking
                            autoPlay={true}
                            items={this.state.items}
                            infinite
                            renderDotsItem={false}
                            autoPlayInterval = {2000}
                            responsive={{1200: {items: 2},1000: {items: 2},800: {items: 2},300: {items: 2}}} />
                    </div>
                </div>
            </>
        );
    }
}

class LoderBlockInfoCompany extends Component
{

    render() {
        const { t } = this.props;
        var  items = [
            <div className="item">
                <a href="#">
                <figure>
                    <div className="loder-prod-InfoCompany"></div>
                </figure>
                <h6><div  className="block-descr-loder"></div></h6>
                </a>
            </div>,
            <div className="item">
                <a href="#">
                <figure>
                    <div className="loder-prod-InfoCompany"></div>
                </figure>
                <h6><div  className="block-descr-loder"></div></h6>
                </a>
            </div>];
        return (
            <div id="block_info_company">
                <a href="#">
                    <figure style={{textAlign: 'center'}}>
                        <div></div>
                    </figure>
                    <h2 style={{textAlign: 'center'}} className="size-titre-b2b"> </h2>
                </a>
                <div style={{textAlign: 'center'}}>
                    <div className="btn btn-info-company" id="btn_info_company">
                        {t("Infos Entreprise")}
                    </div>
                </div>
                <div className="produit-aussi mt-4">
                    <label className="m-b20">{t("LES AUTRES PRODUITS")}  :</label>
                    <AliceCarousel
                            mouseTracking
                            autoPlay={true}
                            items={items}
                            infinite
                            renderDotsItem={false}
                            autoPlayInterval = {2000}
                            responsive={{1200: {items: 2},1000: {items: 2},800: {items: 2},300: {items: 2}}} />
                </div>
            </div>
        );

    }
}
