
import './styleSlider.css';
import React, { Component } from 'react';
import PageFacebook from './pageFacebook';
import i18next from 'i18next';
export default class Index extends Component
{
    constructor (props) {
        super(props)
        
    }
    render() {
        if(this.props.data){
            var ItmesPosts = [];
            this.props.data.forEach(post => {
                ItmesPosts.push(<Item data={post}/>);
            });
            return ( 
                <>
                    <div className="col-md-12 px-2" style={{backgroundColor: '#fff', paddingTop: '10px', marginBottom: '15px', border: '1px solid #b8c7c8', borderBottomWidth: '0px'}}>
                        <span className="title-lastest" style={{fontSize: '17px', textTransform: 'uppercase', color: '#aaa', marginBottom: '10px', borderBottom: 'solid 1px #ccc', paddingBottom: '10px'}}>LES DERNIERS ARTICLES:</span>
                        {ItmesPosts}
                    </div>
                    <div id="page_facebook">
                        <div style={{border: '1px solid #b8c7c8'}} className="col-md-12 px-0">
                            <PageFacebook width={400}/>
                        </div>
                    </div>
                
                </>
            ); 
        }else{
            return ( 
                <>
                    <div className="col-md-12 px-2" style={{backgroundColor: '#fff', paddingTop: '10px', marginBottom: '15px', border: '1px solid #b8c7c8', borderBottomWidth: '0px'}}>
                        <span className="title-lastest" style={{fontSize: '17px', textTransform: 'uppercase', color: '#aaa', marginBottom: '10px', borderBottom: 'solid 1px #ccc', paddingBottom: '10px'}}>LES DERNIERS ARTICLES:</span>
                        <Item />
                        <Item />
                        <Item />
                    </div>
                    <div id="page_facebook">
                        <div style={{border: '1px solid #b8c7c8'}} className="col-md-12">
                            <pageFacebook />
                        </div>
                    </div>
                
                </>
            );
        }
        
    }
}

class Item extends Component
{
    render() {
        if(this.props.data){
            return ( 
                <div className="col-md-12 mx-0 px-0" style={{marginBottom: '10px', backgroundColor: '#fff'}}>
                    <a className="col-md-12 px-0 mx-0" href={"/"+i18next.language+this.props.data.url}>
                        <div style={{width: '100%', display: 'flex', backgroundColor: '#eeeeee99', borderBottom: 'solid 1px #ccc'}}>
                            <div style={{width: '30%'}}>
                                <figure style={{margin: 0}}>
                                    <h4 style={{fontSize: '11px', color: '#fff', marginBottom: '0px', width: 'calc(100% - 10px)', textAlign: 'center', marginTop: '8px', lineHeight: '12px', textTransform: 'uppercase', marginLeft: '5px', backgroundColor: '#ff0000'}}>{this.props.data.category}</h4>
                                    <img onContextMenu={(e)=> e.preventDefault()} className="zomm-image" src={this.props.data.thumbnail} style={{width: '100%', height: '50px', objectFit: 'cover', padding: '5px', paddingTop: '0px'}} />
                                </figure>
                            </div>
                            <div style={{width: '70%'}}>
                                <span style={{width: '100%', display: 'block', color: '#000', fontSize: '14px', lineHeight: '15px', fontWeight: 'bold', textAlign: 'justify', paddingTop: '5px'}}>{this.props.data.post_title}</span>
                            </div>
                        </div>
                    </a> 
                </div>
            );
        }else{
            return ( 
                <div className="col-md-12 mx-0 px-0" style={{marginBottom: '10px', backgroundColor: '#fff'}}>
                    
                </div>
            );
        }
        
    }
}



