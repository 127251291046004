import React,{ Component ,useEffect, useState } from 'react';
import Modal, {closeStyle} from 'simple-react-modal';
import i18next from 'i18next';
import Select from 'react-select'
import {prefixe} from '../../config.json'
import Enteprises from '../../services/enteprises';
import { useForm } from "react-hook-form";
import { withTranslation } from 'react-i18next';
import Devis from '../../services/devis'
const Devisexpress = (props) => {
   const [showModale, setshowModale] = useState(false);
   const [ blockdevi1, setblockdevi1] = useState({display:"block"});
   const [ blockdevi2, setblockdevi2] = useState({display:"none"});
   const [ stylecompany, setstylecompany] = useState({display:"none"});
   const [ styleactivite, setstyleactivite] = useState({display:"block"});
   const [devi_etes, setDevi_etes] = useState(1);
   const [categorie, setCategorie] = useState([]);
   const [subCategorie, setSubCategorie] = useState([]);
   const [listeSubCategorie, setListeSubCategorie] = useState([]);
   const [countrys, setCountrys] = useState([]);
   const [type_devis_express, settype_devis_express] = useState([]);
   const [delai_devis_express, setdelai_devis_express] = useState([]);
   const [rdv_devis_express, setrdv_devis_express] = useState([]);
   const [temps_appel_devis_express, settemps_appel_devis_express] = useState([]);
   const [objet_demande_devis_express, setobjet_demande_devis_express] = useState([]);
   const [showappele, setshowappele] = useState(false);
   const [showheureappele, setshowheureappele] = useState(false);
   const [submitForm, setsubmitForm] = useState(false);
   const [clicksubmitForm, setclicksubmitForm] = useState(false);
   const [searchionnersubCategorie, setSearchionnersubCategorie] = useState([]);
   const [selectionnersubCategorie, setSelectionnersubCategorie] = useState([]);
   const { t } = props;
   const { register, handleSubmit, reset  ,setValue, formState: { errors } } = useForm();
   useEffect(() => {
       return false;
   }, []);
   const onSubmit = (data) => 
   {
    setclicksubmitForm(true);
    Devis.registerDevieExpress(data).then((res)=>{
        if(res.data){
            reset(); 
            setclicksubmitForm(false);
            setsubmitForm(true);
        }
    });
   }
    const showDevisExpress = () => 
    {
        Enteprises.getCategories().then((res)=>{
            if(res.data.get){
                var categories = [];
                var subcategories = [];
                res.data.data.forEach(categorie => {
                    if(categorie.parent==0){
                        categories.push(categorie);
                    }else{
                        subcategories.push({ value: categorie.id, label: categorie.name , parent: categorie.parent });
                    }
                });
                console.log(res.data.data);
                setCategorie(categories);
                setSubCategorie(subcategories);
            }
        });
        Devis.getCountry().then((res)=>{
            if(res.data.get){
                setCountrys(res.data.data);
            }
        });
        Devis.getInfo().then((res)=>{
            if(res.data.get){
                settype_devis_express(res.data.type_devis_express);
                setdelai_devis_express(res.data.delai_devis_express);
                setrdv_devis_express(res.data.rdv_devis_express);
                settemps_appel_devis_express(res.data.temps_appel_devis_express);
                setobjet_demande_devis_express(res.data.objet_demande_devis_express);
            }
        });
        setsubmitForm(false);
        setclicksubmitForm(false);
        setblockdevi2({display:"none"});
        setblockdevi1({display:"block"});
        setshowModale(true)
    }
    const clickCategorie = (e) => 
    {
        var categories =[];
        var secteur_devis_express=null;
        var parent_secteur_devis_express=null;
        subCategorie.forEach(categorie => {
            if(e.target.id == categorie.value){
                categories.push(<span class="span_destinataire">{categorie.label}</span>);
                secteur_devis_express=categorie.value;
                parent_secteur_devis_express=categorie.parent;
            }
        });
        setValue("secteur", parent_secteur_devis_express);
        setValue("secteur_devis_express", secteur_devis_express);
        setSelectionnersubCategorie(categories);
        setSearchionnersubCategorie([]);
    }
    const changecategorie = (e) => 
    {
        
        var displayedContacts = subCategorie.filter((c) => {
            let searchValue = c.label.toLowerCase();
            return searchValue.indexOf(e.target.value) !== -1;
        })
        var Searchionnersub=[];
        displayedContacts.forEach(element => {
            Searchionnersub.push(<li onClick={clickCategorie} className="searched_companies li_not_clicked" id={element.value} name={element.label}>{element.label}</li>);
        });
        
        setSearchionnersubCategorie(Searchionnersub);
        // var categories=[];
        // subCategorie.forEach(categorie => {
        //     if(categorie.parent==e.target.value){
        //         categories.push(categorie);
        //     }
        // });
        // setListeSubCategorie(categories);
    }
    return (<>
        <div className="btn-add-devis-express" id="btn-add-devis-express" onClick={showDevisExpress}>
            <div> 
                <img onContextMenu={(e)=> e.preventDefault()} src="/upload/image/devis-express.png " /> 
                <span>DEVIS EXPRESS</span>
            </div>
        </div>
        <div className="modal-login-hadeer modal-login-myb2b">
            <Modal
               closeOnOuterClick={true}
                show={showModale}
                onClose={()=>setshowModale(false)}
                >
                <div classNameName="div-loginn">
                    <div className="connxtion_header">
                        <h2 id="to_text_pupup--" className="size-titre-b2b grie-b2b">{t('DEVIS EXPRESS')}:</h2>
                        <div className="divbtnclose">
                            <span className="close closemodalheader" onClick={()=>setshowModale(false)}>×</span>
                        </div>
                    </div>
                    <div className="connxtion_body">
                        {submitForm?<>
                            <div style={{textAlign: 'center', paddingTop: '7em'}}>
                                <h2>Votre Devis Express a été envoyer avec succès</h2>
                                <i className="fa fa-check" style={{fontSize:"90px",color:"green"}}/>
                            </div> 
                        </>:<>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row m-0" id="content_row_body_devis_express">
                                <div className="col-md-12 m-0 block-devi-express-l" style={blockdevi1}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <img onContextMenu={(e)=> e.preventDefault()} className="text-image" src="/upload/image/devisblock2.png" style={{height: "50px"}} />
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <div className="form-group">
                                                <label htmlFor="shortDescription3" className="label-divs"> Quel type de solutions recherchez-vous ? <span className="star">*</span> :</label>
                                                <div className="row">
                                                    {type_devis_express.map((item)=>{
                                                        return (
                                                            <fieldset className="radio" style={{padding: '0 15px'}}> 
                                                                <input style={{marginRight: '10px'}} type="radio" className="type_devis_express" {...register("type_devis_express", { required:true })} id={"type_devis_express"+item.id} value={item.id} /> 
                                                                <label for={"type_devis_express"+item.id}>{item.nom}</label>
                                                            </fieldset>
                                                        )
                                                    })}
                                                </div>
                                                {errors.type_devis_express?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                            </div>
                                        </div>
                                        <div className="col-md-12"> 
                                            <label className="label-bold">Nom du Produit recherché * :</label> 
                                            <input type="text" placeholder="Nom Produit" {...register("nom_produit", { required:true })} className="form-control" style={{lineHeight: 1}} />
                                            {errors.nom_produit?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                        </div>
                                        {/* <div className="col-md-12">
                                            <label className="label-bold">Secteur d'activité * :</label> 
                                            <select {...register("secteur", { required:true,onChange:changecategorie })} className="form-control bs-select-hidden">
                                                {categorie.map((item)=>{
                                                    return (
                                                        <option value={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </select>
                                            {errors.secteur?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                        </div> */}
                                        <div className="col-md-12">
                                            <label className="label-bold">Secteur d'activité * :</label>
                                            <div id="destinataires_container" align="center">
                                                {selectionnersubCategorie}
                                            </div>
                                            <input placeholder="Veuillez choisir vos Secteur d'activité" id="inputsearchCategorie" onChange={changecategorie} className="form-control input" type="text" autoComplete="off" />
                                            <input type='hidden' {...register("secteur_devis_express")} />
                                            <input type='hidden' {...register("secteur")} />
                                            <label id="destinataire-error" className="error">Catégorie (min 1 catégories) ! </label>
                                            {errors.secteur_devis_express?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                            <div id="responsecontainer" style={{maxHeight: '150px', overflow: 'auto'}}>
                                                {searchionnersubCategorie}
                                            </div>
                                            {/* {this.state.error_categories?
                                            <label className="error text-transform-unset" id="verifier_existence_error" style={{display:"block"}}>
                                                Le champ « Catégories » est obligatoire 
                                            </label>:<></>} */}
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <div className="form-group">
                                                <label className="label-divs"> Dans quel délai envisagez-vous votre commande ? <span className="star">*</span> :</label>
                                                <div className="row">
                                                    {delai_devis_express.map((item)=>{
                                                        return (
                                                            <fieldset className="radio" style={{padding: '0 15px'}}> 
                                                                <input style={{marginRight: '10px'}} type="radio" className="type_devis_express" {...register("delai_devis_express", { required:true })} id={"delai_devis_express"+item.id} value={item.id} /> 
                                                                <label for={"delai_devis_express"+item.id}>{item.nom}</label>
                                                            </fieldset>
                                                        )
                                                    })}
                                                </div>
                                                {errors.delai_devis_express?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <div className="form-group">
                                                <label htmlFor="shortDescription3" className="label-divs"> Afin de permettre à nos partenaires d'étudier au mieux vos besoins, vous engagez-vous à leur accorder  <span className="star">*</span> :</label>
                                                <div className="row">
                                                
                                                    {rdv_devis_express.map((item)=>{
                                                        return (
                                                            <fieldset className="radio" style={{padding: '0 15px'}}> 
                                                                <input style={{marginRight: '10px'}} type="radio" className="type_devis_express" {...register("rdv_devis_express",{ required:true , onChange:(e)=>{if(e.target.value==1){setshowappele(true);}else{setshowappele(false);setshowheureappele(false);}} })} id={"rdv_devis_express"+item.id} value={item.id} /> 
                                                                <label for={"rdv_devis_express"+item.id}>{item.nom}</label>
                                                            </fieldset>
                                                        )
                                                    })}
                                                </div>
                                                {errors.rdv_devis_express?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                            </div>
                                        </div>
                                        <div className="col-md-6" style={{display:showappele?"block":"none"}}>
                                            <label className="label-bold">Je veux être appelé :</label> 
                                            <select {...register("temps_appel_devis_express", { required:showappele , onChange:(e)=>{if(e.target.value==4){setshowheureappele(true);}else{setshowheureappele(false);}} })} className="form-control bs-select-hidden">
                                                {/* <option value=""></option> */}
                                                {temps_appel_devis_express.map((item)=>{
                                                    return (
                                                        <option value={item.id}>{item.nom}</option>
                                                    )
                                                })}
                                            </select>
                                            {errors.temps_appel_devis_express?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                        </div>
                                        <div className="col-md-6" style={{display:showheureappele?"block":"none"}}> 
                                            <label className="label-bold">Heure d'appel :</label> 
                                            <input type="text" placeholder="hh:mm"  {...register("heure_tel_devis_express", { required:showheureappele })} className="form-control" style={{lineHeight: 1}} />
                                            {errors.heure_tel_devis_express?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                        </div>
                                        <div className="col-md-12">
                                            <label className="label-bold">Objet de la demande * :</label> 
                                            <select {...register("objet_demande_devis_express", { required:true })} className="form-control bs-select-hidden">
                                                {/* <option value=""></option> */}
                                                {objet_demande_devis_express.map((item)=>{
                                                    return (
                                                        <option value={item.id}>{item.nom}</option>
                                                    )
                                                })}
                                            </select>
                                            {errors.objet_demande_devis_express?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="devi_etes" className="label-bold">Veuillez détailler votre demande dans le formulaire * :</label> 
                                            <textarea type="text" {...register("description", { required:true })} className="form-control" style={{lineHeight: 1}} />
                                            {errors.description?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                        </div>
                                        <div className="col-md-12 form-group"> 
                                            <button type="button" className="btn btn-b2b" onClick={()=>{setblockdevi1({display:"none"});setblockdevi2({display:"block"})}} style={{float: 'right', width: 'auto', lineHeight: '0px', padding: '0px 40px !important', height: '50px'}}>Suivant</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 m-0 block-devi-express-l" style={blockdevi2}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <img onContextMenu={(e)=> e.preventDefault()} className="text-image" src="/upload/image/devisblock1.png" style={{height: "50px"}} />
                                        </div>
                                        <div className="col-md-6">
                                            <label for="devi_etes" className="label-bold">Vous êtes* :</label> 
                                            <select {...register("etes", { required:true,onChange:(e)=>{setDevi_etes(e.target.value);if(e.target.value==1){setstylecompany({display:"none"});setstyleactivite({display:"block"})}else{setstylecompany({display:"block"});setstyleactivite({display:"none"})}} })} id="devi_etes" className="form-control bs-select-hidden">
                                                <option value={1} selected>Un particulier</option>
                                                <option value={2}>Une entreprise</option>
                                            </select>
                                            {errors.etes?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                        </div>
                                        <div className="col-md-6">
                                            <div style={styleactivite}> 
                                                <label className="label-bold">Activité* :</label> 
                                                <input type="text" placeholder="Votre activité" {...register("activite", { required:devi_etes==1?true:false })} className="form-control" style={{lineHeight: 1}} />
                                                {errors.activite?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                            </div>
                                            <div style={stylecompany}> 
                                                <label htmlFor="devi_nom_societe" className="label-bold">Nom de la société* :</label> 
                                                <input type="text" placeholder="Le nom de votre entreprise" {...register("nom_societe", { required:devi_etes==2?true:false })} className="form-control" style={{lineHeight: 1}} />
                                                {errors.nom_societe?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                            </div>
                                        </div>
                                        <div className="col-md-6"> 
                                            <label className="label-bold">Nom* :</label> 
                                            <input type="text" placeholder="Votre Nom"  {...register("nom", { required:true })} className="form-control" style={{lineHeight: 1}} />
                                            {errors.nom?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                        </div>
                                        <div className="col-md-6"> 
                                            <label className="label-bold">Prénom* :</label> 
                                            <input type="text" placeholder="Votre Prénom"  {...register("prenom", { required:true })} className="form-control" style={{lineHeight: 1}} />
                                            {errors.prenom?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                        </div>
                                        <div className="col-md-12"> 
                                            <label className="label-bold">Email* :</label> 
                                            <input type="text" placeholder="Votre Email"  {...register("email", { required:true })} className="form-control" style={{lineHeight: 1}} />
                                            {errors.email?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                        </div>
                                        <div className="col-md-12"> 
                                            <label className="label-bold">Téléphone* :</label> 
                                            <input type="text" placeholder="Votre Téléphone" {...register("telephone", { required:true })} className="form-control" style={{lineHeight: 1}} />
                                            {errors.telephone?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="label-bold">Pays* :</label> 
                                            <select {...register("pays", { required:false })} className="form-control bs-select-hidden">
                                                {countrys.map((item)=>{
                                                    let selected="";
                                                    if (parseInt(prefixe)==parseInt(item.code)){
                                                        selected="selected"
                                                    }
                                                    return (<option value={item.name} selected={selected}>{item.name}</option>)
                                                })}
                                            </select>
                                            {errors.pays?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                        </div>
                                        <div className="col-md-6"> 
                                            <label className="label-bold">Ville* :</label> 
                                            <input type="text" placeholder="Votre Ville" {...register("ville", { required:true })} className="form-control" style={{lineHeight: 1}} />
                                            {errors.ville?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                        </div>
                                        <div className="col-md-8"> 
                                            <label className="label-bold">Adresse* :</label> 
                                            <input type="text" placeholder="Votre Adresse" {...register("adresse", { required:true })} className="form-control" style={{lineHeight: 1}} />
                                            {errors.adresse?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                        </div>
                                        <div className="col-md-4"> 
                                            <label className="label-bold">Code postal* :</label> 
                                            <input type="text" placeholder="Votre Code postal" {...register("code_postal", { required:true })} className="form-control" style={{lineHeight: 1}} />
                                            {errors.code_postal?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                        </div>
                                        <div className="col-md-12 mt-2"> 
                                            <input type="submit" name="envoyer_devis_express" defaultValue="Envoyer" className="btn btn-b2b" style={{float: 'right', width: 'auto', lineHeight: '0px', padding: '0px 40px !important', height: '50px'}} /> 
                                            <button type="button" disabled={clicksubmitForm?"disabled":""} className="btn btn-b2b"onClick={()=>{setblockdevi1({display:"block"});setblockdevi2({display:"none"})}} style={{float: 'left', width: 'auto', lineHeight: '0px', padding: '0px 40px !important', height: '50px'}}>précédent</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        </>}
                    </div>
                </div> 
            </Modal>
        </div>
    </>
        
    );
}
export default withTranslation()(Devisexpress);

