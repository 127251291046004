import React, { Component } from 'react';
import myb2b from '../../services/myb2b';
import jwt_decode from "jwt-decode";
import {b2bNameSite} from '../../config.json';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { Markup } from 'react-render-markup';
class Maselectionentreprises extends Component {
    constructor (props) {
        super(props)
        var connected=false;
        if(localStorage.getItem('tokenb2b')&& localStorage.getItem('tokenb2b')!=""){
            var dataUser=jwt_decode(localStorage.getItem('tokenb2b'));
            if(dataUser && dataUser.code_user>0){
                connected=true;
            }
        }
        this.state = {
            items:[],
            connected:connected
        }
        if(connected){
           
            myb2b.getNbFolloweds().then((res)=>{
                if(res.data.get){
                   
                    var items=[];
                    res.data.followeds.forEach(followed => {
                        items.push(<Item url={followed.url} img={followed.image} title={followed.title} />)
                    });
                    this.setState({
                        items:items
                    });
                }
            });
        }
        
       
    }
    render() {
        return (
            <div className="widget">
                <h4 className="widget-title">MA SELECTION D'ENTREPRISES : </h4>
                <ul className="naves" style={{overflow: 'auto',maxHeight: "308px"}} >
                    {this.state.items.length>0?<>{this.state.items}</>:
                    <>Vous devez suivre une ou plusieurs entreprises sur « {b2bNameSite} », pour pourvoir vous afficher la liste des entreprises qui vous intéressent.</>}  									
                </ul>
            </div>
        );
    }
}

export default Maselectionentreprises;


class Item extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            url : props.url,
            img : props.img,
            title : props.title
        }
    }
    render() {
    
    return ( 
        <li className="d-flex align-items-center mb-1">
            <img onContextMenu={(e)=> e.preventDefault()} src={this.state.img} style={{width: '40px', height: '40px', borderRadius: '50%', border: '1px solid',marginRight: "5px"}} />
            <Link to={"/"+i18next.language+this.state.url} className="overflow-text-hidden" title={this.state.title}><Markup markup={this.state.title} /></Link>
        </li>
        );
    }
}
