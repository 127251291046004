import React, { Component , useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Myb2b from '../../services/myb2b';
import { Markup } from 'react-render-markup';
import i18next from 'i18next';
import jwt_decode from "jwt-decode";
const Filactualite = () => {
    const [items,setItems] = useState([]);
    const [chargeritems,setChargeritems] = useState(false);
    const [paged,setPaged] = useState(1);
    useEffect(() => {
        Myb2b.getPostsConnected().then((res)=>{
            if(res.data.get){
                var newitems=[];
                res.data.data.forEach(newitem => {
                    newitems.push(<Item data={newitem} />);
                });
                setItems(newitems);
                setTimeout(() => {
                    setChargeritems(true) 
                }, 1000);
                setPaged(paged+1);
            }
        });
    }, []);
    const item_fin = document.getElementById("item_fin");
    if(item_fin && window.scrollY>item_fin.offsetTop-1000 && chargeritems){
        console.log("---------chargeritems----------"); 
        setChargeritems(false) 
        Myb2b.getPostsConnected(paged).then((res)=>{
            if(res.data.get){
                var newitems=items;
                setItems(items);
                res.data.data.forEach(newitem => {
                    newitems.push(<Item data={newitem} />);
                });
                setItems(newitems);
                setTimeout(() => {
                    setChargeritems(true) 
                }, 1000);
                setPaged(paged+1);
            }
        });
    }
    
    return (<>
        <div className="loadMore" id="my-posts">
        {items}
        </div>
        <div id="item_fin"></div>
        <div className="central-meta item" >
            <div className="user-post">
                <div className="friend-info">
                    <figure>
                    </figure>
                    <div className="friend-name">
                    <span>
                        Aucune publication
                    </span>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Filactualite;



const Item = (props) => {

    const [likes, setLikes] = useState(0);
    const [likesclicked, setLikesClicked] = useState(false);
    const [shares, setShares] = useState(1);
    const [sharesclicked, setsharesClicked] = useState(false);
    const [comments, setComments] = useState(0);
    const [comment, setComment] = useState("");
    const [listeComments, setListeComments] = useState([]);
    
    useEffect(() => {
        init();
    }, []);
    function init(){
        setListeComments([]);
        Myb2b.reactPost(props.data.id).then((res)=>{
            if(res.data.get){
                setLikes(res.data.likes);
                setLikesClicked(res.data.liked);
                setShares(res.data.shares);
                setsharesClicked(res.data.shared);
                var comments=[];
                res.data.comments.forEach(comment => {
                    comments.push(<ItemComment id={comment.id} user={comment.user} image={comment.image} name={comment.title} url={comment.url} comment={comment.comment} date={comment.date} init={init}/>);
                });
                setComments(comments.length)
                setListeComments(comments);
            }
        });
    }
    function onClickLike(){
        Myb2b.likePost(props.data.id).then((res)=>{
            init();
        });
    }
    function onClickShare(){
        if(!sharesclicked){
            Myb2b.sharePost(props.data.id).then((res)=>{
                init();
            });
        }
    }
    function onClickComment(){
        Myb2b.commentPost(props.data.id,comment).then((res)=>{
            init();
            setComment("");
        });
    }
    function changeComment(e) {
        setComment(e.target.value);
    }
    return (<>
        <div className="central-meta item">
            <div className="user-post">
                <div className="friend-info">
                    {props.data.type!="Article"?
                    <figure>
                        <img onContextMenu={(e)=> e.preventDefault()} src={props.data.img_company} alt="" />
                    </figure>
                    :<></>}
                    <div className="friend-name">
                        <ins>
                            <Link to={"/"+i18next.language+props.data.url}><Markup markup={props.data.title} />	</Link>	
                            <span className="label label-info produit">{props.data.type}</span>
                        </ins>
                        <span>
                        {props.data.date}	
                        </span>
                    </div>
                    <div className="post-meta">
                        <Link to={"/"+i18next.language+props.data.url}>
                        <img onContextMenu={(e)=> e.preventDefault()} src={props.data.img} alt={props.data.title} className="w-100" />
                        </Link>	
                        <div className="we-video-info">
                            <ul>
                                <li id="like_button" style={{cursor:"pointer"}} onClick={onClickLike}>
                                    <span className="like" data-toggle="tooltip" title data-original-title="like">
                                        <i className={"fa fa-heart"+(likesclicked?"":"-o")} />													
                                        <ins>{likes}</ins>
                                    </span>
                                </li>
                                <li>
                                    <span className="comment" data-toggle="tooltip" title data-original-title="Comments">
                                        <i className="fa fa-comments-o" />
                                        <ins>{comments}</ins>
                                    </span>
                                </li>
                                <li id="share_button" style={{cursor:"pointer",color:(sharesclicked?"red":"")}} onClick={onClickShare}>
                                    <span className="views" data-toggle="tooltip" title data-original-title="Partage">
                                        <i className="fa fa-share-alt" />														
                                        <ins>{shares}</ins>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="description">
                            <div className="coment-area">
                                <ul className="we-comet" style={{maxHeight: '224px', overflow: 'auto'}}>
                                    {listeComments}
                                </ul>
                                <div className="post-comt-box">
                                    <input type="text" value={comment} placeholder="Commenter" onChange={changeComment} style={{paddingLeft: '15px', background: '#f3f3f3 none repeat scroll 0 0', borderColor: 'transparent', borderRadius: '20px', color: '#000000', fontSize: '13.5px', fontWeight: 500, height: '40px', lineHeight: '16px', width: '94%'}} />
                                    <i id="send_comment" className="fa fa-send" onClick={onClickComment} style={{cursor:"pointer"}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

const ItemComment = (props) => {

    const [image, setImage] = useState(props.image);
    const [name, setName] = useState(props.name);
    const [url, setUrl] = useState(props.url);
    const [comment, setComment] = useState(props.comment);
    const [datecomment, setDateComments] = useState(props.date);
    const [id, setID] = useState(props.id);
    const [user, setUser] = useState(props.user);
    const [dateUser, setDataUser] = useState(localStorage.getItem('tokenb2b')!=""?jwt_decode(localStorage.getItem('tokenb2b')):null);
    function deliteComment() { 
        Myb2b.removeCommentPost(id).then((res)=>{
            props.init();
        });
    }
    return (<>
    <li>
        <div className="comet-avatar">
            <img onContextMenu={(e)=> e.preventDefault()} src={image} alt={name}/>
        </div>
        <div className="we-comment">
            {(dateUser && dateUser.code_user==user)? <div className='deliteComment' onClick={deliteComment}>x</div> :<></>}
            <div className="coment-head">
                <h5><Link to={url} title={name}>{name}</Link></h5>
                <span>{datecomment}</span>
            </div>
            <p>{comment}</p>
        </div>
    </li>
    </>);
}

