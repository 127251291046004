
import './detail.css';
import React, { Component } from 'react';
import Evenements from '../../services/evenements';
import { Link } from 'react-router-dom';
import { Markup } from 'react-render-markup';
import Select from 'react-select'
import { useParams } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Pagination from "react-js-pagination";
import BtnPartage from '../Slider/btnPartage';
import i18next from 'i18next';
import ReactGA from 'react-ga';
import { withTranslation } from 'react-i18next';
function Detail(props){ 
    let { slug } = useParams();
    return ( 
        <Index slug={slug} t={props.t}/>
    );
}
export default withTranslation()(Detail)
class Index extends Component
{
    constructor (props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        if(document.getElementById("header_b2b"))document.getElementById("header_b2b").style.display="block";

        super(props)
        this.state = {
            slug : this.props.slug,
            data : null
        }
        Evenements.getDetail(this.props.slug).then((res)=>{
            if(res.data.get){
               this.setState({data: res.data.data}) ;
               console.log(res.data.data);
            }
        });
    }
    
    render() {
        const {t} = this.props
        if(this.state.data){ 
            return ( 
                <section id="list-places-wrapper" className="container" style={{marginTop: 0, paddingTop: '20px', backgroundColor: 'white'}}>
                    <div className="container mec-wrap clearfix col-md-12" id="mec_skin_7478">
                        <article className="mec-single-event row">
                            <div className="col-md-8">
                                <div className="mec-events-event-image"></div>
                                <div className="mec-event-content">
                                    <h1 className="mec-single-title">{this.state.data.post_title}</h1>
                                    <div id="block-mobile-div" />
                                    <div className="mec-single-event-description mec-events-content" style={{textAlign: 'justify'}}>
                                        <Markup markup={this.state.data.post_content} />
                                    </div>
                                </div>
                                <br />
                                <BlockEvenes />
                                <div className="mec-events-meta-group mec-evenpost_titlets-meta-group-tags"> </div>
                            </div>
                            <div className="col-md-4">
                                <div id="block-mobile">
                                    <div className="mec-event-meta mec-color-before mec-frontbox">
                                        <img onContextMenu={(e)=> e.preventDefault()} src={this.state.data.img} style={{width: '100%'}} />
                                    </div>
                                    <br />
                                    <h3 className="mec-social-single mec-frontbox-title"></h3>
                                    <h3 className="mec-social-single mec-frontbox-title"> 
                                        <i className="mec-sl-calendar"></i>
                                    </h3>
                                    <h3 className="mec-date"></h3>
                                    <h3>
                                        <abbr className="mec-events-abbr">
                                            {t('Du')} {this.state.data.start_date}	
                                            <br/>
                                            {t('Au')} {this.state.data.end_date}	
                                        </abbr>
                                    </h3>
                                    <div>

                                        {this.state.data.type=="encours"?<>
                                        <button type="button" className="btn-evenement">{t('Événements en cours')}</button>
                                        </>:this.state.data.type=="venir"?<>
                                        <button type="button" className="btn-evenement">{t('Événements à venir')}</button>
                                        </>:<>
                                        <button type="button" className="btn-evenement">{t('Événements passés')}</button>
                                        </>}
                                        
                                    </div>
                                    <br />
                                </div>
                                <div className="mec-event-social mec-frontbox">
                                    <div className="mec-event-sharing">
                                        <div className="mec-links-details">
                                            <p className="pb-0 mb-0">
                                                <span style={{fontWeight: 'bold', color: '#5d5f5e'}}>{t("PARTAGER SUR")} : </span>
                                            </p>
                                            <BtnPartage 
                                                image={this.state.data.img} 
                                                title={this.state.data.post_title} 
                                                content=""
                                                url={this.state.data.urlPartage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </section>
            );
        }else{
            return (
            <section id="list-places-wrapper" className="container" style={{marginTop: 0, paddingTop: '20px', backgroundColor: 'white'}}>
                <div className="container mec-wrap clearfix col-md-12" id="mec_skin_7478">
                    <article className="mec-single-event row">
                        <div className="col-md-8">
                            <div className="mec-events-event-image"></div>
                            <div className="mec-event-content">
                                <h1 className="mec-single-title loder"></h1>
                                <div id="block-mobile-div" />
                                <div className="mec-single-event-description mec-events-content" style={{textAlign: 'justify'}}>
                                    <p className="event-description-loder"></p>
                                    <p className="event-description-loder"></p>
                                    <p className="event-description-loder"></p>
                                    <p className="event-description-loder"></p>
                                    <p className="event-description-loder"></p>
                                    <p className="event-description-loder"></p>
                                    <p className="event-description-loder"></p>
                                    <p className="event-description-loder"></p>
                                    <p className="event-description-loder"></p>
                                    <p className="event-description-loder"></p>
                                    <p className="event-description-loder"></p>
                                    <p className="event-description-loder"></p>
                                    <p className="event-description-loder"></p>
                                </div>
                            </div>
                            <br />
                            
                            <BlockEvenes />
                            
                        </div>
                        <div className="col-md-4">
                            <div id="block-mobile">
                                <div className="mec-event-meta mec-color-before mec-frontbox image-loder">
                                </div>
                                <br />
                                <h3 className="mec-social-single mec-frontbox-title"></h3>
                                <h3 className="mec-social-single mec-frontbox-title"> 
                                    <i className="mec-sl-calendar"></i>
                                </h3>
                                <h3 className="mec-date"></h3>
                                <h3>
                                    <abbr className="mec-events-abbr">
                                        <div className="span-loder">{t('Du')}  <span> </span> 	</div>
                                        <div className="span-loder">{t('Au')}  <span> </span> 	</div>
                                    </abbr>
                                </h3>
                                
                                <br />
                            </div>
                            <div className="mec-event-social mec-frontbox">
                                <h3 className="mec-social-single mec-frontbox-title">{t('Partagez cet événement')} :</h3>
                                <div className="mec-event-sharing partagez-loder">
                                    
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        );
        }

    }
}

class BlockEvenes extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            items : [<ItemEvene />,<ItemEvene />,<ItemEvene />,<ItemEvene />,<ItemEvene />,<ItemEvene />]
        }
        setTimeout(() => {
            Evenements.getEvenementsVenir(1,0,"").then((res)=>{
                if(res.data.get){
                    var items = [];
                    res.data.data.forEach(data => {
                        items.push(<ItemEvene data={data} />);
                    }); 
                    this.setState({items:items});
                }
            });
        }, 2000);
       
    }
    render() {
        const {t} = this.props;
        if(this.props.data && this.state.items.length>0){
            return (<>
                <h3 style={{textTransform: 'uppercase'}}>{t('Événements à venir')} :</h3>
                <div className="list-single-main-item"> 
                    <div className=" product-list no-padding" width="100%">
                        <AliceCarousel 
                            mouseTracking 
                            autoPlay={true}
                            items={this.state.items}
                            infinite
                            renderDotsItem={false}
                            autoPlayInterval = {2000}
                            responsive={{1200: {items: 3},1000: {items: 2},800: {items: 2},300: {items: 1}}} />
                    </div>
                </div>
            </>);
        }else{
            return (<></>); 
        }
    }
}
class ItemEvene extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            
        }
    }

    render() {

        if(this.props.data ){ //
            return (
                <div className="col-md-12 px-1">
                    <Link to={"/"+i18next.language+this.props.data.url}>
                        <div className="col-md-12 px-0" style={{height: '170px'}}>
                            <img onContextMenu={(e)=> e.preventDefault()} src={this.props.data.img} alt={this.props.data.title} style={{height: '100%', width: '100%', objectFit: 'cover'}} />
                            <h4 style={{position: 'absolute', top: 0, left: '0px', color: '#fff', background: '#ed2829', fontSize: '12px', padding: '1px 5px'}}>{this.props.data.category}</h4>
                        </div>
                        <div className="col-md-12 px-1" style={{fontFamily: 'calibri', textAlign: 'justify'}}> 
                            <h4 style={{color: '#000', textTransform: 'capitalize', fontSize: 12, lineHeight: '12px', marginTop: '12px'}}>{this.props.data.title}</h4>
                        </div>
                    </Link>
                </div>
            );
        }else{
            return (
                <div className="partenaires-item item">
                    <figure>
                        <div width="200" height="200" className="loder-image"/>
                    </figure>
                    <h6><span className="loder-title"></span></h6>
                </div>
            );
        }
    }
}
