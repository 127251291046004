import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import {InlineShareButtons} from 'sharethis-reactjs';
import './styleBtnShare.css';
const BtnPartage = (props)=>{  
    useEffect(() => {
        async function changeMeta() {
            // const title = await document.querySelector('title');
            // title.innerText = `${title.text} | ${props.title}`;
            // const description = await document.querySelector("meta[name='description']");
            // await description.setAttribute('content',props.content)
            // const image = await document.querySelector("meta[property='og:image']");
            // await image.setAttribute('content',props.image);
        }
        changeMeta();
    }, []);
    return(
        <>
            
            <div className="btnsSShare" style={props.style}>
                <InlineShareButtons
                    config={{
                        alignment: 'center',  // alignment of buttons (left, center, right)
                        color: 'social',      // set the color of buttons (social, white)
                        enabled: true,        // show/hide buttons (true, false)
                        font_size: 16,        // font size for the buttons
                        labels: 'cta',        // button labels (cta, counts, null)
                        language: 'en',       // which language to use (see LANGUAGES)
                        networks: [ 
                        'linkedin',
                        'twitter',
                        'facebook',
                        'messenger'
                        ],
                        padding: 0,          // padding within buttons (INTEGER)
                        radius: 100,            // the corner radius on each button (INTEGER)
                        show_total: false,
                        size: 40,             // the size of each button (INTEGER)
            
                        //OPTIONAL PARAMETERS
                        url: props.url, 
                        image: props.image,
                        description: props.content.replace(/<[^>]*>?/gm, ''),     
                        title: props.title,            
                        message: 'contact@chnitisoftpro.net',    
                        subject: 'contact@chnitisoftpro.net',  
                        username: 'chnitisoftpro' 
                    }}
                />
            </div>
        </>
       
    );
}

export default BtnPartage;