import http from "./httpService";
import { apiUrl } from  "../config.json";

const getFormations = (paged=1,mot_cle=null) => {
    const apiEndoint =  apiUrl+"/formations/getFormations.php";
    const formData = new FormData();
    formData.append("mot_cle", mot_cle);
    formData.append("paged", paged);
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}
const getDetail = (slug) => {
    const apiEndoint =  apiUrl+"/formations/getDetail.php";
    const formData = new FormData();
    formData.append("slug", slug);
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}


const postuler = (offre,message,object,company) => {
    const apiEndoint =  apiUrl+"/formations/postuler.php";
    const formData = new FormData();
    formData.append("offre", offre);
    formData.append("message", message);
    formData.append("object", object);
    formData.append("company", company);
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}
export default 
{
    getFormations,
    getDetail,
    postuler
}