import React, { Component , useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DataHome from '../../services/home';
import { Markup } from 'react-render-markup';
import i18next from 'i18next';
const Evenementsavenir = () => {
    const [items,setItems] = useState([]);
    useEffect(() => {
        DataHome.getEvennements().then((res)=>{
            if(res.data.get){
                var newitems=[];
                res.data.data.forEach(evennement => {
                    newitems.push(<Item url={evennement.url} img={evennement.img} title={evennement.title}/>);
                });
                setItems(newitems);
            }
        });
    }, []);

    return (
        <div className="widget stick-widget">
            <h4 className="widget-title">ÉVÉNEMENTS A VENIR :</h4>
            <ul className="followers" style={{overflow: 'auto',maxHeight: "308px"}}>
            {items}								
            </ul>
        </div>
    );
}

export default Evenementsavenir;

class Item extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            url : props.url,
            img : props.img,
            title : props.title
        }
    }
    render() {
    
    return ( 
        <li className="d-flex align-items-center mb-1">
            <img onContextMenu={(e)=> e.preventDefault()} src={this.state.img} style={{width: '40px', height: '40px', borderRadius: '50%', border: '1px solid',marginRight: "5px"}} />
            <Link to={"/"+i18next.language+this.state.url} className="overflow-text-hidden" title={this.state.title}><Markup markup={this.state.title} /></Link>
        </li>
        );
    }
}