
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import {b2bNameSite,urlFacebook,urlLinkedin,urlYoutube} from '../../config.json';
import { withTranslation } from 'react-i18next';
import './style.css';
class Footer extends Component
{
  
    render() {
        const { t } = this.props;
        return ( 
        
            <footer className="site-footer" style={{marginTop: "-20px"}}>
                <div className="div-site-footer">
                    <div className="footer-top">
                        <div className="container">
                            <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 footer-col-4">
                                <div className="widget widget_getintuch">
                                    <h4 className="m-b15 text-uppercase">« {b2bNameSite}  »</h4>
                                    <div className="dlab-separator-outer m-b10">
                                        <div className="dlab-separator bg-white style-skew"></div>
                                    </div>
                                    <ul>
                                        <li><i className="fa fa-map-marker icone-footer"></i><strong> {t('Adresse')}:</strong> {t('25 Avenue Louis Braille, 1002 Cité les Jardins, Tunis')}</li>
                                        <li><i className="fa fa-mobile icone-footer"></i><strong> {t('Service Clients')} :</strong>+216 71 903 156</li>
                                        <li style={{paddingTop: "10px"}}>
                                        <i className="fa fa-user icone-footer"></i><strong> {t('Suivez-nous sur')} :</strong>
                                        <ul className="dlab-social-icon dez-border mt-3">
                                            <li><a target="_blank" href={urlFacebook}><i className="fa fa-facebook"></i></a></li>
                                            <li><a target="_blank" href={urlLinkedin}><i className="fa fa-linkedin"></i></a></li>
                                            <li><a target="_blank" href={urlYoutube}><i className="fa fa-youtube"></i></a></li>
                                        </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 footer-col-4">
                                <div className="widget widget_services mb-2">
                                    <h4 className="m-b15 text-uppercase"> {t('À Propos')}</h4>
                                    <div className="dlab-separator-outer m-b10">
                                        <div className="dlab-separator bg-white style-skew"></div>
                                    </div>
                                    <ul>
                                        <li><Link to={"/"+i18next.language}> {t('Plan du Site')}</Link></li>
                                        <li><Link to={"/"+i18next.language}> {t('FAQ')}</Link></li>
                                        <li><Link to={"/"+i18next.language+"/contactez-nous"}> {t('Nous Contacter')}</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 footer-col-4">
                                <div className="widget widget_services">
                                    <h4 className="m-b15 text-uppercase"> {t('Les Services de')} « {b2bNameSite}  »</h4>
                                    <div className="dlab-separator-outer m-b10">
                                        <div className="dlab-separator bg-white style-skew"></div>
                                    </div>
                                    <ul>
                                        <li><Link to={"/"+i18next.language+"/entreprises"}> {t('Guide des Entreprises')}</Link></li>
                                        <li><Link to={"/"+i18next.language+"/produits-et-services"}> {t('Guide des Produits')}</Link></li>
                                        <li><Link to={"/"+i18next.language+"/abonnement-b2b"}> {t('Abonnement et Publicité')}</Link></li>
                                        <li><a href={"/sitemap"}> {t('Référencement sur')} « {b2bNameSite} »</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 footer-col-4">
                                <div className="widget widget_services">
                                    <h4 className="m-b15 text-uppercase"><i className="icone-map-b2b"></i> {t("NOS PLATEFORMES «B2B» AL'INTERNATIONAL")}:</h4>
                                    <div className="dlab-separator-outer m-b10">
                                        <div className="dlab-separator bg-white style-skew"></div>
                                    </div>
                                    <div className="row platformes-b2b">
                                        {/* <ul className="col-md-6  col-sm-6">
                                            <li><a target="_blank" href="https://b2b-france.net/"><i className="icon-b2b-France"></i>B2B France</a></li>
                                            <li><a target="_blank" href="https://b2b-canada.net/" ><i className="icon-b2b-Canada"></i>B2B Canada</a></li>
                                            <li><a target="_blank" href="https://b2b-africa.net/"><i className="icon-b2b-Africa"></i>B2B Africa</a></li>
                                            <li><a target="_blank" href="https://b2b-algeria.net/"><i className="icon-b2b-Alegeria"></i>B2B Algeria</a></li>
                                            <li><a target="_blank" href="https://b2b-libya.net/"><i className="icon-b2b-Libye"></i>B2B Libye</a></li>
                                            <li><a target="_blank" href="https://b2b-morocco.net/"><i className="icon-b2b-Morocco"></i>B2B Morocco</a></li>
                                            <li><a target="_blank" href="https://b2b-congo.net/"><i className="icon-b2b-Congo"></i>B2B Congo</a></li>
                                            
                                        </ul>
                                        <ul className="col-md-6  col-sm-6">
                                            <li><a target="_blank" href="https://b2b-rwanda.net/"><i className="icon-b2b-Rwanda"></i>B2B Rwanda</a></li>
                                            <li><a target="_blank" href="https://b2b-senegal.net/"><i className="icon-b2b-Senegal"></i>B2B Sénégal</a></li>
                                            <li><a target="_blank" href="https://b2b-ghana.net/"><i className="icon-b2b-Ghana"></i>B2B Ghana</a></li>
                                            <li><a target="_blank" href="https://b2b-asia.net/"><i className="icon-b2b-Asia"></i>B2B Asia</a></li>
                                            <li><a target="_blank" href="https://b2b-saudia.net/"><i className="icon-b2b-Saudi"></i>B2B Saudia</a></li>
                                            <li><a target="_blank" href="https://b2b-qatar.net/"><i className="icon-b2b-Qatar"></i>B2B Qatar</a></li>
                                            <li><a target="_blank" href="https://b2b-emirates.net/"><i className="icon-b2b-Emirates"></i>B2B Emirates</a></li>
                                        </ul> */}
                                        <ul className="col-md-6  col-sm-6">
                                            <li><a href="javascript:void(0)"><i className="icon-b2b-France"></i>B2B France</a></li>
                                            {/* <li><a href="javascript:void(0)"><i className="icon-b2b-America"></i>B2B America</a></li> */}
                                            <li><a href="javascript:void(0)" ><i className="icon-b2b-Canada"></i>B2B Canada</a></li>
                                            <li><a href="javascript:void(0)"><i className="icon-b2b-Africa"></i>B2B Africa</a></li>
                                            {/* <li><a href="javascript:void(0)" ><i className="icon-b2b-Tunisia"></i>B2B Tunisia</a></li> */}
                                            <li><a href="javascript:void(0)"><i className="icon-b2b-Alegeria"></i>B2B Algeria</a></li>
                                            <li><a href="javascript:void(0)"><i className="icon-b2b-Libye"></i>B2B Libye</a></li>
                                            <li><a href="javascript:void(0)"><i className="icon-b2b-Morocco"></i>B2B Morocco</a></li>
                                            <li><a href="javascript:void(0)"><i className="icon-b2b-Congo"></i>B2B Congo</a></li>
                                            
                                        </ul>
                                        <ul className="col-md-6  col-sm-6">
                                            <li><a href="javascript:void(0)"><i className="icon-b2b-Rwanda"></i>B2B Rwanda</a></li>
                                            <li><a href="javascript:void(0)"><i className="icon-b2b-Senegal"></i>B2B Sénégal</a></li>
                                            <li><a href="javascript:void(0)"><i className="icon-b2b-Ghana"></i>B2B Ghana</a></li>
                                            <li><a href="javascript:void(0)"><i className="icon-b2b-Asia"></i>B2B Asia</a></li>
                                            <li><a href="javascript:void(0)"><i className="icon-b2b-Saudi"></i>B2B Saudia</a></li>
                                            <li><a href="javascript:void(0)"><i className="icon-b2b-Qatar"></i>B2B Qatar</a></li>
                                            <li><a href="javascript:void(0)"><i className="icon-b2b-Emirates"></i>B2B Emirates</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="container-fluid p-0" style={{marginBottom: "-20px"}}>
                            <div className="widget widget_services b2b-infos-legales mb-0">
                            <ul>
                                <li><Link to={"/"+i18next.language}> {t('Nos Avantages')}</Link></li>
                                <li><Link to={"/"+i18next.language}> {t('Mentions légales')}</Link></li>
                                <li><Link to={"/"+i18next.language}> {t('Protection de la vie privée')}</Link></li>
                                <li><Link to={"/"+i18next.language}> {t("Conditions Générales d'utilisation")}</Link></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom p-0 bg-white">
                        <div className="container-fluid p-0">
                            <div className="row">
                            <div className="col-lg-12 col-md-12 text-Copyright-SMART">
                                <div className="copyright-style left"><i></i></div>
                                <span> 
                                {t('Copyright')}© 2021 -  {t('Tous droits réservés')} SMART MARKETPLACE 
                                </span> 
                                <div className="copyright-style right"><i></i></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
export default withTranslation()(Footer);