import http from "./httpService";
import { apiUrl } from  "../config.json";

const getEvenementsPasses = (paged=1,lieu=0,mot_cle="") => {
    const apiEndoint =  apiUrl+"/evenements/getEvenementsPasses.php?paged="+paged+"&mot_cle="+mot_cle+"&lieu="+lieu;
    return http.get(apiEndoint);
}
const getEvenementsEnCours = (paged=1,lieu=0,mot_cle="") => {
    const apiEndoint =  apiUrl+"/evenements/getEvenementsEnCours.php?paged="+paged+"&mot_cle="+mot_cle+"&lieu="+lieu;
    return http.get(apiEndoint);
}
const getEvenementsVenir = (paged=1,lieu=0,mot_cle="") => {
    const apiEndoint =  apiUrl+"/evenements/getEvenementsVenir.php?paged="+paged+"&mot_cle="+mot_cle+"&lieu="+lieu;
    return http.get(apiEndoint);
}
const getDetail = (slug) => {
    const apiEndoint =  apiUrl+"/evenements/getDetail.php?slug="+slug;
    return http.get(apiEndoint);
}
const getLieus = () => {
    const apiEndoint =  apiUrl+"/evenements/getLieux.php";
    return http.get(apiEndoint);
}

export default 
{
    getLieus,
    getDetail,
    getEvenementsPasses,
    getEvenementsEnCours,
    getEvenementsVenir
}