
import './style.css';
import React, { Component } from 'react';
import Evenements from '../../services/evenements';
import { Link } from 'react-router-dom';
import { Markup } from 'react-render-markup';
import MetaTags from 'react-meta-tags';
import { b2bNameSite,b2bDescriptionSite } from  "../../config.json"; 
import Select from 'react-select'
import Pagination from "react-js-pagination";
import i18next from 'i18next';
import ReactGA from 'react-ga';
import { withTranslation } from 'react-i18next';
class Index extends Component
{
    constructor (props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        super(props)
        this.state = {
            events : <EventsEnCours t={this.props.t}  mot_cle="" liex="0" gotoAvenir={this.gotoAvenir} init={true}/>,
            eventSelected : "EnCours",
            search_mot_cle : "", 
            search_liex : 0, 
            lieus : [],
            activeAvenir: "",
            activeEncoure: "active"
        }
        Evenements.getLieus().then((res)=>{
            if(res.data.get){
                var items = [{ value: '', label: this.props.t('Toutes les lieus') }];
                res.data.data.forEach(lieux => {
                    items.push({ value: lieux.id, label: lieux.name },);
                }); 
                this.setState({lieus : []});
                this.setState({lieus : items});
            }
        });
    }
    
    changeSearch = (e)=>{
        this.setState({
            search_mot_cle : e.target.value
        });
    }
    submitSearch= ()=>{
        this.setState({
            events : <></>
        });
        setTimeout(() => {
            if(this.state.eventSelected=="Passes")
                this.setState({events :<EventsPasses t={this.props.t}  mot_cle={this.state.search_mot_cle} liex={this.state.search_liex}/>});
            else if(this.state.eventSelected=="EnCours")
                this.setState({events :<EventsEnCours t={this.props.t}  mot_cle={this.state.search_mot_cle} liex={this.state.search_liex} gotoAvenir={this.gotoAvenir} init={false}/>});
            else if(this.state.eventSelected=="Avenir")
                this.setState({events :<EventsAvenir t={this.props.t}  mot_cle={this.state.search_mot_cle} liex={this.state.search_liex}/>});
        }, 100);
    }
    changeLiex=(e)=>{
        
        this.setState({
            search_liex : e.value,
            events : <></>
        });
        setTimeout(() => {
            if(this.state.eventSelected=="Passes")
                this.setState({events :<EventsPasses t={this.props.t}  mot_cle={this.state.search_mot_cle} liex={e.value}/>});
            else if(this.state.eventSelected=="EnCours")
                this.setState({events :<EventsEnCours t={this.props.t}  mot_cle={this.state.search_mot_cle} liex={e.value} gotoAvenir={this.gotoAvenir} init={false}/>});
            else if(this.state.eventSelected=="Avenir")
                this.setState({events :<EventsAvenir t={this.props.t}  mot_cle={this.state.search_mot_cle} liex={e.value}/>});
        }, 100);
        
    }
    selectItem = (e)=>{
        var items = document.getElementsByClassName("tablinks");
        var i;
        for (i = 0; i < items.length; i++) {
            items[i].classList.remove("active");
        }
        e.target.classList.add('active');
    }
    b2bEvenPasses=(e)=>{
        this.selectItem(e);
        this.setState({events : <></>});
        this.setState({
            events : <EventsPasses t={this.props.t}  mot_cle={this.state.search_mot_cle} liex={this.state.search_liex}/>,
            eventSelected : "Passes",
        });
    }
    b2bEvenEncours=(e)=>{
        this.selectItem(e);
        this.setState({events : <></>});
        this.setState({
            events : <EventsEnCours t={this.props.t}  mot_cle={this.state.search_mot_cle} liex={this.state.search_liex} gotoAvenir={this.gotoAvenir} init={false}/>,
            eventSelected : "EnCours",
        });
    }
    b2bEvenAvenir=(e)=>{
        this.selectItem(e);
        this.setState({events : <></>});
        this.setState({
            events : <EventsAvenir t={this.props.t} mot_cle={this.state.search_mot_cle} liex={this.state.search_liex}/>,
            eventSelected : "Avenir",
        });
    } 
    gotoAvenir=()=>{
        this.setState({events : <></>});
        this.setState({
            events : <EventsAvenir t={this.props.t}  mot_cle={""} liex={0}/>,
            eventSelected : "Avenir",
            activeAvenir: "active",
            activeEncoure: ""
        });
    } 
    render() {
        const {t} = this.props;
        return ( 
            <div className="page-content">
                <MetaTags>
                    <title>{t("Événements B2B")} | {b2bNameSite} | {b2bDescriptionSite}</title>
                </MetaTags>
                <section id="list-places-wrapper" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 widget de_widget_categories prod-list" id="search-places">
                                <form role="search" className="place_search_form" action="" method="GET">
                                    <div className="row block-search-all ml-0">
                                        <div className="row col-md-11 col-10 de-search-form border-block" id="search-places" style={{"margin-left": "0px"}}>{t("")}
                                            <div className="col-md-8 block-search-keyword">
                                                <div className="de-scontrol">
                                                    <input type="text" name="mot_cle" onChange={this.changeSearch} className="keyword-search" placeholder={t("Entrez un mot-clé")+"..."} value={this.state.search_mot_cle} />
                                                </div>
                                            </div>
                                            <div className="col-md-4  block-search-categorie">
                                                <div className="de-scontrol">
                                                    <Select options={this.state.lieus} placeholder={t("Tous les lieux")} onChange={this.changeLiex}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 col-2 block-search-btn" style={{"padding-right":"0"}}>
                                            <button className="btn btn-primary" type="button" onClick={this.submitSearch} style={{"background-color":"#ED2829","border":"none","height":"65px","width": "100%"}}>
                                                <i className="fa fa-search fa-lg"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-12 ">
                                <div className="tab menu-evenements-mobile">
                                    <button className="tablinks " onClick={this.b2bEvenPasses}>{t("Événements passés")} </button>
                                    <button className={"tablinks "+this.state.activeEncoure} onClick={this.b2bEvenEncours}>{t("Événements en cours")}</button>
                                    <button className={"tablinks "+this.state.activeAvenir} onClick={this.b2bEvenAvenir}>{t("Événements à venir")}</button>
                                </div>
                            </div>
                            {this.state.events}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default withTranslation()(Index)

class EventsPasses extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            items: [<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>],
            activePage : 1 ,
            totalItemsCount : 0,
            itemsPerPage :10,
            mot_cle : this.props.mot_cle,
            liex : this.props.liex,
        }
        Evenements.getEvenementsPasses(1,this.props.liex,this.props.mot_cle).then((res)=>{
            if(res.data.get){
                var items = [];
                res.data.data.forEach(data => {
                    items.push(<Item t={this.props.t}  data={data}/>);
                }); 
                this.setState({items : []});
                this.setState({
                    items : items,
                    totalItemsCount : res.data.nbposts
                });
            }
        });
    }
    initItems(){
        window.scrollTo(0, 0);
        this.setState({items : [
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>
        ]});
    }
    handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.initItems()
        Evenements.getEvenementsPasses(pageNumber,this.props.liex,this.props.mot_cle).then((res)=>{
            if(res.data.get){
                var items = [];
                res.data.data.forEach(data => {
                    items.push(<Item t={this.props.t}  data={data}/>);
                }); 
                this.setState({items : []});
                this.setState({
                    items : items,
                    totalItemsCount : res.data.nbposts
                });
            }
        });
    }
    
    render() {
        return (
            <div className="col-md-12">
                <div className="col-md-12">
                    {this.state.items}
                    <div className="paginations-wrapper">
                        <div className="paginations">
                            {this.state.totalItemsCount>this.state.itemsPerPage?
                            <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsPerPage}
                            totalItemsCount={this.state.totalItemsCount}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                            />
                            :<></>}
                        </div>
                    </div>
                </div>
            </div>         
        );
    }
}
class EventsEnCours extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            items: [<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>],
            activePage : 1 ,
            totalItemsCount : 0,
            itemsPerPage :10,
            mot_cle : this.props.mot_cle,
            liex : this.props.liex,
        }
        Evenements.getEvenementsEnCours(1,this.props.liex,this.props.mot_cle).then((res)=>{
           
            if(res.data.get){
                if(res.data.nbposts==0 && this.props.init){
                    this.props.gotoAvenir()
                }
                var items = [];
                var datavide=false;
                res.data.data.forEach(data => {
                    items.push(<Item t={this.props.t}  data={data}/>);
                    datavide=true;
                }); 
                this.setState({items : []});
                this.setState({
                    items : items,
                    totalItemsCount : res.data.nbposts
                });
                
            }
        });
    }
    initItems(){
        window.scrollTo(0, 0);
        this.setState({items : [
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>
        ]});
    }
    handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.initItems()
        Evenements.getEvenementsEnCours(pageNumber,this.props.liex,this.props.mot_cle).then((res)=>{
            if(res.data.get){
                var items = [];
                res.data.data.forEach(data => {
                    items.push(<Item t={this.props.t}  data={data}/>);
                }); 
                this.setState({items : []});
                this.setState({
                    items : items,
                    totalItemsCount : res.data.nbposts
                });
            }
        });
    }
    
    render() {
        return (
            <div className="col-md-12">
                <div className="col-md-12">
                    {this.state.items}
                    <div className="paginations-wrapper">
                        <div className="paginations">
                            {this.state.totalItemsCount>this.state.itemsPerPage?
                            <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsPerPage}
                            totalItemsCount={this.state.totalItemsCount}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                            />
                            :<></>}
                        </div>
                    </div>
                </div>
            </div>         
        );
    }
}
class EventsAvenir extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            items: [<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>],
            activePage : 1 ,
            totalItemsCount : 0,
            itemsPerPage :10,
            mot_cle : this.props.mot_cle,
            liex : this.props.liex,
        }
        Evenements.getEvenementsVenir(1,this.props.liex,this.props.mot_cle).then((res)=>{
            if(res.data.get){
                console.log(res.data);
                var items = [];
                res.data.data.forEach(data => {
                    items.push(<Item t={this.props.t}  data={data}/>);
                }); 
                this.setState({items : []});
                this.setState({
                    items : items,
                    totalItemsCount : res.data.nbposts
                });
            }
        });
    }
    initItems(){
        window.scrollTo(0, 0);
        this.setState({items : [
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>
        ]});
    }
    handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.initItems()
        Evenements.getEvenementsVenir(pageNumber,this.props.liex,this.props.mot_cle).then((res)=>{
            if(res.data.get){
                var items = [];
                res.data.data.forEach(data => {
                    items.push(<Item t={this.props.t}  data={data}/>);
                }); 
                this.setState({items : []});
                this.setState({
                    items : items,
                    totalItemsCount : res.data.nbposts
                });
            }
        });
    }
    
    render() {
        return (
            <div className="col-md-12">
                <div className="col-md-12">
                    {this.state.items}
                    <div className="paginations-wrapper">
                        <div className="paginations">
                            {this.state.totalItemsCount>this.state.itemsPerPage?
                            <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsPerPage}
                            totalItemsCount={this.state.totalItemsCount}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                            />
                            :<></>}
                        </div>
                    </div>
                </div>
            </div>         
        );
    }
}

class Item extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            data : props.data,
        }
    }
  
    render() {
        const {t} = this.props
        return ( 
            <div className="col-md-12">
                <div className="mec-wrap mec-skin-list-container" >
                    <div className="mec-skin-list-events-container">
                        <div className="mec-wrap">
                            <div className="mec-event-list-standard">
                                <article className="mec-event-article mec-clear">
                                    <div className="mec-topsec row">
                                        <div className="col-md-3 mec-event-image-wrap mec-col-table-c pl-4 d-flex justify-content-start align-items-center">
                                            <div className="mec-event-image">
                                                <Link to={"/"+i18next.language+this.state.data.url}>
                                                    <img onContextMenu={(e)=> e.preventDefault()} width="100%" height={150} src={this.state.data.img} className="attachment-thumblist size-thumblist wp-post-image lazyloaded" alt={this.state.data.title} data-was-processed="true" />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-md-6 ">
                                            <div className="mec-event-content">
                                                <h3 className="mec-event-title">
                                                    <Link className="mec-color-hover size-titre-b2b" to={"/"+i18next.language+this.state.data.url} style={{fontSize: '20px'}}>{this.state.data.title}</Link>
                                                </h3>
                                                <div className="mec-event-description"><Markup markup={this.state.data.content} /></div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 mec-col-table-c mec-event-meta-wrap" style={{height: "auto"}}>
                                            <Link className="mec-booking-button" to={"/"+i18next.language+this.state.data.url} style={{bottom: 0, position: 'absolute', right: 5, border: '1px solid #e11a18', padding: '0 10px'}}>{t('Voir')} +</Link>
                                            <div className="mec-event-meta mec-color-before">
                                                <div className="mec-date-details">{t('du')}<span className="mec-event-d size-titre-b2b">{this.state.data.start_date}</span></div>
                                                <div className="mec-date-details">{t('au')}<span className="mec-event-d size-titre-b2b">{this.state.data.end_date}</span></div>
                                                <div className="mec-venue-details"></div>
                                                <address className="mec-event-address">
                                                    <span>{this.state.data.location}</span>
                                                </address>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
class ItemLoader extends Component
{
    render() {

        return ( 
            <div className="row">
                <div className="col-md-12 " style={{backgroundColor: '#fff', marginBottom: '10px', paddingTop: '10px', border: '1px solid #b8c7c8'}}>
                    <div className="row">
                    <div className="col-md-3" style={{paddingLeft: 0, textAlign: 'center'}}>
                        <div className="cont-img-offre"> 
                        <a href="#">  
                            <div className="loder-image-offre" ></div>
                        </a>
                        <div className="block-marker block-marker-loder">
                            <i className="fa fa fa-calendar" style={{fontSize: '15px', paddingRight: '5px'}} /><span className="loder-span ml-0"></span></div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <a href="#" style={{color: '#ED2829', borderBottom: '1px solid #ED2829', fontFamily: 'calibri', fontWeight: 700, textTransform: 'uppercase'}}><span className="loder-span  ml-0"></span></a>
                        <br />
                        <div style={{textAlign: 'justify'}}>
                            <span className="loder-p"></span>
                            <span className="loder-p"></span>
                        </div>
                        <div className="col-md-12" style={{textAlign: 'justify'}}>
                        <a href="" style={{border: '1px solid #ED2829', padding: '0 10px', color: '#ED2829', fontFamily: 'calibri', marginBottom: '5px', float: 'right'}}> Voir +</a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

